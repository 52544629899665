import React from 'react';
import { Button } from '@mui/material';
import { useLocalization } from 'src/context/LocalizationContext';

const FilterButton = ({ onClick,sx }) => {


    const { language } = useLocalization();
    return (
        <>
            <Button
                sx={{
                    backgroundColor: '#1570EF',
                    fontWeight: 400,
                    fontSize: 14,
                    fontFamily: 'Inter',
                    width: 97,
                    height: 40,
                    borderRadius: '5px',
                    padding: '8px 16px 8px 16px',
                    gap: '6px',
                    ...sx
                }}
                variant="contained"
                onClick={onClick}
            >
                {language.components.FilterButton.filter}
            </Button>
        </>
    );
};

export default FilterButton;