import React, { useState } from 'react';
import {
  Modal,
  Box,
  Button,
  Typography,
  TextField,
  CircularProgress,
} from '@mui/material';
import { toast } from 'react-toastify';
import Services from 'src/services/Services';

const HandoverModal = ({ open, data, onClose, handleCompleteDelivery }) => {
  const parsedUser = JSON.parse(localStorage.getItem('user'));
  const [step, setStep] = useState(1);
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [note, setNote] = useState('');
  const [code, setCode] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSendSMS = () => {
    if (name && lastname && phone.length === 10 && note?.length > 5) {
      setLoading(true);
      const message = `${data?.orderInventory} adet paketin zimmetinize teslimi için onay kodunuz: {code}`;
      Services.CreateHandover(
        parsedUser?.token,
        data?.id,
        message,
        name,
        lastname,
        phone,
        note
      )
        .then((res) => {
          setConfirmationCode(res.data.result.code);
          setStep(2);
          setLoading(false);
          toast.success('Doğrulama kodu gönderildi.');
        })
        .catch(() => toast.error('Kod gönderilirken bir hata oluştu.'));
    } else {
      toast.error('Lütfen tüm alanları doldurunuz.');
    }
  };

  const handleCompleteDeliveryStep = () => {
    if (code === confirmationCode) {
      handleCompleteDelivery({
        name: name,
        lastname: lastname,
        phone: phone,
        confirmationCode: confirmationCode,
      });
      onClose();
    } else {
      toast.error('Girilen doğrulama kodu yanlış.');
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: 2,
              gap: 2,
            }}
          >
            <CircularProgress />
            <Typography>Lütfen bekleyin...</Typography>
          </Box>
        ) : step === 1 ? (
          <>
            <Typography variant="h6">Teslimat Bilgileri</Typography>
            <TextField
              label="İsim"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              label="Soy İsim"
              fullWidth
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
            <TextField
              label="Telefon"
              fullWidth
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              inputProps={{ maxLength: 10 }}
              placeholder="5XXXXXXXXX"
            />
            <TextField
              label="Plaka"
              fullWidth
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={handleSendSMS}>
              Doğrulama Kodu Gönder
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h6">Doğrulama Kodu</Typography>
            <TextField
              label="Kodu Giriniz"
              fullWidth
              value={code}
              onChange={(e) => setCode(e.target.value)}
              inputProps={{ maxLength: 6 }}
              placeholder="Kod"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleCompleteDeliveryStep}
            >
              Teslimatı Tamamla
            </Button>
            <Button color="secondary" onClick={() => setStep(1)}>
              Geri Dön
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default HandoverModal;
