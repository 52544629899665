import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import 'moment/locale/tr';
// @mui
import { Grid, Stack } from '@mui/material';
// sections
import {
  AppCurrentVisits,
  AppWidgetSummary,
  AppWebsiteVisits,
  AppConversionRates,
} from '../sections/@dashboard/app';
import AppAllDatas from 'src/sections/@dashboard/app/AppAllDatas';
import Services from 'src/services/Services';
import AppLineDatas from 'src/sections/@dashboard/app/AppLineDatas';
import { useLocalization } from '../context/LocalizationContext';
import { replacePlaceholders } from 'src/utils/textUtils';
// ----------------------------------------------------------------------

export default function DashboardAppPage() {

  const { language } = useLocalization();

  const parsedUser = JSON.parse(localStorage.getItem('user'));
  const [weekDays, setWeekDays] = useState([]);
  const [monthDays, setMonthDays] = useState([]);
  // weekly and monthly
  const [weeklyTransaction, setWeeklyTransaction] = useState(0);
  const [weeklyPackage, setWeeklyPackage] = useState(0);
  const [monthlyTransaction, setMonthlyTransaction] = useState(0);
  const [monthlyPackage, setMonthlyPackage] = useState(0);

  // shipments by status
  const [postsReadyToCarry, setPostsReadyToCarry] = useState(0);
  const [transactionsOnCarrying, setTransactionsOnCarrying] = useState(0);
  const [transactionsCompleted, setTransactionsCompleted] = useState(0);

  // vehicles
  const [palet, setPalet] = useState(0);
  const [bigCart, setBigCart] = useState(0);
  const [smallCart, setSmallCart] = useState(0);
  const [ring, setRing] = useState(0);
  const [handsfree, setHandsfree] = useState(0);

  // shipment firms
  const [ups, setUps] = useState(0);
  const [mng, setMng] = useState(0);
  const [yurtici, setYurtici] = useState(0);
  const [ptt, setPtt] = useState(0);
  const [aras, setAras] = useState(0);
  const [surat, setSurat] = useState(0);
  const [other, setOther] = useState(0);

  // accept goods and post offices
  const [acceptGoodsCount, setAcceptGoodsCount] = useState(0);
  const [postOfficeCount, setPostOfficeCount] = useState(0);

  // handshakes
  const [manuelHandshakes, setManuelHandshakes] = useState(0);
  const [normalHandshakes, setNormalHandshakes] = useState(0);
  // waybills
  const [weeklyTotalWaybills, setWeeklyTotalWaybills] = useState([]);
  const [weeklyCompletedWaybills, setWeeklyCompletedWaybills] = useState([]);
  const [monthlyTotalWaybills, setMonthlyTotalWaybills] = useState([]);
  const [monthlyCompletedWaybills, setMonthlyCompletedWaybills] = useState([]);

  const fetchStatistics = async (type, date) => {
    const token = parsedUser.token;

    if (parsedUser.accountCategory === 'store') {
      const branchID = localStorage.getItem('branchID');
      try {
        const res = await Services.GetStatisticsByBranch(
          token,
          branchID,
          type,
          date
        );
        if (res.data.status) {
          return res.data.result;
        } else {
          return 0;
        }
      } catch (error) {
        console.error(error);
        return 0;
      }
    } else {
      const mallID = localStorage.getItem('mallID');
      try {
        const res = await Services.GetStatisticsByMall(
          token,
          mallID,
          type,
          date
        );
        if (res.data.status) {
          return res.data.result;
        } else {
          return 0;
        }
      } catch (error) {
        console.error(error);
        return 0;
      }
    }
  };

  const formatWeeklyData = (data) => {
    const weekDaysList = Array.from({ length: 7 }, (_, i) =>
      moment().startOf('week').add(i, 'days').format('YYYY-MM-DD')
    );
    const weeklyDataList = Array(7).fill(0);

    for (const [date, value] of Object.entries(data)) {
      const index = weekDaysList.indexOf(date);
      if (index !== -1) {
        weeklyDataList[index] = value;
      }
    }

    return weeklyDataList;
  };

  const formatMonthlyData = (data) => {
    const monthDaysList = Array.from(
      { length: moment().daysInMonth() },
      (_, i) => moment().startOf('month').add(i, 'days').format('YYYY-MM-DD')
    );
    const monthlyDataList = Array(monthDaysList.length).fill(0);

    for (const [date, value] of Object.entries(data)) {
      const index = monthDaysList.indexOf(date);
      if (index !== -1) {
        monthlyDataList[index] = value;
      }
    }

    return monthlyDataList;
  };

  const fetchGraphStatistics = async (
    type,
    date,
    onlyCompleted,
    weeklyOrMonthly
  ) => {
    const token = parsedUser.token;

    if (parsedUser.accountCategory === 'store') {
      const branchID = localStorage.getItem('branchID');
      try {
        const res = await Services.GetGraphStatisticsByBranch(
          token,
          branchID,
          type,
          date,
          onlyCompleted
        );
        // console.log('fetchGraphStatistics: ', res.data);
        if (res.data.status) {
          return weeklyOrMonthly === 'weekly'
            ? formatWeeklyData(res.data.result)
            : formatMonthlyData(res.data.result);
        } else {
          return weeklyOrMonthly === 'weekly'
            ? formatWeeklyData({})
            : formatMonthlyData({});
        }
      } catch (error) {
        console.error(error);
        return weeklyOrMonthly === 'weekly'
          ? formatWeeklyData({})
          : formatMonthlyData({});
      }
    } else {
      const mallID = localStorage.getItem('mallID');
      try {
        const res = await Services.GetGraphStatisticsByMall(
          token,
          mallID,
          type,
          date
        );
        // console.log('fetchGraphStatistics: ', res.data);
        if (res.data.status) {
          // return res.data.result;
          return weeklyOrMonthly === 'weekly'
            ? formatWeeklyData(res.data.result)
            : formatMonthlyData(res.data.result);
        } else {
          return weeklyOrMonthly === 'weekly'
            ? formatWeeklyData({})
            : formatMonthlyData({});
        }
      } catch (error) {
        console.error(error);
        return weeklyOrMonthly === 'weekly'
          ? formatWeeklyData({})
          : formatMonthlyData({});
      }
    }
  };

  useEffect(() => {
    //weekly and monthly
    // weekly transactions
    const fetchWeeklyTransactions = async () => {
      const _weeklyTransactions = await fetchStatistics(
        'transactions-completed',
        'weekly'
      );
      setWeeklyTransaction(_weeklyTransactions.totalTransactions);
    };

    // weekly packages
    const fetchWeeklyPackages = async () => {
      const _weeklyTransactions = await fetchStatistics(
        'transactions-completed',
        'weekly'
      );
      setWeeklyPackage(_weeklyTransactions.totalPackageCount);
    };

    // monthly transactions
    const fetchMonthlyTransactions = async () => {
      const _monthlyTransactions = await fetchStatistics(
        'transactions-completed',
        'monthly'
      );
      setMonthlyTransaction(_monthlyTransactions.totalTransactions);
    };

    // monthly packages
    const fetchMonthlyPackages = async () => {
      const _monthlyTransactions = await fetchStatistics(
        'transactions-completed',
        'monthly'
      );
      setMonthlyPackage(_monthlyTransactions.totalPackageCount);
    };
    // ---------------------------------------------------------
    //shipments by status
    // ready to carry
    const fetchReadyToCarry = async () => {
      const _readyToCarryPosts = await fetchStatistics(
        'posts-ready-to-carry',
        'last365Days'
      );
      setPostsReadyToCarry(_readyToCarryPosts);
    };

    // on porter
    const fetchOnPorter = async () => {
      const _onPorter = await fetchStatistics(
        'transactions-on-carrying',
        'last365Days'
      );
      setTransactionsOnCarrying(_onPorter);
    };

    // completeds
    const fetchCompleteds = async () => {
      const _completeds = await fetchStatistics(
        'transactions-completed',
        'last365Days'
      );
      setTransactionsCompleted(_completeds);
    };
    // ---------------------------------------------------------
    // pie charts
    // vehicles
    const fetchVehicles = async () => {
      const _vehicles = await fetchStatistics(
        'handshakes-vehicles',
        'last365Days'
      );
      if (_vehicles !== 0) {
        Object.entries(_vehicles).forEach(([label, value]) => {
          switch (label) {
            case 'Küçük Araç':
              setSmallCart(value);
              break;
            case 'Büyük Araç':
              setBigCart(value);
              break;
            case 'Ring':
              setRing(value);
              break;
            case 'Palet':
              setPalet(value);
              break;
            case 'Handsfree':
              setHandsfree(value);
              break;
            default:
              break;
          }
        });
      } else {
        setSmallCart(0);
        setBigCart(0);
        setRing(0);
        setPalet(0);
        setHandsfree(0);
      }
    };

    // cargo vehicles
    const fetchCargoVehicles = async () => {
      const _cargoVehicles = await fetchStatistics(
        'posts-shipment-firms',
        'last365Days'
      );
      if (_cargoVehicles !== 0) {
        Object.entries(_cargoVehicles).forEach(([label, value]) => {
          switch (label) {
            case 'UPS':
              setUps(value);
              break;
            case 'Aras Kargo':
              setAras(value);
              break;
            case 'MNG Kargo':
              setMng(value);
              break;
            case 'PTT Kargo':
              setPtt(value);
              break;
            case 'Sürat Kargo':
              setSurat(value);
              break;
            case 'Yurtiçi Kargo':
              setYurtici(value);
              break;
            case 'Diğer':
              setOther(value);
              break;
            default:
              break;
          }
        });
      } else {
        setUps(0);
        setAras(0);
        setMng(0);
        setPtt(0);
        setSurat(0);
        setYurtici(0);
        setOther(0);
      }
    };

    // posts types
    const fetchPostsTypes = async () => {
      const _postsTypes = await fetchStatistics('posts-types', 'last365Days');
      setPostOfficeCount(_postsTypes?.postOffice ? _postsTypes?.postOffice : 0);
      setAcceptGoodsCount(
        _postsTypes?.corporationalAcceptGoods
          ? _postsTypes?.corporationalAcceptGoods
          : 0
      );
    };

    // handshakes
    const fetchHandshakes = async () => {
      const _handshakes = await fetchStatistics(
        'handshakes-types',
        'last365Days'
      );
      setNormalHandshakes(_handshakes?.qr ? _handshakes?.qr : 0);
      setManuelHandshakes(_handshakes?.manuel ? _handshakes?.manuel : 0);
    };

    // Waybills
    const fetchWaybills = async (weeklyOrMonthly) => {
      const today = new Date();
      const currentDay = today.getDay();
      if (weeklyOrMonthly === 'weekly') {
        const startOfCurrentWeek = new Date(today);
        startOfCurrentWeek.setDate(
          today.getDate() - currentDay + (currentDay === 0 ? -6 : 1)
        );
        const endOfCurrentWeek = new Date(today);
        endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

        const formattedStartDate = startOfCurrentWeek
          .toISOString()
          .split('T')[0];
        const formattedEndDate = endOfCurrentWeek.toISOString().split('T')[0];
        const _weeklyTotalWaybills = await fetchGraphStatistics(
          'handshakes-daily-distribution',
          {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          },
          false,
          'weekly'
        );
        const _weeklyCompletedWaybills = await fetchGraphStatistics(
          'handshakes-daily-distribution',
          {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          },
          true,
          'weekly'
        );
        // set edilecek
        setWeeklyTotalWaybills(_weeklyTotalWaybills);
        setWeeklyCompletedWaybills(_weeklyCompletedWaybills);
      } else {
        const startOfCurrentMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        const endOfCurrentMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );

        const formattedStartDate = startOfCurrentMonth
          .toISOString()
          .split('T')[0];
        const formattedEndDate = endOfCurrentMonth.toISOString().split('T')[0];

        const _monthlyTotalWaybills = await fetchGraphStatistics(
          'handshakes-daily-distribution',
          {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          },
          false,
          'monthly'
        );

        const _monthlyCompletedWaybills = await fetchGraphStatistics(
          'handshakes-daily-distribution',
          {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          },
          true,
          'monthly'
        );

        // set edilecek
        setMonthlyTotalWaybills(_monthlyTotalWaybills);
        setMonthlyCompletedWaybills(_monthlyCompletedWaybills);
      }
    };

    // fetch statistics
    fetchWeeklyTransactions();
    fetchWeeklyPackages();
    fetchMonthlyTransactions();
    fetchMonthlyPackages();
    fetchReadyToCarry();
    fetchOnPorter();
    fetchCompleteds();
    fetchVehicles();
    fetchCargoVehicles();
    fetchPostsTypes();
    fetchHandshakes();
    fetchWaybills('weekly');
    fetchWaybills('monthly');
  }, []);

  useEffect(() => {
    moment.locale('tr');

    const today = moment();

    const startOfCurrentWeek = moment(today).startOf('week');
    const endOfCurrentWeek = moment(today).endOf('week');

    const weekDaysArray = [];
    for (
      let i = moment(startOfCurrentWeek);
      i <= endOfCurrentWeek;
      i.add(1, 'day')
    ) {
      weekDaysArray.push(moment(i).format('DD MMMM'));
    }
    setWeekDays(weekDaysArray);

    const startOfCurrentMonth = moment(today).startOf('month');
    const endOfCurrentMonth = moment(today).endOf('month');

    const monthDaysArray = [];
    for (
      let i = moment(startOfCurrentMonth);
      i <= endOfCurrentMonth;
      i.add(1, 'day')
    ) {
      monthDaysArray.push(moment(i).format('DD MMMM'));
    }
    // console.log('monthDaysArray: ', monthDaysArray);
    setMonthDays(monthDaysArray);
  }, []);

  return (
    <>
      <Helmet>
        <title> {language.dashboard_page.titles.site_title} </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <AppLineDatas
              title={language.dashboard_page.titles.waybillOperations}
              subheader={language.dashboard_page.titles.weeklyTransactions}
              interval={language.dashboard_page.titles.operations}
              totalWaybills={weeklyTotalWaybills}
              completedWaybills={weeklyCompletedWaybills}
              days={weekDays ? weekDays : []}
              height={250}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <AppLineDatas
              title={language.dashboard_page.titles.waybillOperations}
              subheader={replacePlaceholders(language.dashboard_page.titles.monthlyTransactions, { month: 'Kasım' })}
              interval={language.dashboard_page.titles.monthly}
              totalWaybills={monthlyTotalWaybills}
              completedWaybills={monthlyCompletedWaybills}
              days={monthDays ? monthDays : []}
              height={250}
            />
          </Grid>
          {/* Haftalık ve Aylık Taşınanlar */}
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={language.dashboard_page.titles.weeklyOperation}
              total={weeklyTransaction ? weeklyTransaction : 0}
              icon={'octicon:graph-24'}
              color="blue"
              state={'increase'}
              comparison="week"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={language.dashboard_page.titles.weeklyPackage}
              total={weeklyPackage ? weeklyPackage : 0}
              color="blue"
              icon={'uil:package'}
              state={'increase'}
              comparison="week"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={language.dashboard_page.titles.monthlyOperation}
              total={monthlyTransaction ? monthlyTransaction : 0}
              color="green"
              icon={'octicon:graph-24'}
              state={'decrease'}
              comparison="month"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={language.dashboard_page.titles.monthlyPackage}
              total={monthlyPackage ? monthlyPackage : 0}
              color="green"
              icon={'uil:package'}
              state={'decrease'}
              comparison="month"
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <AppAllDatas
              title={language.dashboard_page.titles.weeklyOperation}
              open={postsReadyToCarry ? postsReadyToCarry.totalPosts : 0}
              openCount={
                postsReadyToCarry ? postsReadyToCarry.totalPackageCount : 0
              }
              porter={
                transactionsOnCarrying
                  ? transactionsOnCarrying.totalTransactions
                  : 0
              }
              porterCount={
                transactionsOnCarrying
                  ? transactionsOnCarrying.totalPackageCount
                  : 0
              }
              completed={
                transactionsCompleted
                  ? transactionsCompleted.totalTransactions
                  : 0
              }
              completedCount={
                transactionsCompleted
                  ? transactionsCompleted.totalPackageCount
                  : 0
              }
              icon={'octicon:graph-24'}
              color="blue"
              state={'increase'}
              comparison="week"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <AppCurrentVisits
              title={language.dashboard_page.titles.transportationVehicles}
              // chartData={vehicles}
              chartData={[
                { label: language.general.vehicles.tinyVehicles, value: smallCart },
                { label: language.general.vehicles.bigVehicles, value: bigCart },
                { label: language.general.vehicles.pallet, value: palet },
                { label: language.general.vehicles.packageRing, value: ring },
                { label: language.general.vehicles.handsfree, value: handsfree },
              ]}
              chartColors={[
                '#49BBDA',
                '#3150A0',
                '#3793AB',
                '#D81B60',
                '#F7A937',
              ]}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={9}>
            <AppLineDatas
              title={'İşlem İstatistikleri'}
              subheader={'Aylık İşlemler'}
              interval={'Changeable'}
              height={325}
            />
          </Grid> */}
          <Grid item xs={12} md={6} lg={3}>
            <AppCurrentVisits
              title={language.dashboard_page.titles.cargoVehicles}
              chartData={[
                { label: 'UPS', value: ups },
                { label: 'MNG', value: mng },
                { label: 'Yurt İçi', value: yurtici },
                { label: 'PTT', value: ptt },
                { label: 'Aras', value: aras },
                { label: 'Sürat ', value: surat },
                { label: 'Diğer', value: other },
              ]}
              chartColors={[
                '#49BBDA',
                '#3150A0',
                '#3793AB',
                '#F7A937',
                '#D9D9D9',
              ]}
            />
          </Grid>
          {parsedUser.accountCategory !== 'store' && (
            <>
              {/* <Grid item xs={12} md={6} lg={9}>
                <AppConversionRates
                  title="Firmalar Aylık İşlem İlk 10"
                  subheader=""
                  chartData={[
                    { label: 'Test', value: 10 },
                    { label: 'Test2', value: 12 },
                    { label: 'Test3', value: 14 },
                    { label: 'Test4', value: 16 },
                  ]}
                />
              </Grid> */}
              <Grid item xs={12} md={6} lg={3}>
                <AppCurrentVisits
                  title={language.dashboard_page.titles.correspondenceAndAcceptance}
                  chartData={[
                    { label: 'Muhaberat', value: postOfficeCount },
                    { label: 'Mal Kabul', value: acceptGoodsCount },
                  ]}
                  chartColors={['#F7A937', '#3150A0']}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} md={6} lg={3}>
            <AppCurrentVisits
                  title={language.dashboard_page.titles.waybillOperations}
              chartData={[
                { label: 'Manuel', value: manuelHandshakes },
                { label: 'QR', value: normalHandshakes },
              ]}
              chartColors={['#F7A937', '#3150A0']}
            />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
