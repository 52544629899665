import { Card, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import tutorialPDF from '../assets/pdf/magaza-egitim.pdf';
import { useLocalization } from 'src/context/LocalizationContext';

export default function TutorialPage() {
  const parsedUser = JSON.parse(localStorage.getItem('user'));
  const { language } = useLocalization();
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
  });

  return (
    <>
      <Helmet>
        <title>{language.tutorial_page.titles.site_title}</title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
          sx={{ width: '100%' }}
        >
          <Typography variant="h4" gutterBottom>
            {language.tutorial_page.titles.title}
          </Typography>
        </Stack>
        {true ? (
          <Card style={{ height: '600px', overflow: 'hidden' }}>
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
            >
              <Viewer
                fileUrl={tutorialPDF}
                plugins={[defaultLayoutPluginInstance]}
                defaultScale={1}
              />
            </Worker>
          </Card>
        ) : (
          <div
            style={{
              width: '100%',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Typography>
              {language.tutorial_page.labels.noTutorialAvailable}
            </Typography>
          </div>
        )}
      </Stack>
    </>
  );
}
