import React, { useState, useEffect } from 'react';
import {
  FormControlLabel,
  FormControl,
  Grid,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  Input,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Iconify from '../iconify';
import { toast } from 'react-toastify';
import api from 'src/services/BaseService';

const DeliveryModal = ({
  open,
  count,
  packageCount,
  onClose,
  onBack,
  onDeliver,
  onComplete,
  selectedItems,
  onAddPersonel,
  isLoading,
}) => {
  const [step, setStep] = useState(1);
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [note, setNote] = useState('');
  const [code, setCode] = useState('');
  const [selectedOption, setSelectedOption] = useState('manuel');
  const [selectedPersonel, setSelectedPersonel] = useState('');
  const [personelList, setPersonelList] = useState([]);
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [withoutTowerExist, setWithoutTowerExist] = useState(false);
  const [porterList, setPorterList] = useState([]);
  const [selectedPorter, setSelectedPorter] = useState('');

  useEffect(() => {
    // console.log(selectedItems, "??")
    if (selectedOption === 'auto' && personelList.length === 0) {
      setIsBackdropOpen(true);
      let metaTagsArr = [];
      for (let i = 0; i < selectedItems.length; i++) {
        if (selectedItems[i].dataJSON.tower) {
          metaTagsArr.push(selectedItems[i].dataJSON?.tower);
        }
      }
      // console.log(metaTagsArr, "metaTags")
      api
        .post('flow/concierge-get-users', {
          metaTags: metaTagsArr,
        })
        .then((response) => {
          if (response.data.status) {
            // console.log(response.data, "Get Users")
            setPersonelList(response.data.result);
            setIsBackdropOpen(false);
          } else {
            // console.log(response.data.message);
            toast.error('Kullanıcı bulunamadı');
            setIsBackdropOpen(false);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }

    else if (selectedOption === 'porter' && porterList.length === 0) {
      setIsBackdropOpen(true);
      const mallID = localStorage.getItem('mallID');
      api
        .post('users/get-users', {
          type: 'porter',
          mallID: mallID,
          status: "1",
        })
        .then((response) => {
          if (response.data.status) {
            setPorterList(response.data.result);
            setIsBackdropOpen(false);
          } else {
            toast.error('Kullanıcı bulunamadı');
            setPorterList([]);
            setIsBackdropOpen(false);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  }, [selectedOption]);

  useEffect(() => {
    for (let i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i].dataJSON.tower === '') {
        setWithoutTowerExist(true);
      }
    }
  }, []);

  const handleNext = () => {
    if (step === 1) {
      onDeliver(name, lastname, phone, note);
      setStep(2);
    } else {
      //   onClose();
      onComplete(code);
    }
  };

  const handleBack = () => {
    console.log(step);
    if (step === 1) {
      onClose()
    }
    else if (step === 2) {
      setStep(1);
    } else {
      onBack();
    }
  };

  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value;
    let numericValue = inputValue.replace(/[^0-9]/g, '');
    setPhone(numericValue);
  };

  const handleOptionChange = (event) => {
    setName('');
    setLastname('');
    setPhone('');
    setNote('');
    setSelectedPersonel('');
    setSelectedPorter('')
    setSelectedOption(event.target.value); // Seçilen seçeneği güncelle
  };

  const handlePersonelChange = (event) => {
    setSelectedPersonel(event.target.value); // Seçilen seçeneği güncelle
    const selectedPersonel = personelList.filter(
      (personel) => personel.userID === event.target.value
    )[0];
    setName(selectedPersonel.name);
    setLastname(selectedPersonel.lastname);
    setPhone(selectedPersonel.phone);
  };

  const handlePorterChange = (event) => {
    setSelectedPorter(event.target.value); // Seçilen seçeneği güncelle
    const selectedPorter = porterList.filter(
      (porter) => porter.id === event.target.value
    )[0];
    console.log(selectedPorter);

    setName(selectedPorter.name);
    setLastname(selectedPorter.lastname);
    setPhone(selectedPorter.phone);
  };

  const maskPhoneNumber = (phoneNumber) => {
    const maskedPart = phoneNumber.slice(0, -4).replace(/\d/g, '*'); // Telefon numarasının ilk 2 ve son 4 hanesi maskelenir
    return phoneNumber.slice(0, 0) + maskedPart + phoneNumber.slice(-4); // Maskelenmiş kısım ile orijinal telefon numarasının ilk 2 ve son 4 hanesi birleştirilir
  };

  return (
    <Dialog open={open}>
      <Backdrop
        sx={{ color: '#fff', zIndex: 2 }}
        open={isBackdropOpen || isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle>{step === 1 ? 'Adres Bilgileri' : 'Onay Kodu'}</DialogTitle>
      <DialogContent>
        {step === 1 ? (
          <div>
            {withoutTowerExist ? (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="option"
                      name="option"
                      value={selectedOption}
                      onChange={handleOptionChange}
                    >
                      <FormControlLabel
                        value="manuel"
                        control={<Radio />}
                        label="Bilgileri elle gir"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="option"
                      name="option"
                      value={selectedOption}
                      onChange={handleOptionChange}
                    >
                      <FormControlLabel
                        value="porter"
                        control={<Radio />}
                        label="Porter Seçerek İlerle"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="option"
                      name="option"
                      value={selectedOption}
                      onChange={handleOptionChange}
                    >
                      <FormControlLabel
                        value="manuel"
                        control={<Radio />}
                        label="Bilgileri elle gir"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="option"
                      name="option"
                      value={selectedOption}
                      onChange={handleOptionChange}
                    >
                      <FormControlLabel
                        value="porter"
                        control={<Radio />}
                        label="Porter Seçerek İlerle"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="option"
                      name="option"
                      value={selectedOption}
                      onChange={handleOptionChange}
                    >
                      <FormControlLabel
                        value="auto"
                        control={<Radio />}
                        label="Bilgileri sistemden seç"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            )}

            {selectedOption === 'manuel' ? (
              <>
                <TextField
                  fullWidth
                  label="Ad"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{ mb: 2, mt: 2 }}
                />
                <TextField
                  fullWidth
                  label="Soyad"
                  variant="outlined"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Telefon"
                  placeholder="5XXXXXXXXX"
                  variant="outlined"
                  inputProps={{ maxLength: 10 }}
                  value={phone}
                  onChange={handlePhoneNumberChange}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Notlar"
                  variant="outlined"
                  multiline
                  rows={4}
                  value={note}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                />
              </>
            ) : selectedOption === 'auto' ? (
              <>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Personel</InputLabel>
                  <Select
                    sx={{ width: '100%' }}
                    label="Personel"
                    value={selectedPersonel}
                    onChange={handlePersonelChange}
                    name="personel"
                    disabled={personelList.length === 0}
                  >
                    {personelList.map((personel, index) => (
                      <MenuItem key={index} value={personel.userID}>
                        {personel.name} {personel.lastname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  fullWidth
                  label="Telefon"
                  variant="outlined"
                  placeholder="5XXXXXXXXX"
                  value={maskPhoneNumber(phone)}
                  disabled
                  sx={{ mb: 2, marginTop: 1 }}
                />

                <TextField
                  fullWidth
                  label="Notlar"
                  variant="outlined"
                  multiline
                  rows={4}
                  value={note}
                  disabled={personelList.length === 0}
                  onChange={(e) => setNote(e.target.value)}
                  sx={{ mb: 2 }}
                />

                {personelList.length === 0 && isBackdropOpen === false ? (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {personelList.length === 0 && !isBackdropOpen && (
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        onClick={onAddPersonel}
                      >
                        Kule Çalışanı Ekle
                      </Button>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) :
              (
                <>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Porter</InputLabel>
                    <Select
                      sx={{ width: '100%' }}
                      label="Porter"
                      value={selectedPorter}
                      onChange={handlePorterChange}
                      name="porter"
                      disabled={porterList.length === 0}
                    >
                      {porterList.map((porter, index) => (
                        <MenuItem key={index} value={porter.id}>
                          {porter.name} {porter.lastname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField
                    fullWidth
                    label="Telefon"
                    variant="outlined"
                    placeholder="5XXXXXXXXX"
                    value={maskPhoneNumber(phone)}
                    disabled
                    sx={{ mb: 2, marginTop: 1 }}
                  />

                  <TextField
                    fullWidth
                    label="Notlar"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={note}
                    disabled={porterList.length === 0}
                    onChange={(e) => setNote(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                </>
              )
            }
          </div>
        ) : (
          <>
            <DialogContentText>
              {
                <span style={{ fontWeight: '700', color: 'black' }}>
                  {count}
                </span>
              }{' '}
              adet gönderinin <b>({packageCount} paket)</b> teslimi için {phone} numaralı telefona gönderilen
              onay kodunu giriniz
            </DialogContentText>

            <TextField
              fullWidth
              label="Kodu girin"
              variant="outlined"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              sx={{ mt: 2 }}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        {/* <Button
          variant="contained"
          color="info"
          fullWidth={false}
          onClick={() => createPDF()}
        >
          PDF'e Aktar
        </Button>
        <Button
          variant="contained"
          color="success"
          fullWidth={false}
          onClick={() => exportToExcel()}
        >
          Excele Aktar
        </Button> */}
        <Button onClick={handleBack} color="primary">
          Geri
        </Button>
        {selectedOption === 'manuel' ? (
          <Button
            disabled={
              name?.length < 2 || lastname?.length < 2 || phone?.length !== 10
                ? true
                : false
            }
            onClick={handleNext}
            color="primary"
          >
            {step === 1 ? 'İlerle' : 'Teslim Et'}
          </Button>
        ) : (
          <Button
            disabled={
              name?.length < 2 ||
                lastname?.length < 2 ||
                (phone?.length !== 10 && phone?.length !== 11)
                ? true
                : false
            }
            onClick={handleNext}
            color="primary"
          >
            {step === 1 ? 'İlerle' : 'Teslim Et'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DeliveryModal;
