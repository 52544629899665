import React, { useState, useEffect } from 'react';
import {
  Backdrop,
  CircularProgress,
  FormControl,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import api from 'src/services/BaseService';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useLocalization } from 'src/context/LocalizationContext';

const AddEditHandShakeModal = ({
  open,
  onClose,
  isEdit,
  editData,
  porterList,
  branchList,
  mallList,
  isBackdropOpen,
}) => {

  const { language } = useLocalization();
  const [showResultMessage, setShowResultMessage] = useState(false);
  const [resultMessage, setResultMessage] = useState('');

  const [formData, setFormData] = useState({
    handshakeID: '',
    mallID: '',
    branchID: '',
    porterID: '',
    avmTitle: '',
    createdDateTime: '',
    irsaliyeNo: '',
    portName: '',
    seriNo: '',
    transportationTool: '',
    transportationToolCount: '',
    isPrimary: false,
    porterNameLastname: ''
  });


  const cargoList = [
    language.commons.cargo_list.parcel_shipping,
    language.commons.cargo_list.small_car,
    language.commons.cargo_list.big_car,
    language.commons.cargo_list.hanging_car,
    language.commons.cargo_list.medium_vehicle,
    language.commons.cargo_list.palette,
  ];

  const formattedDateTime = moment.unix(editData.createdDateTime)

  const handleSave = () => {


    const requestBody = {
      handshakeID: editData.handshakeID,
      createdDateTime: formData.createdDateTime.format('YYYY-MM-DDTHH:mm:ssZ'),
      branchID: formData.branchID,
      details: [
        {
          transportationTool: formData.transportationTool,
          transportationToolCount: formData.transportationToolCount,
        },
      ],
      porterID: formData?.porterID,
      porterNameLastname: formData?.porterNameLastname,
      documentNo: [
        {
          irsaliyeNo: formData.irsaliyeNo,
          mallID: formData.mallID,
          seriNo: formData.seriNo,
        },
      ],
    };

    api
      .post('/handshakes/update-manuel', requestBody)
      .then((response) => {
        if (response.data.status) {
          //console.log(response.data)
          setResultMessage('Mağaza başarıyla Düzenlendi!');
          setShowResultMessage(true);
        } else {
          //console.log("error",response)
          setResultMessage('Mağaza eklenirken bir sorunla karşılaşıldı!');
          setShowResultMessage(true);
          // toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
        // toast.error(error.message);
      });
  };

  const allFieldsAreValid = () => {
    if (
      formData.avmTitle !== '' &&
      formData.branchID !== '' &&
      formData.irsaliyeNo !== '' &&
      formData.seriNo !== '' &&
      formData.transportationTool !== '' &&
      formData.transportationToolCount !== '' &&
      formData.mallID !== ''
    ) {
      return true;
    } else return false;
  };


  const handleBranchChange = async (event) => {
    const { value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      branchID: value,
    }));
  };

  const handlePorterChange = async (event) => {
    const { value } = event.target;
    const selectedPorter = porterList.find((porter) => porter.id === value);

    setFormData((prevState) => ({
      ...prevState,
      porterID: value,
      porterNameLastname: selectedPorter?.name + ' ' + selectedPorter?.lastname
    }));
  };
  const handleCargoChange = async (event) => {
    const { name, value } = event.target;
    if (name === 'tool') {
      setFormData((prevState) => ({
        ...prevState,
        transportationTool: value,
      }));
    } else if (name === 'toolCount') {
      setFormData((prevState) => ({
        ...prevState,
        transportationToolCount: value,
      }));
    } else {
      return;
    }
  };

  const handleDateChange = (newValue) => {
    setFormData((prevState) => ({
      ...prevState,
      createdDateTime: newValue,
    }));
  };

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === 'seriNo') {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleClose = () => {
    setFormData({});
    onClose();
  };

  const handleCloseMessage = () => {
    setShowResultMessage(false);
    setFormData({});
    onClose();
  };

  useEffect(() => {
    if (isEdit) {
      let isPrimaryBoolean;
      if (editData.isPrimary === '1') {
        isPrimaryBoolean = true;
      } else {
        isPrimaryBoolean = false;
      }


      setFormData({
        handshakeID: '',
        mallID: editData.mallID,
        branchID: editData?.branchID,
        porterID: editData?.porterID,
        createdDateTime: moment.unix(editData.createdDateTime),
        irsaliyeNo: editData?.irsaliyeNo,
        portName: editData?.portName,
        seriNo: editData?.seriNo,
        transportationTool: editData?.transportationTool,
        transportationToolCount: editData?.transportationToolCount,
        isPrimary: isPrimaryBoolean,
        porterNameLastname: editData?.porterNameLastname
      });
      //console.log("editData",editData)
      //console.log("formdatya",formData)
    }
  }, []);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <Backdrop sx={{ color: '#fff', zIndex: 2 }} open={isBackdropOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <DialogTitle>{language.components.AddEditHandShakeModal.title}</DialogTitle>

        <DialogContent>
          <>

            <FormControl fullWidth margin="normal">
              <InputLabel>{language.commons.column.store_name}*</InputLabel>
              <Select
                label={language.commons.column.store_name + '*'}
                value={formData.branchID}
                onChange={handleBranchChange}
                name="avmTitle"
                disabled={branchList?.length === 0}
              >
                {branchList?.map((branch, index) => (
                  <MenuItem key={index} value={branch.branchID}>
                    {branch.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label={language.commons.column.waybill_no + '*'}
              name="irsaliyeNo"
              value={formData.irsaliyeNo}
              onChange={handleTextFieldChange}
              margin="normal"
            />

            <TextField
              fullWidth
              label={language.commons.column.cob_no + '*'}
              name="seriNo"
              value={formData.seriNo}
              onChange={handleTextFieldChange}
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>{language.commons.column.transport_person + '*'}</InputLabel>
              <Select
                label={language.commons.column.transport_person + '*'}
                value={formData.porterID}
                onChange={handlePorterChange}
                name="porterName"
              //disabled={porterList.length === 0}
              >
                {porterList.map((porter, index) => (
                  <MenuItem key={index} value={porter.id}>
                    {porter.name} {porter.lastname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label={language.commons.column.count + '*'}
              name="toolCount"
              value={formData.transportationToolCount}
              onChange={handleCargoChange}
              margin="normal"
            />  <FormControl fullWidth margin="normal">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  name="createdDateTime"
                  sx={{ mt: 2, marginRight: '8px' }}
                  format="DD/MM/YYYY"
                  label={language.commons.column.created_date}
                  value={formattedDateTime}
                  onChange={handleDateChange}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Hizmet*</InputLabel>
              <Select
                label={language.commons.column.service + '*'}
                value={formData.transportationTool}
                onChange={handleCargoChange}
                name="tool"
                disabled={cargoList.length === 0}
              >
                {cargoList.map((cargo, index) => (
                  <MenuItem key={index} value={cargo}>
                    {cargo}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>


          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {language.commons.back}
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            variant="contained"
            disabled={!allFieldsAreValid()}
          >
            {language.commons.save}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showResultMessage}
        onClose={handleCloseMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isEdit === true ? (
          <DialogTitle id="alert-dialog-title">
            {language.commons.store_update}
          </DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-title">{language.commons.store_add}</DialogTitle>
        )}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {resultMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessage}>{language.commons.okay}</Button>{' '}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddEditHandShakeModal;
