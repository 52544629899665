import { Box, Card } from '@mui/material';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

function AppBarChart() {
    const [chartData] = useState({
        series: [
            {
                name: 'Yerli Kullanıcı',
                data: [3.1, 3.8, 2.5, 2.0],
            },
            {
                name: 'Diğer Milletlerden',
                data: [2.3, 2.5, 3.0, 1.2],
            },
        ],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: false,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: ['Kasım', 'Aralık', 'Ocak', 'Şubat'],
            },
            yaxis: {
                title: {
                    text: 'Milyar Kişi',
                },
            },
            fill: {
                opacity: 1,
            },
            colors: ['#2979FF', '#A9A9A9'],
            legend: {
                position: 'top',
                markers: {
                    fillColors: ['#2979FF', '#A9A9A9'],
                },
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + ' Milyar';
                    },
                },
            },
        },
    });

    return (
        <Card>
            <Box sx={{ p: 0, pb: 1, height: '350px' }} dir="ltr">
                <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
            </Box>
        </Card>

    );
}

export default AppBarChart;
