import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import '../pages/index.css';
// @mui
import {
  Card,
  Stack,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  FormControl,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
// sections
import './muhaberatOutput.css';
// ----------------------------------------------------------------------
import Services from 'src/services/Services';
import api from 'src/services/BaseService';
import AddEntityButton from 'src/components/shared/AddEntityButton';
import ExportToExcelButton from 'src/components/shared/ExportToExcelButton';
import AddEditUserModal from 'src/components/modals/AddEditUserModal';
import EditButton from 'src/components/shared/EditButton';
import { getUserTypeLabel } from 'src/utils/getUserLabel';
import DynamicTable from 'src/components/shared/DynamicTable';
import CustomFilter from 'src/components/filter/CustomFilter';
import FilterViewButton from 'src/components/shared/FilterViewButton';
import { useLocalization } from 'src/context/LocalizationContext';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user?.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function UserPage() {

  const { language } = useLocalization();
  const mallID = localStorage.getItem('mallID');
  const parsedUser = JSON.parse(localStorage.getItem('user'));
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [filterUsername, setFilterUsername] = useState('');
  const [filterStores, setFilterStores] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [allDatas, setAllDatas] = useState([]);
  const [userList, setUserList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [malls, setMalls] = useState([]);
  const [user, setUser] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [pointsToShow, setPointsToShow] = useState([]);
  const [showPopUpError, setShowPopUpError] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');

  const handleModalFilter = () => {
    setFilterModal(true);
  };

  const handleCloseModalFilter = () => {
    setFilterModal(false);
  };

  const TABLE_HEAD = [

    { id: 'name', label: language.commons.column.name_surname, alignRight: false },
    { id: 'type', label: language.commons.column.user_type, alignRight: false, format: (value, row) => getUserTypeLabel(row.type) },
    { id: 'allowedPoints', label: language.commons.column.store_point, alignRight: false, format: (value, row) => renderAllowedPoints(row.allowedPoints) },
    {
      id: 'roles', label: language.commons.column.auth_status, alignRight: false,
      format: (value, row) => {
        const role = getRoles(row.frontAuth)
        if (role === 'Operasyon') {
          return (
            <Typography
              sx={{
                background: '#B9E6FE',
                textAlign: 'center',
                padding: '4px 12px 4px 10px',
                borderRadius: '5px',
                color: 'black',
                fontFamily: 'Inter',
                fontSize: 14,
                fontWeight: 500,
                letterSpacing: 0.15,
              }}
            >
              {language.commons.labels.operation}
            </Typography>
          );
        } else if (role === 'Yönetici') {
          return (
            <Typography
              sx={{
                textAlign: 'center',
                background: '#FEA3B4',
                padding: '4px 12px 4px 10px',
                borderRadius: '5px',
                color: 'black',
                fontFamily: 'Inter',
                fontSize: 14,
                fontWeight: 500,
                letterSpacing: 0.15,
              }}
            >
              {language.commons.labels.manager}
            </Typography>
          );
        } else if (role === 'Operasyon, Yönetici') {
          return (
            <Typography
              sx={{
                textAlign: 'center',
                background: '#FFD6AE',
                padding: '4px 12px 4px 10px',
                borderRadius: '5px',
                color: 'black',
                fontFamily: 'Inter',
                fontSize: 14,
                fontWeight: 500,
                letterSpacing: 0.15,
              }}
            >
              {language.commons.labels.manager_operation}
            </Typography>
          );
        } else {
          return null;
        }

      }
    },
    {
      id: 'status', label: language.commons.labels.user_status, alignRight: false,
      format: (value, row) => {
        return row.status === '1' ? (
          <Button
            variant="outlined"
            sx={{
              background: '#ECFDF3',
              padding: ' 4px 12px 4px 10px',
              borderRadius: '5px',
              borderColor: '#ABEFC6',
              borderWidth: 1,
              color: '#067647',
              fontFamily: 'Inter',
              fontSize: 14,
              fontWeight: 500,
              letterSpacing: 0.15,
              marginLeft: '10px',
              '&:hover': {
                backgroundColor: '#ceded5',
                borderColor: '#93cfaa',
              },
            }}
          >
            • {language.commons.labels.active}
          </Button>
        ) : row.status === '0' ? (
          <Button
            variant="outlined"
            sx={{
              background: '#F9FAFB',
              padding: ' 4px 12px 4px 10px',
              borderRadius: '5px',
              borderColor: '#EAECF0',
              borderWidth: 1,
              color: '#344054',
              fontFamily: 'Inter',
              fontSize: 14,
              fontWeight: 500,
              letterSpacing: 0.15,
              '&:hover': {
                backgroundColor: '#cacbcc',
                borderColor: '#b1b3b5',
              },
            }}
          >
            • {language.commons.labels.passive}
          </Button>
        ) : (
          <Button
            variant="outlined"
            sx={{
              background: '#FFFAEB',
              padding: ' 4px 12px 4px 10px',
              borderRadius: '5px',
              borderColor: '#FEDF89',
              borderWidth: 1,
              color: '#B54708',
              fontFamily: 'Inter',
              fontSize: 14,
              fontWeight: 500,
              letterSpacing: 0.15,
              '&:hover': {
                backgroundColor: '#e3ded1',
                borderColor: '#d1b871',
              },
            }}
          >
            •{language.commons.labels.waiting_user}
          </Button>
        )
      }
    },
    { id: 'username', label: language.commons.labels.username, alignRight: false },
    { id: 'phone', label: language.commons.labels.phone, alignRight: false },
    { id: 'createdDateTime', label: language.commons.labels.register_date, alignRight: false, format: (value) => moment.unix(value).format('DD/MM/YYYY'), },
    {
      id: 'action', label: '', alignRight: false, format: (value, row) => <EditButton
        onClick={() => {
          setModalType('edit');
          handleUserEdit(row);
          setDialogOpen(true);
        }}
      />
    },
  ];

  const handleOpenModal = (points) => {
    setPointsToShow(points);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setPointsToShow([]);
  };

  const renderAllowedPoints = (points) => {
    if (points.length <= 3) {
      return points.map((point, index) => <div key={index}>{point.title}</div>);
    } else {
      const firstFourPoints = points.slice(0, 3);
      return (
        <>
          {firstFourPoints.map((point, index) => (
            <div key={index}>{point.title}</div>
          ))}
          <Button onClick={() => handleOpenModal(points)}>
            {language.commons.see_all}
          </Button>
        </>
      );
    }
  };

  const handleUserEdit = (userData) => {
    setUser(userData);
  };

  function getUserList(status) {
    setLoadingUsers(true);

    Services.GetUsers(parsedUser.token, mallID, status)
      .then((response) => {
        if (response.data.status) {
          setUserList(response.data.result);
          console.log(response.data.result, 'Kullanıcı');
          setAllDatas(response.data.result);
          setFilteredData(response.data.result);
        } else {
          setUserList([]);
          setAllDatas([]);
          setFilteredData([]);
          toast.error('Kullanıcı Bulunamadı.');
        }
      })
      .catch((error) => {
        setUserList([]);
        setAllDatas([]);
        setFilteredData([]);
        toast.error('Kullanıcı Bulunamadı.');
      })

      .finally(() => {
        setLoadingUsers(false);
      });
  }

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const filterByNameSurname = (text) => {
    setFilterName(text);
    const query = text?.toLowerCase();
    const filteredUsers = allDatas.filter(
      (item) =>
        item?.name?.toLowerCase().includes(query) ||
        item?.lastname?.toLowerCase().includes(query)
    );
    setUserList(filteredUsers);
    setFilteredData(filteredUsers);
  };

  const filterByUsername = (text) => {
    setFilterUsername(text);
    const query = text?.toLowerCase();
    const filteredUsers = allDatas.filter((item) =>
      item?.username?.toLowerCase().includes(query)
    );
    setUserList(filteredUsers);
    setFilteredData(filteredUsers);
  };

  const filterByStores = (text) => {
    setFilterStores(text);
    const query = text?.toLowerCase();
    const filteredUsers = allDatas.filter((item) => {
      return item.allowedPoints.some((point) =>
        point?.title?.toLowerCase().includes(query)
      );
    });

    setUserList(filteredUsers);
    setFilteredData(filteredUsers);
  };

  const getBranchTitles = (authLocations) => {
    if (authLocations && authLocations.branch) {
      return authLocations.branch.map((branch) => branch?.title).join(', ');
    }
    return '';
  };

  const getRoles = (roles) => {
    if (roles) {
      const mappedRoles = roles.map((role) =>
        role === 'manager'
          ? 'Yönetici'
          : role === 'operations'
            ? 'Operasyon'
            : role
      );

      if (
        mappedRoles.includes('Operasyon') &&
        mappedRoles.includes('Yönetici')
      ) {
        return 'Operasyon, Yönetici';
      } else if (mappedRoles.includes('Operasyon')) {
        return 'Operasyon';
      } else if (mappedRoles.includes('Yönetici')) {
        return 'Yönetici';
      }
    }
    return '';
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList?.length) : 0;

  const filteredUsers = applySortFilter(
    filteredData,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredUsers?.length && !!filterName;

  useEffect(() => {
    getUserList('1');
  }, [mallID]);

  function exportUserToExcel() {
    let temp1 = userList.map((x, index) => ({
      id: index + 1,
      name: `${x.name} ${x.lastname}`,
      shop: getBranchTitles(x.authLocations),
      authorization: getRoles(x.frontAuth),
      userstatus:
        x.status === '1' ? 'Aktif' : x.status === '3' ? 'Bekleniyor' : 'Pasif',
      email: x.username,
      phone: x.phone,
      createdDateTime: moment
        .unix(x.createdDateTime)
        .format('DD/MM/YYYY HH:mm'),
    }));

    api
      .post('excel/export', {
        type: 'excel',
        content: temp1,
        header: {
          No: 'number',
          'Ad Soyad': 'string',
          'Ait Olunan Mağaza': 'string',
          'Yetki Durumu': 'string',
          'Kullanıcı Durumu': 'string',
          'E-mail Adresi': 'string',
          Telefon: 'string',
          'Kayıt Tarihi': 'string',
        },
        sheetTitle: 'İşler',
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data.status) {
          try {
            const newWindow = window.open(response.data.result, '_blank');
            if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
              setShowPopUpError(true)
              throw new Error('Pop-up engellendi. Lütfen pop-up engelleyicisini devre dışı bırakın.');
            }
          } catch (e) {
            setShowPopUpError(true)
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
        toast.error(error.message);
      });
  }

  const getMalls = () => {
    api
      .post('/malls/get-malls', {
        withOffices: true,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          setMalls(response.data.result);
        } else {
          // toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    getMalls();
    // console.log(getMalls());
  }, []);

  return (
    <>
      <Helmet>
        <title> {language.user_page.titles.site_title} </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Stack>
            <Typography variant="h4" gutterBottom>
              {language.user_page.titles.title}
            </Typography>
          </Stack>
          <Stack direction={isMobile ? 'column' : 'row'} spacing={2} alignItems="end">
            <AddEntityButton
              label={'Kullanıcı Ekle'}
              onClick={() => {
                setModalType('new');
                setDialogOpen(true);
              }}
            />
            <ExportToExcelButton
              setShowPopUpError={setShowPopUpError}
              showPopUpError={showPopUpError}
              sx={{ marginLeft: '7px' }}
              onClick={() => exportUserToExcel()} />
          </Stack>


        </Stack>
        <Stack
          direction="row"
          justifyContent={isMobile ? 'space-between' : 'end'}
          mb={5}
        >
          {isMobile ? (
            <FilterViewButton variant="contained" onClick={handleModalFilter} />
          ) : null}
        </Stack>
        <Card>
          <CustomFilter
            modalType={'drawer'}
            filterModal={filterModal}
            handleCloseModal={handleCloseModalFilter}
          >
            <Stack direction="column"
              alignItems="center"
              justifyContent="space-between"
              paddingY={2}
              paddingX={1}
              spacing={2}>
              <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                <TextField
                  label={language.commons.labels.name}
                  variant="outlined"
                  autoComplete="off"
                  value={filterName}
                  onChange={(e) => filterByNameSurname(e.target.value)}
                  sx={{ my: 2, marginLeft: 1 }}
                />
              </FormControl>
              <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                <TextField
                  label={language.commons.labels.username}
                  variant="outlined"
                  autoComplete="off"
                  value={filterUsername}
                  onChange={(e) => filterByUsername(e.target.value)}
                  sx={{ my: 2, marginLeft: 1 }}
                />
              </FormControl>
              <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                <TextField
                  label={language.commons.labels.stores}
                  variant="outlined"
                  autoComplete="off"
                  value={filterStores}
                  onChange={(e) => filterByStores(e.target.value)}
                  sx={{ my: 2, marginLeft: 1 }}
                />
              </FormControl>


            </Stack>
            <div
              style={{
                width: '100%',
                height: '1px',
                borderTop: '1px solid #ccc',
                marginBottom: '2%',
              }}
            ></div>
            <div style={{ color: '#ccc', marginLeft: '1%' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '2%',
                }}
              >
                <div>{language.commons.labels.user_status}: </div>
                <Button
                  onClick={() => getUserList('1')}
                  variant="outlined"
                  sx={{
                    background: '#ECFDF3',
                    padding: ' 4px 12px 4px 10px',
                    borderRadius: '5px',
                    borderColor: '#ABEFC6',
                    borderWidth: 1,
                    color: '#067647',
                    fontFamily: 'Inter',
                    fontSize: 14,
                    fontWeight: 500,
                    letterSpacing: 0.15,
                    marginLeft: '10px',
                    '&:hover': {
                      backgroundColor: '#ceded5',
                      borderColor: '#93cfaa',
                    },
                  }}
                >
                  • {language.commons.labels.active_users}
                </Button>
                <div
                  style={{
                    width: '2%',
                    height: '1px',
                    borderTop: '1px solid #ccc',
                    transform: 'rotate(90deg)',
                  }}
                ></div>
                <Button
                  onClick={() => getUserList('0')}
                  variant="outlined"
                  sx={{
                    background: '#F9FAFB',
                    padding: ' 4px 12px 4px 10px',
                    borderRadius: '5px',
                    borderColor: '#EAECF0',
                    borderWidth: 1,
                    color: '#344054',
                    fontFamily: 'Inter',
                    fontSize: 14,
                    fontWeight: 500,
                    letterSpacing: 0.15,
                    '&:hover': {
                      backgroundColor: '#cacbcc',
                      borderColor: '#b1b3b5',
                    },
                  }}
                >
                  • {language.commons.labels.passive_users}
                </Button>

              </div>
            </div>
          </CustomFilter>



          <DynamicTable
            data={userList}
            headers={TABLE_HEAD}
            initialOrder={'name'}
            initalOrderDirection={'asc'}
            isOrdered={true}
            isMobile={isMobile}
            handleModal={handleModalFilter}
            handleCloseModal={handleCloseModalFilter}
            rowOnClick={(row) => {
              setModalType('edit');
              handleUserEdit(row);
              setDialogOpen(true);
            }}
          />

        </Card>
      </Stack>
      <AddEditUserModal
        dialogOpen={dialogOpen}
        malls={malls}
        modalType={modalType}
        setDialogOpen={setDialogOpen}
        user={user}
        setUser={setUser}
        getUserList={getUserList}
      />
      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle>{language.commons.all_points}</DialogTitle>
        <DialogContent>
          <List>
            {pointsToShow.map((point, index) => (
              <ListItem key={index}>
                <ListItemText primary={point.title} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>{language.commons.close}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
