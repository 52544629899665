import axios from 'axios';
import { API_URL, HANDSFREE_URL } from 'src/constants/urls';

// const API_URL = process.env.REACT_APP_API_URL;
// const HANDSFREE_URL = process.env.HANDSFREE_APP_API_URL;

export default {
  async CreateOrder(array) {
    const token = localStorage.getItem('tokenForService');
    return axios({
      method: 'post',
      url: `${API_URL}mall-logistics/create`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: array,
    });
  },

  Login(username, password) {
    return axios({
      method: 'post',
      url: `${API_URL}login`,
      data: {
        username: username,
        password: password,
      },
    });
  },

  ForgotPassword(token, username) {
    return axios({
      method: 'post',
      url: `${API_URL}users/forgot-password`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        username: username,
      },
    });
  },

  GetPoints(token) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-points`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },

  GetTokenForErrorTicket() {
    return axios({
      method: 'post',
      url: `${API_URL}get-token`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        key: 'wHjOKqH25WwOEeHphyGR',
        secret: '37m2LWciLhboNNFA6UkQ',
      },
    });
  },

  GetToken() {
    return axios({
      method: 'post',
      url: `${HANDSFREE_URL}get-token`,
      data: {
        key: 'CeQJ3t6OT8Ka5RTiDPU1tBepbUQumY',
        secret: '4VtrWHHHrWYmnYUlOHncj7DSlapfhA',
      },
    });
  },
  UpdateProfile(token, user) {
    return axios({
      method: 'post',
      url: `${API_URL}users/update-profile`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: user,
    });
  },

  CreateEmployee(token, user) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/users-create-employee`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: user,
    });
  },

  GetTokenForCreateEmployeeWithoutLogin() {
    return axios({
      method: 'post',
      url: `${API_URL}get-token`,
      headers: {
        GUID: '24BE-DB0E-D75E-4060',
        'Content-Type': 'application/json',
      },
      data: {
        key: 'wHjOKqH25WwOEeHphyGR',
        secret: '37m2LWciLhboNNFA6UkQ',
      },
    });
  },
  GetStores(token, mallID) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-malls-branches`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        mallID: mallID,
      },
    });
  },
  GetMalls(token) {
    return axios({
      method: 'post',
      url: `${API_URL}malls/get-malls`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },
  GetMallsForCreateEmployeeWithoutLogin(token) {
    return axios({
      method: 'post',
      url: `${API_URL}malls/get-malls`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        withOffices: false,
      },
    });
  },
  GetMallsForSuperadminLogin(token) {
    return axios({
      method: 'post',
      url: `${API_URL}malls/get-malls`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        withOffices: true,
      },
    });
  },
  SearchBranchByTaxNo(token, taxNo, mallID) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/search-branch-by-tax-no`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        taxNo: taxNo,
        mallID: mallID,
      },
    });
  },

  CreateEmployeeWithoutLogin(token, userObj) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/user-create`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        mallID: userObj?.mallID,
        corporationID: userObj?.corporationID,
        branchID: userObj?.branchID,
        email: userObj?.email,
        phone: userObj?.phoneNumber,
        name: userObj?.firstName,
        lastname: userObj?.lastName,
        password: userObj?.password,
        username: userObj?.username,
        type: 'corporational',
        isAdministrator: false,
        accountCategory: 'store',
        frontAuth: ['operations'],
        authLocations: {
          branch: [userObj?.branchID],
        },
      },
    });
  },

  GetHandsFreeOrders(token, branchID) {
    return axios({
      method: 'post',
      url: `${HANDSFREE_URL}flow/get-location-orders`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        mallLogisticsID: branchID,
      },
    });
  },

  GetHandsFreePosts(token, branchID, status) {
    return axios({
      method: 'post',
      url: `${HANDSFREE_URL}flow/get-branch-posts`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        mallLogisticsID: branchID,
        status: status,
      },
    });
  },

  GetHandsFreeConfirm(token, orderID, count) {
    return axios({
      method: 'post',
      url: `${HANDSFREE_URL}flow/order-confirm`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        orderID: orderID,
        count: count,
      },
    });
  },

  GetHandsFreeReject(token, orderID) {
    return axios({
      method: 'post',
      url: `${HANDSFREE_URL}flow/order-reject`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        orderID: orderID,
      },
    });
  },

  CreateHandsfreeByStore(
    token,
    locationID,
    formData,
    additionalNote,
    createdBy
  ) {
    return axios({
      method: 'post',
      url: `${HANDSFREE_URL}flow/create-order-by-store`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        locationID: locationID,
        name: formData.name,
        lastname: formData.lastname,
        email: formData.email,
        inventory: formData.inventory,
        phone: formData.phone,
        additionalNote: additionalNote,
        createdBy: createdBy,
      },
    });
  },

  GetPrices() {
    return axios({
      method: 'get',
      url: `https://api.easypoint.com.tr/boxapi/box-list`,
    });
  },

  GetLocation(token, branchID) {
    return axios({
      method: 'post',
      url: `${API_URL}locations/get-locations`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        branchID: branchID,
      },
    });
  },

  GetUsers(token, mallID, status) {
    return axios({
      method: 'post',
      url: `${API_URL}users/get-users`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        // type: 'corporational',
        mallID: mallID,
        status: status,
      },
    });
  },
  GetUser(token, userID) {
    return axios({
      method: 'post',
      url: `${API_URL}users/get-user`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        userID: userID,
        withAuthOffices: true,
      },
    });
  },

  CreateTicket(token, title, description, type, files, username) {
    return axios({
      method: 'post',
      url: `${API_URL}support/create-ticket`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        title: title,
        description: description,
        files: files,
        type: type,
        username: username,
      },
    });
  },

  GetUserByID(token, userID) {
    return axios({
      method: 'post',
      url: `${API_URL}users/get-user`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        userID: userID,
      },
    });
  },

  GetReport(token, queryType, startDate, endDate) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-report`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        type: 'auto',
        queryType: queryType,
        startDate: startDate,
        endDate: endDate,
      },
    });
  },

  GetUserForTickets(token, userID) {
    return axios({
      method: 'post',
      url: `${API_URL}users/get-user`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        userID: userID,
      },
    });
  },

  GetTickets(token, status) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-tickets`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        status: status,
      },
    });
  },

  GetTicketsUser(token, createdBy) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-tickets`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        createdBy: createdBy,
      },
    });
  },

  GetTicketsStaff(token, responsibleBy) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-tickets`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        responsibleBy: responsibleBy,
        status: 3,
        operator: 'or',
      },
    });
  },

  GetTicketsManager(token) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-tickets`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        status: [1, 3, 4],
      },
    });
  },

  GetTicket(token, id) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-ticket`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        id: id,
      },
    });
  },

  GetTicketsByUser(token, createdBy) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-tickets`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        createdBy: createdBy,
      },
    });
  },

  UserUpdate(token, frontAuth, userID, isAdministrator) {
    //  console.log('Service Response', {
    //   token: token,
    //   frontAuth: frontAuth,
    //   userID: userID,
    //   isAdministrator: isAdministrator,
    // });
    return axios({
      method: 'post',
      url: `${API_URL}users/update-profile`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        frontAuth: frontAuth,
        userID: userID,
        isAdministrator: isAdministrator,
      },
    });
  },

  UserSetActive(token, userID) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/user-set-active`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        userID: userID,
      },
    });
  },

  UserSetPassive(token, userID) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/user-set-passive`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        userID: userID,
      },
    });
  },

  GetStatisticsByMall(token, mallID, type, date) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/analytics`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        mallID: mallID,
        type: type,
        date: date,
      },
    });
  },

  GetStatisticsByBranch(token, branchID, type, date) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/analytics`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        branchID: branchID,
        type: type,
        date: date,
      },
    });
  },

  GetGraphStatisticsByBranch(token, branchID, type, date, onlyCompleted) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/analytics`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        branchID: branchID,
        type: type,
        date: date,
        onlyCompleted: onlyCompleted,
      },
    });
  },

  GetGraphStatisticsByMall(token, mallID, type, date, onlyCompleted) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/analytics`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        mallID: mallID,
        type: type,
        date: date,
        onlyCompleted: onlyCompleted,
      },
    });
  },

  GetMLToken() {
    return axios({
      method: 'post',
      url: `${API_URL}get-token`,
      data: {
        key: 'wHjOKqH25WwOEeHphyGR',
        secret: '37m2LWciLhboNNFA6UkQ',
      },
    });
  },

  GetAgreement(token, handle) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/agreements-get-static`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        handle: handle,
      },
    });
  },

  CreatePreHandshake(
    token,
    mallID,
    branchID,
    details,
    username,
    password,
    totalPackageCount,
    storeConfirmation
  ) {
    return axios({
      method: 'post',
      url: `${API_URL}handshakes/create-pre`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        mallID: mallID,
        branchID: branchID,
        details: details,
        username: username,
        password: password,
        totalPackageCount: totalPackageCount,
        storeConfirmation: storeConfirmation,
      },
    });
  },
  GetCampaigns(token) {
    return axios({
      method: 'post',
      url: `${API_URL}campaigns/get-campaigns`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        audiences: ['mall-logistics', 'corporational-customers', 'porters'],
        allCampaigns: true,
      },
    });
  },

  CreateCampaign(
    token,
    title,
    description,
    start,
    end,
    images,
    link,
    targetAudience
  ) {
    return axios({
      method: 'post',
      url: `${API_URL}campaigns/create`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        title: title,
        description: description,
        targetAudience: targetAudience,
        start: start,
        end: end,
        link: link,
        images: images,
      },
    });
  },
  UpdateCampaign(
    token,
    campaignID,
    title,
    description,
    start,
    end,
    images,
    link,
    targetAudience,
    status
  ) {
    return axios({
      method: 'post',
      url: `${API_URL}campaigns/update`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        campaignID: campaignID,
        title: title,
        description: description,
        targetAudience: targetAudience,
        start: start,
        end: end,
        link: link,
        images: images,
        status: status,
      },
    });
  },

  GetDropboxLocations(token) {
    return axios({
      method: 'get',
      url: `${API_URL}flow/drop-box-get-locations`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },

  GetDropboxListByLocations(token, locationCode, startDate, endDate) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/drop-box-list-by-location-code`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        locationCode: locationCode,
        startDate: startDate,
        endDate: endDate,
      },
    });
  },

  UpdateMallLogisticsPost(
    token,
    postID,
    barcode,
    shipmentFirm,
    orderInventory,
    tower,
    office,
    customerName,
    customer,
    companyName,
    from,
    to
  ) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/mall-logistics-update`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        postID: postID,
        barcode: barcode,
        shipmentFirm: shipmentFirm,
        orderInventory: orderInventory,
        tower: tower,
        office: office,
        customerName: customerName,
        customer: customer,
        companyName: companyName,
        from: from,
        to: to,
      },
    });
  },

  GetCargoFirms(token) {
    return axios({
      method: 'get',
      url: `${API_URL}flow/get-cargo-firms`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },

  CreateHandover(token, postID, message, name, lastname, phone, note) {
    return axios({
      method: 'post',
      url: `${API_URL}handover/malllogistics/create`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        postID: postID,
        message: message,
        name: name,
        lastname: lastname,
        phone: phone,
        note: note,
      },
    });
  },
};
