import { Box, Card } from '@mui/material';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

function AppPieChart({seriesData, labelsData, colorsData}) {
    const [chartData] = useState({
        series: seriesData, // Müsait ve Kullanımda değerleri
        options: {
            chart: {
                type: 'pie',
                height: 350,
            },
            labels: labelsData,
            colors: colorsData,
            legend: {
                position: 'bottom',
                markers: {
                    fillColors: colorsData,
                },
            },
            dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                    return opts.w.globals.series[opts.seriesIndex] + ' kişi';
                },
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + ' kişi';
                    },
                },
            },
        },
    });

    return (
        <Card>
            <Box sx={{ p: 0, pb: 1, height: '350px' }} dir="ltr">
                <ReactApexChart options={chartData.options} series={chartData.series} type="pie" height={350} />
            </Box>
        </Card>
    );
}

export default AppPieChart;
