import { Helmet } from 'react-helmet-async';
import { filter, sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import moment from 'moment';
// @mui
import {
  Card,
  Stack,
  Button,
  Typography,
  TextField,
  useMediaQuery,
  FormControl,
  Divider,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import api from 'src/services/BaseService';
import './muhaberatOutput.css';
import AddEditHandShakeModal from 'src/components/modals/AddEditHandShakeModal';
import ClearButton from 'src/components/shared/ClearButton';
import FilterButton from 'src/components/shared/FilterButton';
import EditButton from 'src/components/shared/EditButton';
import ExportToExcelButton from 'src/components/shared/ExportToExcelButton';
import DynamicTable from 'src/components/shared/DynamicTable';
import CustomFilter from 'src/components/filter/CustomFilter';
import FilterViewButton from 'src/components/shared/FilterViewButton';
import { tokenService } from 'src/services';
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function ManuelHandShakeList() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('createdDateTime');
  const [filterName, setFilterName] = useState('');
  const [storeList, setStoreList] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [title, setTitle] = useState('');
  const [irsaliyeNo, setIrsaliyeNo] = useState('');
  const [seriNo, setSeriNo] = useState('');
  const [toolCount, setToolCount] = useState();
  const [addEditStoreOpen, setAddEditStoreOpen] = useState(false);
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [responsiblePerson, setResponsiblePerson] = useState('');
  const [isPrimary, setIsPrimary] = useState('');
  const [porterList, setPorterList] = useState([]);
  const [branchList, setBranchList] = useState([]);

  const [mallList, setMallList] = useState([]);
  const [isBackdropOpen, setIsBackdropOpen] = useState(true);
  const [storeListBackDrop, setStoreListBackDrop] = useState(true);
  const [activeList, setActiveList] = useState('handshakes'); // 'handshakes' or 'cancelledHandshakes'
  const [showPopUpError, setShowPopUpError] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const [filterModal, setFilterModal] = useState(false);

  const handleModal = () => {
    setFilterModal(true);
  };

  const handleCloseModal = () => {
    setFilterModal(false);
  };

  const STORE_TABLE_HEAD = [
    { id: 'avmTitle', label: 'Mağaza Adı', alignRight: false },
    {
      id: 'irsaliyeNo ',
      label: 'İrsaliye',
      alignRight: false,
      format: (value, row) => {
        return row.irsaliyeNo;
      },
      sortBy: 'irsaliyeNo',
    },
    { id: 'seriNo', label: 'Koçan', alignRight: false },
    {
      id: 'responsiblePerson',
      label: 'Taşıma Personeli',
      alignRight: false,
      sortBy: 'porter.name',
      format: (value, row) => {
        return row.porter?.name + ' ' + row.porter?.lastname;
      },
    },
    {
      id: 'createdDateTime',
      label: 'Oluşturulma Tarihi',
      alignRight: false,
      format: (value) =>
        moment.unix(value).format('DD/MM/YYYY HH:mm') === '01/01/1970 02:00'
          ? ''
          : moment.unix(value).format('DD/MM/YYYY HH:mm'),
    },
    { id: 'transportationToolCount', label: 'Adet', alignRight: false },
    { id: 'transportationTool', label: 'Hizmet', alignRight: false },
    {
      id: 'operation',
      label: '',
      alignRight: false,
      format: (value, row) => {
        return tokenService?.getUser()?.accountCategory !== 'company' ? (
          <EditButton onClick={() => handleEdit(row)} />
        ) : (
          <span>&nbsp;</span>
        );
      },
      sx: { width: 100 },
    },
  ];

  const CANCEL_STORE_TABLE_HEAD = [
    {
      id: 'irsaliyeNo ',
      label: 'İrsaliye',
      alignRight: false,
      format: (value, row) => {
        return row.irsaliyeNo;
      },
    },
    { id: 'seriNo', label: 'Koçan', alignRight: false },
    {
      id: 'createdDateTime',
      label: 'Oluşturulma Tarihi',
      alignRight: false,
      format: (value) =>
        moment.unix(value).format('DD/MM/YYYY HH:mm') === '01/01/1970 02:00'
          ? ''
          : moment.unix(value).format('DD/MM/YYYY HH:mm'),
    },
    { id: 'reason', label: 'İptal Nedeni', alignRight: false },
  ];

  const handleEdit = (rowData) => {
    // console.log("rowData", rowData);
    setEditData(rowData);
    setAddEditStoreOpen(true);
    setIsModalEdit(true);
  };

  const handleCloseAddStoreModal = () => {
    setAddEditStoreOpen(false);
    getHandShakes();
  };

  const getHandShakes = () => {
    setActiveList('handshakes');
    api
      .post('handshakes/get', {
        mallID: mallID,
        type: 'manuel',
      })
      .then((response) => {
        if (response.data.status) {
          let allStores = response.data.result;
          const realVariable = allStores.map((store) => ({
            handshakeID: store.handshakeID,
            branchID: store.branchID,
            porterID: store.confirmationValue.porterID,
            avmTitle: store.branchTitle,
            createdDateTime: store.createdDateTime,
            irsaliyeNo: store.confirmationValue.documentNo[0]?.irsaliyeNo,
            seriNo: store.confirmationValue.documentNo[0]?.seriNo,
            transportationTool: store.details[0]?.transportationTool,
            transportationToolCount: store.details[0]?.transportationToolCount,
            portName: store.confirmationValue.porterID?.name,
            mallID: store.confirmationValue.documentNo[0]?.mallID,
            porter: store.porter,
            porterNameLastname: store.porter_name + ' ' + store.porter_lastname,
          }));
          setStoreList(realVariable);
          setOriginalData(realVariable);
          setStoreListBackDrop(false);
          setActiveList('handshakes');
        } else {
          setStoreListBackDrop(false);
        }
      })
      .catch((error) => {
        // console.log(error); //
      });
  };
  const getCancelHandShakes = () => {
    setActiveList('cancelledHandshakes');
    api
      .post('flow/get-cancelled-manuel-handshakes')
      .then((response) => {
        if (response.data.status) {
          let allStores = response.data.result;
          const realVariable = allStores.map((store) => ({
            createdBy: store.createdBy,
            createdDateTime: store.createdDateTime,
            reason: store.reason,
            irsaliyeNo: store.receiptNo,
            seriNo: store.receiptSerial,
            // handshakeID: store.handshakeID,
            // branchID: store.branchID,
            // porterID: store.confirmationValue.porterID,
            // avmTitle: store.branchTitle,
            // createdDateTime: store.createdDateTime,
            // irsaliyeNo: store.confirmationValue.documentNo[0]?.irsaliyeNo,
            // seriNo: store.confirmationValue.documentNo[0]?.seriNo,
            // transportationTool: store.details[0]?.transportationTool,
            // transportationToolCount: store.details[0]?.transportationToolCount,
            // portName: store.confirmationValue.porterID?.name,
            // mallID: store.confirmationValue.documentNo[0]?.mallID,
            // porter: store.porter,
            // porterNameLastname:store.porterNameLastname
          }));
          setStoreList(realVariable);
          setOriginalData(realVariable);
          setStoreListBackDrop(false);
        } else {
          setStoreListBackDrop(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const mallID = localStorage.getItem('mallID');
  const branchID = localStorage.getItem('branchID');

  useEffect(() => {
    if (tokenService?.getUser()?.accountCategory === 'company') {
      api
        .post('handshakes/get', {
          branchID: branchID,
          type: 'manuel',
        })
        .then((response) => {
          if (response.data.status) {
            let allStores = response.data.result;
            const realVariable = allStores.map((store) => ({
              handshakeID: store.handshakeID,
              branchID: store.branchID,
              porterID: store.confirmationValue.porterID,
              avmTitle: store.branchTitle,
              createdDateTime: store.createdDateTime,
              irsaliyeNo: store.confirmationValue.documentNo[0]?.irsaliyeNo,
              seriNo: store.confirmationValue.documentNo[0]?.seriNo,
              transportationTool: store.details[0]?.transportationTool,
              transportationToolCount:
                store.details[0]?.transportationToolCount,
              portName: store.confirmationValue.porterID?.name,
              mallID: store.confirmationValue.documentNo[0]?.mallID,
              porter: store.porter,
              porterNameLastname:
                store.porter_name + ' ' + store.porter_lastname,
            }));
            setStoreList(realVariable);
            setOriginalData(realVariable);
            setStoreListBackDrop(false);
            setActiveList('handshakes');
          } else {
            setStoreListBackDrop(false);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    } else {
      api
        .post('handshakes/get', {
          mallID: mallID,
          type: 'manuel',
        })
        .then((response) => {
          if (response.data.status) {
            let allStores = response.data.result;
            const realVariable = allStores.map((store) => ({
              handshakeID: store.handshakeID,
              branchID: store.branchID,
              porterID: store.confirmationValue.porterID,
              avmTitle: store.branchTitle,
              createdDateTime: store.createdDateTime,
              irsaliyeNo: store.confirmationValue.documentNo[0]?.irsaliyeNo,
              seriNo: store.confirmationValue.documentNo[0]?.seriNo,
              transportationTool: store.details[0]?.transportationTool,
              transportationToolCount:
                store.details[0]?.transportationToolCount,
              portName: store.confirmationValue.porterID?.name,
              mallID: store.confirmationValue.documentNo[0]?.mallID,
              porter: store.porter,
              porterNameLastname:
                store.porter_name + ' ' + store.porter_lastname,
            }));
            setStoreList(realVariable);
            setOriginalData(realVariable);
            setStoreListBackDrop(false);
            setActiveList('handshakes');
          } else {
            setStoreListBackDrop(false);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }

    api
      .post('users/get-users', { type: 'porter', mallID: mallID })
      .then((response) => {
        const result = response.data.result;
        setPorterList(result);
      });

    api.post('flow/get-malls-branches', { mallID: mallID }).then((response) => {
      const { result } = response.data;
      setBranchList(result);
    });

    api
      .post('/malls/get-malls', {
        withOffices: true,
      })
      .then((response) => {
        //console.log("result",response.data.result);

        if (response.data) {
          setMallList(response.data.result);
          setIsBackdropOpen(false);
        } else {
          // toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [mallID]);

  const getFilteredHandShakeList = () => {
    let filteredStoreList;

    if (
      title ||
      irsaliyeNo ||
      seriNo ||
      responsiblePerson ||
      (startDate && endDate) ||
      toolCount
    ) {
      filteredStoreList = originalData.filter((item) => {
        const seriNoMatch = seriNo
          ? String(item.seriNo)
              .toLowerCase()
              .includes(String(seriNo)?.toLowerCase())
          : true;
        const irsaliyeNoMatch = irsaliyeNo
          ? String(item.irsaliyeNo)
              .toLowerCase()
              .includes(String(irsaliyeNo)?.toLowerCase())
          : true;
        const titleMatch = title
          ? item.avmTitle.toLowerCase().includes(title.toLowerCase())
          : true;
        const responsibleMatchPerson = responsiblePerson
          ? (item.porter.name + ' ' + item.porter.lastname)
              .toLowerCase()
              .includes(responsiblePerson.toLowerCase())
          : true;
        const dateMatch =
          startDate && endDate
            ? moment.unix(item.createdDateTime).format('DD-MM-YYYY') >=
                moment(startDate).format('DD-MM-YYYY') &&
              moment.unix(item.createdDateTime).format('DD-MM-YYYY') <=
                moment(endDate).format('DD-MM-YYYY')
            : true;

        const toolMatchCount = toolCount
          ? item.transportationToolCount === toolCount
          : true;

        return (
          titleMatch &&
          responsibleMatchPerson &&
          irsaliyeNoMatch &&
          seriNoMatch &&
          dateMatch &&
          toolMatchCount
        );
      });
    } else {
      filteredStoreList = originalData;
    }

    setStoreList(filteredStoreList);
  };

  const clearFilters = () => {
    setStoreList(originalData);
    setResponsiblePerson('');
    setTitle('');
    setIrsaliyeNo('');
    setSeriNo('');
    setStartDate(null);
    setEndDate(null);
    setToolCount('');
    setIsPrimary('');
  };

  const filteredStores = applySortFilter(
    storeList,
    getComparator(order, orderBy),
    filterName
  );

  const exportUserToExcel = () => {
    let tempStoreList = storeList.map((x, index) => ({
      id: index + 1,
      avmTitle: x.avmTitle,
      irsaliyeNo: x.irsaliyeNo,
      seriNo: x.seriNo,
      porter: `${x.porter.name} ${x.porter.lastname}`,
      createdDateTime: moment
        .unix(x.createdDateTime)
        .format('DD/MM/YYYY HH:mm'),
      transportationToolCount: x.transportationToolCount,
      transportationTool: x.transportationTool,
    }));

    api
      .post('excel/export', {
        type: 'excel',
        content: tempStoreList,
        header: {
          No: 'number',
          'Avm İsmi': 'string',
          'İrsaliye No': 'string',
          'Koçan No': 'string',
          'Porter Ad Soyad': 'string',
          'Oluşturulma Tarihi': 'string',
          'Taşıma Adeti': 'number',
          Hizmet: 'string',
        },
        sheetTitle: 'Mal Kabul Dosya Listesi',
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data.status) {
          try {
            const newWindow = window.open(response.data.result, '_blank');
            if (
              !newWindow ||
              newWindow.closed ||
              typeof newWindow.closed == 'undefined'
            ) {
              setShowPopUpError(true);
              throw new Error(
                'Pop-up engellendi. Lütfen pop-up engelleyicisini devre dışı bırakın.'
              );
            }
          } catch (e) {
            setShowPopUpError(true);
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <>
      <Helmet>
        <title> Mal Kabul Dosya Listesi | Mall Logistics Panel </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Stack direction="row">
            <Typography variant="h4" gutterBottom>
              Mal Kabul Dosya Listesi
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="end">
            {activeList === 'handshakes' && (
              <ExportToExcelButton
                setShowPopUpError={setShowPopUpError}
                showPopUpError={showPopUpError}
                sx={{ marginLeft: '10px' }}
                onClick={() => exportUserToExcel()}
              />
            )}
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent={isMobile ? 'space-between' : 'end'}
          mb={5}
        >
          {isMobile ? (
            <FilterViewButton variant="contained" onClick={handleModal} />
          ) : null}
        </Stack>
        <Card>
          <CustomFilter
            modalType={'drawer'}
            filterModal={filterModal}
            handleCloseModal={handleCloseModal}
          >
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="space-between"
              paddingY={2}
              paddingX={1}
            >
              <Stack
                width={'100%'}
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                paddingY={2}
                spacing={2}
              >
                {tokenService?.getUser()?.accountCategory !== 'company' && (
                  <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                    <TextField
                      label="Mağaza Adı"
                      variant="outlined"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </FormControl>
                )}

                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    label="İrsaliye No"
                    variant="outlined"
                    type="number"
                    value={irsaliyeNo}
                    onChange={(e) => setIrsaliyeNo(e.target.value)}
                  />
                </FormControl>
                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    label="Seri No"
                    variant="outlined"
                    type="number"
                    value={seriNo}
                    onChange={(e) => setSeriNo(e.target.value)}
                  />
                </FormControl>
                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    label="Taşıma Personeli"
                    variant="outlined"
                    value={responsiblePerson}
                    onChange={(e) => setResponsiblePerson(e.target.value)}
                  />
                </FormControl>
                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    label="Adet"
                    variant="outlined"
                    type="number"
                    value={toolCount}
                    onChange={(e) => setToolCount(parseInt(e.target.value, 10))}
                  />
                </FormControl>
              </Stack>

              <Stack
                direction="column"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="row">
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      label="Başlangıç Tarihi"
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                    />
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      label="Bitiş Tarihi"
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                    />
                  </LocalizationProvider>
                </Stack>

                <hr />

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '20px', // İrsaliye Durumu ile filtre butonu arasındaki mesafa artırıldı
                    width: '100%',
                  }}
                >
                  <div style={{ color: '#ccc', marginLeft: '2%', flex: 1 }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div>İrsaliye Durumu: </div>

                      <Button
                        onClick={() => getHandShakes()}
                        variant="outlined"
                        sx={{
                          background: '#ECFDF3',
                          padding: ' 4px 12px 4px 10px',
                          borderRadius: '5px',
                          borderColor: '#ABEFC6',
                          borderWidth: 1,
                          color: '#067647',
                          fontFamily: 'Inter',
                          fontSize: 14,
                          fontWeight: 500,
                          letterSpacing: 0.15,
                          marginLeft: '10px',
                          '&:hover': {
                            backgroundColor: '#ceded5',
                            borderColor: '#93cfaa',
                          },
                        }}
                      >
                        • Geçerli
                      </Button>
                      <div
                        style={{
                          width: '1%',
                          height: '1px',
                          borderTop: '1px solid #ccc',
                          transform: 'rotate(90deg)',
                        }}
                      ></div>
                      <Button
                        onClick={() => getCancelHandShakes()}
                        variant="outlined"
                        sx={{
                          background: '#FFFAEB',
                          padding: ' 4px 12px 4px 10px',
                          borderRadius: '5px',
                          borderColor: '#FEDF89',
                          borderWidth: 1,
                          color: '#B54708',
                          fontFamily: 'Inter',
                          fontSize: 14,
                          fontWeight: 500,
                          letterSpacing: 0.15,
                          '&:hover': {
                            backgroundColor: '#e3ded1',
                            borderColor: '#d1b871',
                          },
                        }}
                      >
                        • İptal
                      </Button>
                    </div>
                  </div>
                </div>
                <Divider sx={{ width: '100%' }} />
                <Stack direction="row" paddingY={2} paddingX={1}>
                  <ClearButton onClick={() => clearFilters()} />
                  <FilterButton onClick={() => getFilteredHandShakeList()} />
                </Stack>
              </Stack>
            </Stack>
          </CustomFilter>

          {activeList === 'handshakes' && (
            <DynamicTable
              data={filteredStores}
              headers={STORE_TABLE_HEAD}
              initialOrder={'createdDateTime'}
              initalOrderDirection={'desc'}
              isOrdered={true}
              isMobile={isMobile}
              handleModal={handleModal}
              handleCloseModal={handleCloseModal}
              rowOnClick={(row) => handleEdit(row)}
            />
          )}

          {activeList === 'cancelledHandshakes' && (
            <DynamicTable
              data={filteredStores}
              headers={CANCEL_STORE_TABLE_HEAD}
              initialOrder={'createdDateTime'}
              initalOrderDirection={'desc'}
              isOrdered={true}
              isMobile={isMobile}
              handleModal={handleModal}
              handleCloseModal={handleCloseModal}
            />
          )}
        </Card>
      </Stack>

      {addEditStoreOpen && (
        <AddEditHandShakeModal
          open={addEditStoreOpen}
          onClose={handleCloseAddStoreModal}
          isEdit={isModalEdit}
          editData={editData}
          porterList={porterList}
          branchList={branchList}
          mallList={mallList}
          isBackdropOpen={isBackdropOpen}
        />
      )}
    </>
  );
}
