import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import { ProtectedRoute } from './sections/auth/ProtectedRoute';
import FinishedJobsPage from './pages/FinishedJobsPage';
import FinishedJobsDetailPage from './pages/FinishedJobsDetailPage';
import Porters from './pages/Porters';
import MuhaberatEntryPage from './pages/MuhaberatEntry';
import MuhaberatOutput from './pages/MuhaberatOutput';
import MuhaberatOutputUsers from './pages/MuhaberatOutputUsers';
import ManuelHandShake from './pages/Manuel-handshake';
import HandsfreePage from './pages/HandsfreePage';
import AllActionsPage from './pages/AllActionsPage';
import RobotListsPage from './pages/RobotListsPage';
import RobotActionsPage from './pages/RobotActionsPage';
import PriceTablePage from './pages/PriceTablePage';
import TechnicalSupportRequests from './pages/TechnicalSupportRequests';
import FormWithoutLogin from './pages/FormWithoutLogin';
import CreateEmployeeApproval from './pages/CreateEmployeeApproval';
import FetchBranches from './pages/FetchBranches';
import RequestReceivedPage from './pages/RequestReceivedPage';
import SettingsPage from './pages/SettingsPage';
import StoreQr from './pages/StoreQr';
import PriceCabineTable from './pages/priceCabineTable';
import StoreList from './pages/StoreList';
import EditStore from './pages/EditStore';
import AvmList from './pages/AvmList';
import ManuelHandShakeList from './pages/Handshake-list';
import ProfilePage from './pages/ProfilePage';
import SupportTickets from './pages/SupportTickets';
import PointsPage from './pages/PointsPage';
import EditUserForPoint from './pages/EditUserForPoint';
import DataTableTest from './pages/DataTableTest';
import CreateReport from './pages/CreateReport';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import PreHandshake from './pages/PreHandshake';
import PreHandshakeList from './pages/PreHandshakeList';
import CampaignPage from './pages/CampaignPage';
import SafeBoxReport from './pages/SafeBoxReport';
import SafeBoxOperations from './pages/SafeBoxOperations';
import CreateForm from './pages/CreateForm';
import TutorialPage from './pages/TutorialPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        {
          path: 'app',
          element: (
            <ProtectedRoute>
              <DashboardAppPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'finished-jobs',
          element: (
            <ProtectedRoute>
              <FinishedJobsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'finished-jobs-detail/:id',
          element: (
            <ProtectedRoute>
              <FinishedJobsDetailPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'porters',
          element: (
            <ProtectedRoute>
              <Porters />
            </ProtectedRoute>
          ),
        },
        {
          path: 'users',
          element: (
            <ProtectedRoute>
              <UserPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'muhaberat-entry',
          element: (
            <ProtectedRoute>
              <MuhaberatEntryPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'muhaberat-output',
          element: (
            <ProtectedRoute>
              <MuhaberatOutput />
            </ProtectedRoute>
          ),
        },
        {
          path: 'muhaberat-output-users',
          element: (
            <ProtectedRoute>
              <MuhaberatOutputUsers />
            </ProtectedRoute>
          ),
        },
        {
          path: 'store-list',
          element: (
            <ProtectedRoute>
              <StoreList />
            </ProtectedRoute>
          ),
        },
        {
          path: 'support-tickets',
          element: (
            <ProtectedRoute>
              <SupportTickets />
            </ProtectedRoute>
          ),
        },
        {
          path: 'support-tickets/:id',
          element: (
            <ProtectedRoute>
              <SupportTickets />
            </ProtectedRoute>
          ),
        },
        {
          path: 'support-tickets/complete/:id',
          element: (
            <ProtectedRoute>
              <SupportTickets />
            </ProtectedRoute>
          ),
        },
        {
          path: 'edit-store/:id/:mallID',
          element: (
            <ProtectedRoute>
              <EditStore />
            </ProtectedRoute>
          ),
        },
        {
          path: 'avm-list',
          element: (
            <ProtectedRoute>
              <AvmList />
            </ProtectedRoute>
          ),
        },
        {
          path: 'manuel-handshake',
          element: (
            <ProtectedRoute>
              <ManuelHandShake />
            </ProtectedRoute>
          ),
        },
        {
          path: 'manuel-handshake-list',
          element: (
            <ProtectedRoute>
              <ManuelHandShakeList />
            </ProtectedRoute>
          ),
        },
        {
          path: 'pre-handshake-list',
          element: (
            <ProtectedRoute>
              <PreHandshakeList />
            </ProtectedRoute>
          ),
        },
        {
          path: 'handsfree',
          element: (
            <ProtectedRoute>
              <HandsfreePage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'all-actions',
          element: (
            <ProtectedRoute>
              <AllActionsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'robot-lists',
          element: (
            <ProtectedRoute>
              <RobotListsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'robot-actions',
          element: (
            <ProtectedRoute>
              <RobotActionsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'monthly-prices',
          element: (
            <ProtectedRoute>
              <PriceTablePage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'monthly-cabine',
          element: (
            <ProtectedRoute>
              <PriceCabineTable />
            </ProtectedRoute>
          ),
        },
        {
          path: 'users',
          element: (
            <ProtectedRoute>
              <UserPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'points',
          element: (
            <ProtectedRoute>
              <PointsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'edit-point-user/:id',
          element: (
            <ProtectedRoute>
              <EditUserForPoint />
            </ProtectedRoute>
          ),
        },
        {
          path: 'technical-support-requests',
          element: (
            <ProtectedRoute>
              <TechnicalSupportRequests />
            </ProtectedRoute>
          ),
        },
        {
          path: 'create-employee-approval',
          element: (
            <ProtectedRoute>
              <CreateEmployeeApproval />
            </ProtectedRoute>
          ),
        },
        {
          path: 'fetch-branches',
          element: (
            <ProtectedRoute>
              <FetchBranches />
            </ProtectedRoute>
          ),
        },
        {
          path: 'qr',
          element: (
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'store-qr',
          element: (
            <ProtectedRoute>
              <StoreQr />
            </ProtectedRoute>
          ),
        },
        {
          path: 'profile',
          element: (
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'data-table',
          element: (
            <ProtectedRoute>
              <DataTableTest />
            </ProtectedRoute>
          ),
        },
        {
          path: 'create-report',
          element: (
            <ProtectedRoute>
              <CreateReport />
            </ProtectedRoute>
          ),
        },
        {
          path: 'campaign',
          element: (
            <ProtectedRoute>
              <CampaignPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'create-form',
          element: (
            <ProtectedRoute>
              <CreateForm />
            </ProtectedRoute>
          ),
        },
        {
          path: 'safe-box-report',
          element: (
            <ProtectedRoute>
              <SafeBoxReport />
            </ProtectedRoute>
          ),
        },
        {
          path: 'safe-box-operations',
          element: (
            <ProtectedRoute>
              <SafeBoxOperations />
            </ProtectedRoute>
          ),
        },
        {
          path: 'tutorials',
          element: (
            <ProtectedRoute>
              <TutorialPage />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPasswordPage />,
    },
    {
      path: 'form-without-login',
      element: <FormWithoutLogin />,
    },
    {
      path: 'request-received',
      element: <RequestReceivedPage />,
    },
    {
      path: 'pre-handshake/:avmID',
      element: (<PreHandshake />),
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
