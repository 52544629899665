import React from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import api from 'src/services/BaseService';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Container,
  Modal,
  Box,
  Typography,
  TextField,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import './FinishedJobsDetailPage.css';

import { StatusList } from 'src/types/statusTypes';
import { tokenService } from 'src/services';
import { toast } from 'react-toastify';
import HandoverModal from './Views/HandsoverModal';
import Services from 'src/services/Services';

export default function FinishedJobsDetailPage() {
  const [barcode, setBarcode] = useState();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [porterList, setPorterList] = useState([]);
  const [selectedPorter, setSelectedPorter] = useState(null);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [openHandoverModal, setOpenHandoverModal] = useState(false);
  // const data = location.state.data;
  const { id } = useParams();

  const handleOpenHandoverModal = () => {
    setOpenHandoverModal(true);
  };

  const handleCloseHandoverModal = () => {
    setOpenHandoverModal(false);
  };

  const handleCompleteDelivery = (formData) => {
    // Burada API çağrısı yapılabilir
    const postData = [
      {
        postID: data?.id,
        porterID: tokenService.getUser()?.userID,
        transportationTool: data?.requestedTransportationTool,
        transportationToolCount: '1',
        inventory: +data?.orderInventory,
        status: 14,
        externalPerson: {
          name: formData.name,
          lastname: formData.lastname,
          phone: formData.phone,
          code: formData.confirmationCode,
        },
      },
    ];
    api
      .post('transactions/create', postData)
      .then((res) => {
        if (res.data.status) {
          api
            .post('transactions/complete', {
              transactionID: [res.data.result[0].id],
            })
            .then((res2) => {
              if (res2.data.status) {
                api
                  .post('posts/change-status', [
                    { postID: data?.id, status: 1 },
                  ])
                  .then((res3) => {
                    if (res3.data.status) {
                      toast.success(
                        'Teslim işlemi başarıyla gerçekleştirildi.'
                      );
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                    } else {
                      toast.error(
                        'Gönderinin statüs değeri değiştirilirken bir hata meydana geldi.'
                      );
                    }
                  })
                  .catch(() =>
                    toast.error(
                      'Gönderinin statüs değeri değiştirilirken bir hata meydana geldi.'
                    )
                  );
              } else {
                toast.error(
                  'Taşıma işleminin statüs değeri değiştirilirken bir hata meydana geldi.'
                );
              }
            })
            .catch(() =>
              toast.error(
                'Taşıma işleminin statüs değeri değiştirilirken bir hata meydana geldi.'
              )
            );
        } else {
          toast.error(
            'Teslim işlemi gerçekleştirilirken bir hata meydana geldi.'
          );
        }
      })
      .catch(() =>
        toast.error('Teslim işlemi gerçekleştirilirken bir hata meydana geldi.')
      );
  };

  const getPorterList = () => {
    const mallID = localStorage.getItem('mallID');
    api
      .post('users/get-users', {
        type: 'porter',
        mallID: mallID,
      })
      .then((response) => {
        if (response.data.status) {
          setPorterList(response.data.result);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
        toast.error(error.message);
      });
  };

  const cancelPost = () => {
    api
      .post('posts/change-status', [
        { postID: id, status: 2, reason: cancelReason },
      ])
      .then((res) => {
        // console.log(res.data);
        if (res.data.status) {
          toast.success('İptal işlemi başarıyla tamamlandı.');
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else toast.error('İş iptal edilirken bir hata meydana geldi.');
      })
      .catch((err) => {
        console.log(err);
        toast.error('İş iptal edilirken bir hata meydana geldi.');
      })
      .finally(() => setLoading(false));
  };

  const cancelTransaction = (transactionIDs) => {
    api
      .post(
        'transactions/change-status',
        transactionIDs.map((id) => ({ transactionID: id, status: 2 }))
      )
      .then((res) => {
        // console.log(res.data);
        if (res.data.status) cancelPost();
        else {
          toast.error('İş iptal edilirken bir hata meydana geldi.');
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('İş iptal edilirken bir hata meydana geldi.');
        setLoading(false);
      });
  };

  const cancel = () => {
    setLoading(true);
    api
      .post('transactions/get', {
        postID: id,
      })
      .then((res) => {
        // console.log(res.data);
        const transactionIDs = res.data.result.map(
          (item) => item.transactionID
        );
        if (res.data.status && transactionIDs.length > 0)
          cancelTransaction(transactionIDs);
        else cancelPost();
      })
      .catch((err) => {
        console.log(err);
        cancelPost();
      });
  };

  useEffect(() => {
    api
      .post('posts/get', {
        barcode: id,
        withLogs: true,
        useForce:
          tokenService?.getUser()?.accountCategory === 'company' ? true : false,
      })
      .then((response) => {
        if (response.data.status) {
          setData(response.data.result[0]);
          // console.log(response.data.result);
        } else {
          setData(null);
        }
      })
      .catch((error) => {
        setData(null);
      });
    getPorterList();
  }, []);

  const formatDate = (date) => {
    const formattedDate = moment.unix(date).format('DD.MM.YYYY - HH:mm');
    return formattedDate;
  };

  const calculateAndFormatDeliveryTime = (
    createdDateTime,
    completedDateTime
  ) => {
    const createdMoment = moment.unix(
      data && data.on_porter_date_time !== null
        ? data.on_porter_date_time
        : createdDateTime
    );
    const completedMoment = moment.unix(completedDateTime);
    const duration = moment.duration(completedMoment.diff(createdMoment));
    const hours = duration.asHours();

    if (hours < 1) {
      const minutes = Math.round(hours * 60);
      return `${minutes} dakika`;
    } else {
      const roundedHours = Math.floor(hours);
      const remainingMinutes = Math.round((hours - roundedHours) * 60);
      if (remainingMinutes > 0) {
        return `${roundedHours} saat ${remainingMinutes} dakika`;
      } else {
        return `${roundedHours} saat`;
      }
    }
  };

  const getStatusLabel = (statusValue) => {
    const status = StatusList.find((item) => item.id === +statusValue);
    return status ? status.label : '';
  };

  return (
    <>
      <Helmet>
        <title> Gönderi Detay | Mall Logistics Panel </title>
      </Helmet>
      {data === null ? (
        <></>
      ) : (
        <Container style={{ marginBottom: 30 }}>
          {(data.status === '9' || data.status === '21') && (
            <div
              onClick={handlePrintClick}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBlock: 15,
                cursor: 'pointer',
              }}
            >
              <Print fontSize="large" />
            </div>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6} sm={7}>
              <div
                style={{
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                  paddingBottom: '29px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                <div
                  style={{
                    minHeight: '50px',
                    width: '100%',
                    backgroundColor: '#172955',
                    borderTopRightRadius: '8px',
                    borderTopLeftRadius: '8px',
                    padding: '10px',
                    marginBottom: '15.5px',
                  }}
                >
                  <span
                    style={{
                      color: '#F3F4F6',
                      fontWeight: '600',
                      fontSize: '12px',
                    }}
                  >
                    {data.title}
                  </span>
                </div>
                <div
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                ></div>
                <div className="customRow">
                  <text
                    style={{
                      fontSize: 14,
                      fontWeight: '400',
                      color: '#8890A0',
                    }}
                  >
                    ID:
                  </text>
                  <text style={{ color: '#111827', fontSize: 14 }}>
                    {data.id}
                  </text>
                </div>
                <div className="divider" />
                <div className="customRow">
                  <text
                    style={{
                      fontSize: 14,
                      fontWeight: '400',
                      color: '#8890A0',
                    }}
                  >
                    Barkod:
                  </text>
                  <text style={{ color: '#111827', fontSize: 14 }}>
                    {data.barcode}
                  </text>
                </div>
                <div className="divider" />
                <div className="customRow">
                  <text
                    style={{
                      fontSize: 14,
                      fontWeight: '400',
                      color: '#8890A0',
                    }}
                  >
                    Kargo Türü:
                  </text>
                  <text style={{ color: '#111827', fontSize: 14 }}>Paket</text>
                </div>
                <div className="divider" />
                <div className="customRow">
                  <text
                    style={{
                      fontSize: 14,
                      fontWeight: '400',
                      color: '#8890A0',
                    }}
                  >
                    Paket Adedi:
                  </text>
                  <text style={{ color: '#111827', fontSize: 14 }}>
                    {data.orderInventory}
                  </text>
                </div>
                <div className="divider" />
                <div className="customRow">
                  <text
                    style={{
                      fontSize: 14,
                      fontWeight: '400',
                      color: '#8890A0',
                    }}
                  >
                    Çıkış - Varış Noktası:
                  </text>
                  <text style={{ color: '#111827', fontSize: 14 }}>
                    {data.from.title} → {data.to.title}
                  </text>
                </div>
                <div className="divider" />
                {data.takenName && (
                  <>
                    <div className="customRow">
                      <text
                        style={{
                          fontSize: 14,
                          fontWeight: '400',
                          color: '#8890A0',
                        }}
                      >
                        Gönderen:
                      </text>
                      <text style={{ color: '#111827', fontSize: 14 }}>
                        {data.takenName}
                      </text>
                    </div>
                    <div className="divider" />
                  </>
                )}
                {/* {data.willTakenBy !== ' ' && (
                  <>
                    {' '}
                    <div className="customRow">
                      <text style={{ fontSize: 14, fontWeight: '400', color: '#8890A0' }}>
                        Alıcı:
                      </text>
                      <text style={{ color: '#111827', fontSize: 14 }}>{data.willTakenBy}</text>
                    </div>
                    <div className="divider" />
                  </>
                )} */}
                {data.phone !== null && (
                  <>
                    {' '}
                    <div className="customRow">
                      <text
                        style={{
                          fontSize: 14,
                          fontWeight: '400',
                          color: '#8890A0',
                        }}
                      >
                        Alıcı Telefon No:
                      </text>
                      <text style={{ color: '#111827', fontSize: 14 }}>
                        {data.phone?.slice(0, -7) + '*******'}
                      </text>
                    </div>
                    <div className="divider" />
                  </>
                )}
                {/* <div className="customRow">
                  <text style={{ fontSize: 14, fontWeight: '400', color: '#8890A0' }}>
                    Teslim Edilecek İl:
                  </text>
                  <text style={{ color: '#111827', fontSize: 14 }}>{data.sentToCity}</text>
                </div>
                <div className="divider" />
                <div className="customRow">
                  <text style={{ fontSize: 14, fontWeight: '400', color: '#8890A0' }}>
                    Teslim Edilecek İlçe:
                  </text>
                  <text style={{ color: '#111827', fontSize: 14 }}>{data.sentToRegion}</text>
                </div>
                <div className="divider" />
                <div className="customRow">
                  <text style={{ fontSize: 14, fontWeight: '400', color: '#8890A0' }}>
                    Teslim Edilecek Easy Point Noktası:
                  </text>
                  <text style={{ color: '#111827', fontSize: 14 }}>{data.pointTitle}</text>
                </div>{' '}
                <div className="divider" /> */}
                {data.shipmentFirm && (
                  <>
                    <div className="customRow">
                      <text
                        style={{
                          fontSize: 14,
                          fontWeight: '400',
                          color: '#8890A0',
                        }}
                      >
                        Kargo Firması:
                      </text>
                      <text style={{ color: '#111827', fontSize: 14 }}>
                        {data.shipmentFirm}
                      </text>
                    </div>
                    <div className="divider" />
                  </>
                )}
                {data.shipmentNo && (
                  <>
                    {' '}
                    <div className="customRow">
                      <text
                        style={{
                          fontSize: 14,
                          fontWeight: '400',
                          color: '#8890A0',
                        }}
                      >
                        Kargo Takip No:
                      </text>
                      <text style={{ color: '#111827', fontSize: 14 }}>
                        {data.shipmentNo}
                      </text>
                    </div>
                    <div className="divider" />
                  </>
                )}
                {data.apiUserType === 'mall-logistics' ? (
                  <>
                    <div className="customRow">
                      <text
                        style={{
                          fontSize: 14,
                          fontWeight: '400',
                          color: '#8890A0',
                        }}
                      >
                        Oluşturan Kullanıcı:
                      </text>
                      <text style={{ color: '#111827', fontSize: 14 }}>
                        {data.createdByName + ' ' + data.createdByLastname}
                      </text>
                    </div>
                    <div className="divider" />
                  </>
                ) : (
                  <>
                    <div className="customRow">
                      <text
                        style={{
                          fontSize: 14,
                          fontWeight: '400',
                          color: '#8890A0',
                        }}
                      >
                        Oluşturan Kullanıcı:
                      </text>
                      <text style={{ color: '#111827', fontSize: 14 }}>
                        {data.additionalNote}
                      </text>
                    </div>
                    <div className="divider" />
                  </>
                )}
                {data.porterID && (
                  <>
                    <div className="customRow">
                      <text
                        style={{
                          fontSize: 14,
                          fontWeight: '400',
                          color: '#8890A0',
                        }}
                      >
                        Taşıyan Kullanıcı:
                      </text>
                      <text style={{ color: '#111827', fontSize: 14 }}>
                        {data.porterNameLastname}
                      </text>
                    </div>
                    <div className="divider" />
                  </>
                )}
                <div className="customRow">
                  <text
                    style={{
                      fontSize: 14,
                      fontWeight: '400',
                      color: '#8890A0',
                    }}
                  >
                    İş Emri Oluşturma Tarihi:
                  </text>
                  <text style={{ color: '#111827', fontSize: 14 }}>
                    {formatDate(data.createdDateTime)}
                  </text>
                </div>
                <div className="divider" />
                {data.status === '12' &&
                  tokenService.getUser()?.accountCategory !== 'store' && (
                    <>
                      <div
                        className="customRow"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <text
                          style={{
                            fontSize: 14,
                            fontWeight: '400',
                            color: '#8890A0',
                          }}
                        >
                          Porter:
                        </text>
                        <FormControl sx={{ width: 300 }} margin="normal">
                          <InputLabel>Porter</InputLabel>
                          <Select
                            label="Porter"
                            value={selectedPorter}
                            onChange={(e) => setSelectedPorter(e.target.value)}
                            name="porter"
                            disabled={porterList?.length === 0}
                          >
                            {porterList?.map((porter, index) => (
                              <MenuItem key={index} value={porter.id}>
                                {porter.name} {porter.lastname}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="divider" />
                    </>
                  )}
                {data.on_porter_date_time !== null && (
                  <>
                    <div className="customRow">
                      <text
                        style={{
                          fontSize: 14,
                          fontWeight: '400',
                          color: '#8890A0',
                        }}
                      >
                        Zimmete Alınma Tarihi:
                      </text>
                      <text style={{ color: '#111827', fontSize: 14 }}>
                        {formatDate(data.on_porter_date_time)}
                      </text>
                    </div>
                    <div className="divider" />
                  </>
                )}
                {data.status === '1' && (
                  <>
                    <div className="customRow">
                      <text
                        style={{
                          fontSize: 14,
                          fontWeight: '400',
                          color: '#8890A0',
                        }}
                      >
                        Tamamlanma Tarihi:
                      </text>
                      <text style={{ color: '#111827', fontSize: 14 }}>
                        {formatDate(data?.completedDateTime)}
                      </text>
                    </div>
                    <div className="divider" />
                    <div className="customRow">
                      <text
                        style={{
                          fontSize: 14,
                          fontWeight: '400',
                          color: '#8890A0',
                        }}
                      >
                        Taşıma Süresi:
                      </text>
                      <text style={{ color: '#111827', fontSize: 14 }}>
                        {calculateAndFormatDeliveryTime(
                          data.createdDateTime,
                          data.completedDateTime
                        )}
                      </text>
                    </div>
                    <div className="divider" />
                  </>
                )}
                <div className="customRow">
                  <text
                    style={{
                      fontSize: 14,
                      fontWeight: '400',
                      color: '#8890A0',
                    }}
                  >
                    İş Emri Durumu:
                  </text>

                  <text
                    style={{
                      backgroundColor: 'rgb(77 177 88)',
                      color: 'white',
                      padding: 3,
                      borderRadius: 5,
                      paddingInline: 10,
                      fontSize: '12px',
                    }}
                  >
                    {getStatusLabel(data.status)}
                  </text>
                </div>
                {(data.status === '3' ||
                  data.status === '5' ||
                  data.status === '12') &&
                  tokenService.getUser()?.accountCategory !== 'store' && (
                    <>
                      <div className="divider" />
                      <div className="customRow">
                        <text
                          style={{
                            fontSize: 14,
                            fontWeight: '400',
                            color: '#8890A0',
                          }}
                        >
                          Teslim
                        </text>

                        <Button
                          style={{
                            backgroundColor: '#DA3831',
                            border: 'none',
                            fontWeight: 600,
                            color: 'white',
                            marginLeft: 20,
                            fontSize: '12px',
                          }}
                        >
                          {data.status === '3' && 'Teslim Al'}
                          {data.status === '5' && 'Porter Zimmetine Ata'}
                          {data.status === '12' && 'Porter Ata'}
                        </Button>
                      </div>
                    </>
                  )}

                {data?.usedVehicles?.length > 0 ? (
                  <>
                    <div className="divider" />
                    <div className="customRow">
                      <text
                        style={{
                          fontSize: 14,
                          fontWeight: '400',
                          color: '#8890A0',
                        }}
                      >
                        Toplam Araç Sayısı:
                      </text>
                      <text style={{ textAlign: 'right' }}>
                        {data?.usedVehicles.map((e) => (
                          <>
                            <text style={{ textAlign: 'right' }}>
                              {e.transportationTool} {'  →  '}{' '}
                            </text>
                            <text style={{ textAlign: 'right' }}>
                              {e.transportationToolCount} Sefer
                            </text>
                            <br />
                          </>
                        ))}
                      </text>
                    </div>
                    <div className="divider" />
                  </>
                ) : (
                  <>
                    <div className="divider" />
                    <div className="customRow">
                      <text
                        style={{
                          fontSize: 14,
                          fontWeight: '400',
                          color: '#8890A0',
                        }}
                      >
                        Toplam Araç Sayısı:
                      </text>
                      <text style={{ color: '#111827', fontSize: 14 }}>
                        <text style={{ textAlign: 'right' }}>
                          {data?.requestedTransportationTool}
                          {'  →  '}
                        </text>
                        <text style={{ textAlign: 'right' }}>
                          {' '}
                          {data?.requestedTransportationToolCount} Sefer{' '}
                        </text>
                      </text>
                    </div>
                    <div className="divider" />
                  </>
                )}
              </div>
            </Grid>

            <Grid item xs={8} sm={5}>
              <div
                style={{
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                  paddingBottom: '29px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '42px',
                    display: 'flex',
                    backgroundColor: '#DDDFE3',
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '15px',
                  }}
                >
                  <span
                    style={{
                      color: '#DA3831',
                      fontWeight: '400',
                      fontSize: '14px',
                      color: '#6B7280',
                    }}
                  >
                    Gönderi Logları
                  </span>
                </div>

                {data.logs?.length >= 1 ? (
                  <>
                    {data.logs.map((log, i) => (
                      <>
                        <div
                          style={{
                            backgroundColor: '#172955',
                            padding: 10,
                            width: '95%',
                            marginLeft: '2.5%',
                            textAlign: 'center',
                            marginBottom: 10,
                            fontSize: '14px',
                            color: '#fff',
                            wordWrap: 'break-word',
                          }}
                        >
                          {log}
                        </div>

                        <div className="divider" />
                      </>
                    ))}
                  </>
                ) : (
                  <p>Log bulunmamaktadır.</p>
                )}
              </div>
            </Grid>
          </Grid>

          {data.status === '14' &&
            (tokenService.getUser()?.type === 'highadmin' ||
              tokenService.getUser()?.type === 'superadmin' ||
              (tokenService.getUser()?.accountCategory === 'mall-logistics' &&
                tokenService.getUser()?.frontAuth?.includes('manager'))) && (
              <Button
                style={{
                  backgroundColor: 'green',
                  border: 'none',
                  fontWeight: 600,
                  color: 'white',
                  marginTop: 20,
                  marginRight: 10,
                }}
                onClick={handleOpenHandoverModal}
              >
                Mal Kabulden Teslim Et
              </Button>
            )}

          <Button
            style={{
              backgroundColor: 'orange',
              border: 'none',
              fontWeight: 600,
              color: 'white',
              marginTop: 20,
            }}
          >
            Destek Talebi Oluştur
          </Button>

          {data.status !== '2' &&
            tokenService.getUser()?.frontAuth?.includes('can-cancel') && (
              <Button
                style={{
                  backgroundColor: '#DA3831',
                  border: 'none',
                  fontWeight: 600,
                  color: 'white',
                  marginTop: 20,
                  marginLeft: 10,
                }}
                onClick={() => setOpenConfirmModal(true)}
              >
                İptal Et
              </Button>
            )}

          <Modal
            open={openConfirmModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onClose={() => {
              setOpenConfirmModal(false);
              setCancelReason('');
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: '8px',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <TextField
                placeholder="İptal Sebebi"
                variant="outlined"
                fullWidth
                value={cancelReason}
                onChange={(e) => setCancelReason(e.target.value)}
              />
              <Button
                onClick={cancel}
                disabled={!cancelReason || loading}
                sx={{
                  width: '100%',
                  backgroundColor: loading ? '#DDDFE3' : '#DC423C',
                  fontWeight: '400',
                  color: 'white',
                  marginTop: '21px',
                  display: 'flex',
                  '&:hover': {
                    backgroundColor: loading ? '#DDDFE3' : '#B53832',
                  },
                  '&.Mui-disabled': {
                    backgroundColor: '#DDDFE3',
                  },
                }}
                fullWidth
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: 'gray' }} />
                ) : (
                  'Onayla'
                )}
              </Button>
            </Box>
          </Modal>
          <HandoverModal
            open={openHandoverModal}
            data={data}
            onClose={handleCloseHandoverModal}
            handleCompleteDelivery={handleCompleteDelivery}
          />
        </Container>
      )}
    </>
  );
}
