
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { AppConversionRates, AppWidgetSummary } from 'src/sections/@dashboard/app';
import AppBarChart from 'src/sections/@dashboard/app/AppBarChart';
import AppLineDatas from 'src/sections/@dashboard/app/AppLineDatas';
import AppPieChart from 'src/sections/@dashboard/app/AppPieChart';
import Services from 'src/services/Services';

export default function SafeBoxReport() {
  const parsedUser = JSON.parse(localStorage.getItem('user'));
  const token = parsedUser.token;

  const [malls, setMalls] = useState([]);
  const [selectedMall, setSelectedMall] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [weekDays, setWeekDays] = useState([]);
  const [monthDays, setMonthDays] = useState([]);

  function getMalls() {
    Services.GetMalls(token).then((response) => {
      console.log(response.data);
      if (response.data.status) {
        setMalls(response.data.result)
        console.log(response.data.result);
      }
    });
  }

  const handleChange = (event) => {
    setSelectedMall(event.target.value);
  };

  useEffect(() => {
    getMalls()
  }, []);

  useEffect(() => {
    moment.locale('tr');

    const today = moment();

    const startOfCurrentWeek = moment(today).startOf('week');
    const endOfCurrentWeek = moment(today).endOf('week');

    const weekDaysArray = [];
    for (
      let i = moment(startOfCurrentWeek);
      i <= endOfCurrentWeek;
      i.add(1, 'day')
    ) {
      weekDaysArray.push(moment(i).format('DD MMMM'));
    }
    setWeekDays(weekDaysArray);

    const startOfCurrentMonth = moment(today).startOf('month');
    const endOfCurrentMonth = moment(today).endOf('month');

    const monthDaysArray = [];
    for (
      let i = moment(startOfCurrentMonth);
      i <= endOfCurrentMonth;
      i.add(1, 'day')
    ) {
      monthDaysArray.push(moment(i).format('DD MMMM'));
    }
    // console.log('monthDaysArray: ', monthDaysArray);
    setMonthDays(monthDaysArray);
  }, []);

  return (
    <>
      <Helmet>
        <title> Emanet Dolap Raporu | Mall Logistics Panel </title>
      </Helmet>
      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Emanet Dolap Raporu
          </Typography>
        </Stack>

        <Box sx={{ width: '100%', height: '104px', backgroundColor: '#fff', boxShadow: '0px 2px 6px 1px #0D0A2C14', paddingInline: '32px', paddingTop: '24px', paddingBottom: '24px', gap: '24px', display: 'flex' }}>

          <FormControl sx={{ width: '255px' }}>
            <InputLabel id="select-label">Lokasyon Seç</InputLabel>
            <Select
              labelId="select-label"
              value={selectedMall}
              onChange={handleChange}
              label="Lokasyon Seç"
            >
              {malls.map((item) => (
                <MenuItem key={item.id} value={item.title}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              format="DD/MM/YYYY"
              label="Başlangıç Tarihi"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
                setEndDate(null);
              }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              format="DD/MM/YYYY"
              label="Bitiş Tarihi"
              value={endDate}
              minDate={startDate}
              disabled={!startDate}
              onChange={(newValue) => setEndDate(newValue)}
            />
          </LocalizationProvider>
        </Box>

        <Box sx={{ flexGrow: 1, paddingTop: 2 }}>

          <Grid container spacing={2}>

            <Grid item xs={12} md={6} lg={4}>
              <AppWidgetSummary
                title="Toplam Kiralama Sayısı"
                total={9700}
                color="blue"
                icon={'octicon:graph-24'}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <AppWidgetSummary
                title="Toplam Gelir Miktarı"
                total={148560}
                color="blue"
                icon={'octicon:graph-24'}
              />
            </Grid>

            <Grid item xs={12} lg={8}>
              <AppLineDatas
                title={'Toplam İşlemler'}
                subheader={'İşlem İstatiği'}
                interval={'Haftalık'}
                seriesData={[
                  {
                    name: 'Toplam Süre',
                    data: [14, 12, 0, 5, 4, 7],
                  },
                  {
                    name: 'Toplam Fiyat',
                    data: [3, 2, 4, 7, 2, 3],
                  },
                ]}
                days={weekDays ? weekDays : []}
                height={300}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <AppConversionRates
                title="Popüler Ülkeler"
                subheader="İstatistikler"
                chartData={[
                  { label: 'ABD', value: 10 },
                  { label: 'Kanada', value: 12 },
                  { label: 'İngiltere', value: 14 },
                  { label: 'Almanya', value: 16 },
                ]}
                height={285}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <AppBarChart />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <AppPieChart seriesData={[516, 632, 1642, 718]} labelsData={['Kasım', 'Aralık', 'Ocak', 'Şubat']} colorsData={['#93AAFD', '#C6D2FD','#4A3AFF','#0666F6']} />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <AppPieChart seriesData={[2811, 12799]} labelsData={['Müsait', 'Kullanımda']} colorsData={['#C6D2FD', '#0666F6']} />
            </Grid>
          </Grid>


        </Box>
      </Stack>
    </>
  )
}
