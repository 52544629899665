// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Paper, Stack } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/iconify';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  borderRadius: '5px',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
  state: PropTypes.string,
  comparison: PropTypes.string,
};

export default function AppWidgetSummary({
  title,
  total,
  icon,
  color,
  sx,
  state,
  comparison,
  ...other
}) {
  useEffect(() => {
    // console.log('title: ', title, ': ', total);
  }, []);

  return (
    <Paper
      elevation={3}
      sx={{
        py: 3,
        // textAlign: 'center',
        // color: 'black',
        // bgcolor: 'white',
        ...sx,
      }}
      {...other}
    >
      <Stack
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingInline: '10%',
        }}
      >
        <Typography sx={{ fontSize: 18, color: '#6B7280' }}>{title}</Typography>{' '}
        <Stack
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography style={{ color: '#182230', fontSize: 34 }}>
            {total}
          </Typography>
          <StyledIcon
            sx={{
              color: 'white',
              backgroundImage: (theme) =>
                color === 'blue'
                  ? `linear-gradient(135deg, #203B80 0%, #3A5EB9 100%)`
                  : `linear-gradient(135deg, #318CA4 0%, #175F72 100%)`,
            }}
          >
            <Iconify icon={icon} width={24} height={24} />
          </StyledIcon>
        </Stack>
      </Stack>
      {/* <div style={{ width: '100%', backgroundColor: '#DFDFE1', height: 1 }} />
      <Stack
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: 10,
          paddingInline: '10%',
        }}
      >
        {state === 'increase' ? (
          <>
            <Iconify
              sx={{ color: '#2E7D32' }}
              icon={'dashicons:arrow-up'}
              width={20}
            />
            <Typography
              style={{
                color: '#2E7D32',
                fontSize: 12,
                fontWeight: '400',
                marginLeft: 10,
              }}
            >
              12%
            </Typography>
            <Typography
              style={{
                color: '#6B7280',
                fontSize: 12,
                fontWeight: '400',
                marginLeft: 5,
              }}
            >
              Geçen {comparison === 'week' ? 'Haftaya' : 'Aya'} Göre
            </Typography>
          </>
        ) : (
          <>
            <Iconify
              sx={{ color: '#D14343' }}
              icon={'dashicons:arrow-down'}
              width={20}
            />
            <Typography
              style={{
                color: '#D14343',
                fontSize: 12,
                fontWeight: '400',
                marginLeft: 10,
              }}
            >
              12%
            </Typography>
            <Typography
              style={{
                color: '#6B7280',
                fontSize: 12,
                fontWeight: '400',
                marginLeft: 5,
              }}
            >
              Geçen {comparison === 'week' ? 'Haftaya' : 'Aya'} Göre
            </Typography>
          </>
        )}
      </Stack> */}
    </Paper>
  );
}
