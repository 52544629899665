import React from 'react';

const DonutChart = ({ unavailable, available }) => {
    // Toplam değeri hesapla
    const total = unavailable + available;
    // Yüzde değerini hesapla
    const percentage = Math.round((unavailable / total) * 100);

    // SVG boyutları ve stroke genişliği
    const size = 100;
    const strokeWidth = 10;
    const radius = (size - strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;
    const progress = (percentage / 100) * circumference;

    return (
        <div style={{ width: size, height: size, position: 'relative' }}>
            <svg width={size} height={size}>
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    stroke="#F7A937"
                    fill="none"
                    strokeWidth={strokeWidth}
                />
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    stroke="#3150A0"
                    fill="none"
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${circumference} ${circumference}`}
                    strokeDashoffset={circumference - progress}
                    strokeLinecap="butt"
                    transform={`rotate(-90 ${size / 2} ${size / 2})`}
                />
            </svg>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    fontSize: '24px',
                }}
            >
                <div style={{ fontSize: '20px' }}>{percentage}%</div>
                <div style={{ fontSize: '13px' }}>Kullanımda</div>
            </div>
        </div>
    );
};

export default DonutChart;