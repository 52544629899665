import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Stack,
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Switch,
  Grid,
  InputAdornment,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import AddLocationOutlinedIcon from '@mui/icons-material/AddLocationOutlined';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Select from '@mui/material/Select';
import AddUserForStoreModal from 'src/components/modals/AddUserForStoreModal';
import AddLocationForStoreModal from 'src/components/modals/AddLocationForStoreModal';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useParams } from 'react-router-dom';
// sections
import api from 'src/services/BaseService';
import Services from 'src/services/Services';
// ----------------------------------------------------------------------

export default function EditStore() {
  const { id, mallID } = useParams();
  const [showResultMessage, setShowResultMessage] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [selectedOption, setSelectedOption] = useState('new');
  const [userList, setUserList] = useState([]);

  const [locationName, setLocationName] = useState('');
  const [editStore, setEditStore] = useState(null);

  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [addLocationModalOpen, setAddLocationModalOpen] = useState(false);
  const [expanded, setExpanded] = useState(0);
  const [selectedStoreLocations, setSelectedStoreLocations] = useState([]);

  const [formData, setFormData] = useState({
    mallID: '',
    title: '',
    companyLegalTitle: '',
    companyCorporationalCode: '',
    taxNo: '',
    taxAdministration: '',
    address: '',
    city: '',
    region: '',
    country: 'Türkiye',
    isPrimary: null,
    // isHandsfree: null,
    isGetir: null,
    mallTitle: '',
    locationAddress: '',
    locations: [],
    branchID: '',
    getirID: '',
    allowedAPIs: null
  });

  const typeList = [
    { name: 'Mağaza', value: 'store' },
    { name: 'Depo', value: 'warehouse' },
  ];

  const steps = ['Mağaza Bilgileri', 'Lokasyon Bilgileri'];

  const selectNewUser = (newUser) => {
    getUserList();
    const updatedFormData = { ...formData };
    const locationToUpdate = updatedFormData.locations[expanded];
    locationToUpdate.userID = newUser;
    updatedFormData.locations[expanded] = locationToUpdate;
    setFormData(updatedFormData);
  };


  useEffect(() => {
    getMallsAndStores();
    getUserList();
  }, []);

  const getUserList = () => {
    api
      .post('users/get-users', {
        type: 'corporational',
        mallID: mallID,
        status: '1',
      })
      .then((response) => {
        if (response.data.status) {
          setUserList(response.data.result);
          // console.log(response.data)
        } else {
          // console.log(response.data);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  function getMallsAndStores() {
    api
      .post('/malls/get-malls', {
        withOffices: true,
      })
      .then((response) => {
        if (response.data) {
          getStores(response.data.result);
        } else {
          // toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  function getStores(malls) {
    api
      .post('flow/get-malls-branches', {
        mallID: mallID,
      })
      .then((response) => {
        if (response.data) {
          const mallTitle = malls.find((item) => item.id === mallID).title;
          const editStore = response.data.result.find((item) => item.id === id);

          let checkIsGetir = false

          if (editStore.allowedAPIs) {
            if (editStore.allowedAPIs.includes("getir")) {
              checkIsGetir = true
            }
          }

          setSelectedStoreLocations(editStore.locations);
          let isPrimaryBoolean;
          if (editStore.isPrimary === '1') {
            isPrimaryBoolean = true;
          } else {
            isPrimaryBoolean = false;
          }
          setEditStore({
            mallID: editStore.mallID,
            title: editStore.title,
            companyLegalTitle: editStore.companyLegalTitle,
            companyCorporationalCode: editStore.companyCorporationalCode,
            taxNo: editStore.billing?.taxNo,
            taxAdministration: editStore.billing?.taxAdministration,
            address: editStore.billing?.address,
            city: editStore.billing?.city,
            region: editStore.billing?.region,
            country: editStore.billing?.country,
            isPrimary: isPrimaryBoolean,
            mallTitle: mallTitle,
            locationAddress: editStore.locationAddress,
            locations: editStore.locations,
            branchID: editStore.branchID,
            getirID: editStore.branchAPIIDs?.getir ? editStore.branchAPIIDs?.getir : '',
            isGetir: checkIsGetir,
            allowedAPIs: editStore.allowedAPIs,
            // isHandsfree: editStore.isHandsfree === "1" ? true : false
          });
          setFormData({
            mallID: editStore.mallID,
            title: editStore.title,
            companyLegalTitle: editStore.companyLegalTitle,
            companyCorporationalCode: editStore.companyCorporationalCode,
            taxNo: editStore.billing?.taxNo,
            taxAdministration: editStore.billing?.taxAdministration,
            address: editStore.billing?.address,
            city: editStore.billing?.city,
            region: editStore.billing?.region,
            country: editStore.billing?.country,
            isPrimary: isPrimaryBoolean,
            mallTitle: mallTitle,
            locationAddress: editStore.locationAddress,
            locations: editStore.locations,
            branchID: editStore.branchID,
            getirID: editStore.branchAPIIDs?.getir ? editStore.branchAPIIDs?.getir : '',
            isGetir: checkIsGetir,
            allowedAPIs: editStore.allowedAPIs,
            // isHandsfree: editStore.isHandsfree === "1" ? true : false
          });
        }
      })
  }

  const handleCloseAddUserModal = () => {
    setAddUserModalOpen(false);
  };

  const handleOpenAddUserModal = () => {
    setAddUserModalOpen(true);
  };

  const handleCloseAddLocationModal = () => {
    setAddLocationModalOpen(false);
  };

  const handleOpenAddLocationModal = () => {
    setAddLocationModalOpen(true);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const allFieldsAreValid = () => {
    if (activeStep === 0) {
      if (formData.title !== '' && formData.companyLegalTitle !== '' && formData.taxNo !== '') {
        if (!formData.isGetir)
          return true;
        else {
          if (formData.getirID !== '') {
            return true
          }
          else
            return false
        }
      }
      else return false;
    }
    else if (activeStep === 1) {
      if (formData.mallID !== '' && locationName !== '') {
        return true;
      } else return false;
    }
  };

  const handleSwitchChange = () => {
    const { name, value } = event.target;
    if (name === 'isPrimary') {
      setFormData((prevState) => ({
        ...prevState,
        [name]: !formData.isPrimary, // Değerin tersine çevrilmesi
      }));
    }
    // else if (name === 'isHandsfree') {
    //   setFormData((prevState) => ({
    //     ...prevState,
    //     [name]: !formData.isHandsfree, // Değerin tersine çevrilmesi
    //   }));
    // }
    else if (name === 'isGetir') {
      setFormData((prevState) => ({
        ...prevState,
        [name]: !formData.isGetir, // Değerin tersine çevrilmesi
      }));
    }
    else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleLocationNameChange = (value, index) => {
    const updatedFormData = { ...formData };
    const locationToUpdate = updatedFormData.locations[index];
    locationToUpdate.title = value;
    updatedFormData.locations[index] = locationToUpdate;
    setFormData(updatedFormData);
  };

  const handleLocationUserChange = (value, index) => {
    const updatedFormData = { ...formData };
    const locationToUpdate = updatedFormData.locations[index];
    locationToUpdate.userID = value;
    updatedFormData.locations[index] = locationToUpdate;
    setFormData(updatedFormData);
  };

  const handleLocationTypeChange = (value, index) => {
    const updatedFormData = { ...formData };
    const locationToUpdate = updatedFormData.locations[index];
    locationToUpdate.type = value;
    updatedFormData.locations[index] = locationToUpdate;
    setFormData(updatedFormData);
  };

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === 'taxNo') {
      if (/^\d*$/.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else if (name === 'companyCorporationalCode') {
      if (/^[0-9.,]*$/.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const nextStep = () => {
    if (activeStep !== 2) {
      setActiveStep(activeStep + 1);
    }
  };

  const backStep = () => {
    if (activeStep !== 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const removeLocation = (location) => {
    api
      .post('flow/location-remove', {
        locationID: location.locationID,
      })
      .then((response) => {
        if (response.data.status) {
          // console.log(response.data, "Remove Location Result")
          getMallsAndStores();
          setExpanded(0);
        } else {
          // console.log(response.data, "Remove Location Result")
        }
      })
      .catch((error) => {
        // console.log(error);
        // toast.error(error.message);
      });
  };

  const updateLocation = (location) => {
    api
      .post('/flow/location-update', {
        locationID: location.locationID,
        title: location.title,
        branchID: formData.branchID,
        address: formData.locationAddress,
        type: location.type,
        adminUserID: location.userID,
        mallID: formData.mallID,
      })
      .then((response) => {
        if (response.data.status) {
          setResultMessage('Lokasyon başarıyla düzenlendi!');
          setShowResultMessage(true);
        } else {
          setResultMessage('Lokasyon düzenlenirken bir sorunla karşılaşıldı!');
          setShowResultMessage(true);
        }
      })
      .catch((error) => {
        // console.log(error);
        // toast.error(error.message);
      });
  };

  const handleUpdateStore = () => {
    let isPrimaryBoolean;
    if (formData.isPrimary === false) {
      isPrimaryBoolean = '0';
    } else {
      isPrimaryBoolean = '1';
    }

    let allowedAPIsArr = []

    if (editStore.allowedAPIs !== null && editStore.allowedAPIs !== "") { // Daha önce allowedAPIs varsa

      allowedAPIsArr = editStore.allowedAPIs

      if (!editStore.allowedAPIs.includes('getir') && formData.isGetir) { // Getir ekleme
        allowedAPIsArr.push('getir')
      }

      else if (editStore.allowedAPIs.includes('getir') && formData.isGetir === false) { // Getir çıkarma

        if (allowedAPIsArr.length > 1) { // Tek eleman Getir değilse
          allowedAPIsArr = allowedAPIsArr.filter(item => item !== 'getir')
        }
        else { // Tek eleman getirse
          allowedAPIsArr = null
        }
      }
    }

    else if ((editStore.allowedAPIs === null || editStore.allowedAPIs == "")) { // Daha önce allowedAPIs yoksa

      if (formData.isGetir) { // Getir ekleme
        allowedAPIsArr.push('getir')
      }

      else { // Boşsa ve eklenmemişse formatlama
        allowedAPIsArr = null
      }
    }

    api
      .post('/flow/branch-update', {
        id: id,
        branchID: formData.branchID,
        title: formData.title,
        mallID: formData.mallID,
        corporationID: '',
        type: 'corporational',
        companyLegalTitle: formData.companyLegalTitle,
        billing: {
          status: true,
          type: 'corporational',
          taxNo: formData.taxNo,
          taxAdministration: formData.taxAdministration,
          address: formData.address,
          region: formData.region,
          city: formData.city,
          country: formData.country,
        },
        companyCorporationalCode: formData.companyCorporationalCode,
        isPrimary: isPrimaryBoolean,
        allowedAPIs: allowedAPIsArr,
        // isHandsfree: formData.isHandsfree
      })
   
      .then((response) => {
        if (response.data.status) {
          if (!formData.isGetir) {
            setResultMessage('Mağaza başarıyla düzenlendi!');
            setShowResultMessage(true);
            nextStep();
            setEditStore(formData);
          }
          else {
            api
              .post('/flow/set-branch-api-id', {
                branchID: formData.branchID,
                customerID: formData.getirID.trim(),
                apiUserID: "378924692387468742"
              }).then((response) => {
                if (response.data.status) {
                  setResultMessage('Mağaza başarıyla düzenlendi!');
                  setShowResultMessage(true);
                  nextStep();
                  setEditStore(formData);
                }
                else {
                  setResultMessage('Mağaza düzenlenirken bir sorunla karşılaşıldı!');
                  setShowResultMessage(true);
                }
              })
          }
        } else {
          setResultMessage('Mağaza düzenlenirken bir sorunla karşılaşıldı!');
          setShowResultMessage(true);
          // toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Helmet>
        <title> Mağaza Düzenleme | Mall Logistics Panel </title>
      </Helmet>
      <Stack
        style={{
          paddingInline: '25px',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Card
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            width: '45%',
          }}
        >
          <Stack
            width="95%"
            direction="row"
            alignItems="flex-end"
            justifyContent="flex-end"
            marginTop={3}
            mb={5}
          >
            {activeStep === 0 && (
              <div style={{ flexDirection: 'column' }}>
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginTop: 10,
                  }}
                >
                  <Typography>Öncelikli Mağaza</Typography>
                  <Switch
                    checked={formData.isPrimary}
                    onChange={handleSwitchChange}
                    name="isPrimary"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </div>
                {/* <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginTop: 10,
                  }}
                >
                  <Typography>Handsfree</Typography>
                  <Switch
                    checked={formData.isHandsfree}
                    onChange={handleSwitchChange}
                    name="isHandsfree"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </div> */}
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginTop: 10,
                  }}
                >
                  <Typography>Getir</Typography>
                  <Switch
                    checked={formData.isGetir}
                    onChange={handleSwitchChange}
                    name="isGetir"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </div>
              </div>
            )}
          </Stack>

          <Stack
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Mağaza Düzenleme
            </Typography>
            <Typography gutterBottom>
              Lütfen düzenlemek istediğiniz mağaza bilglerini güncelleyin.
            </Typography>
          </Stack>
          <Box sx={{ width: '75%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          {activeStep === 0 && (
            <DialogContent>
              <>
                <Grid xs={16} container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Mağaza Adı*"
                      name="title"
                      value={formData.title}
                      onChange={handleTextFieldChange}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Ticari Ünvan*"
                      name="companyLegalTitle"
                      value={formData.companyLegalTitle}
                      onChange={handleTextFieldChange}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Cari Kod"
                      name="companyCorporationalCode"
                      value={formData.companyCorporationalCode}
                      onChange={handleTextFieldChange}
                      margin="normal"
                    />
                  </Grid>
                </Grid>
                <Grid xs={16} container alignItems="center" spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Vergi Dairesi"
                      name="taxAdministration"
                      value={formData.taxAdministration}
                      onChange={handleTextFieldChange}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={"Vergi Numarası*"}
                      type="text"
                      name="taxNo"
                      value={formData.taxNo}
                      onChange={handleTextFieldChange}
                      margin="normal"
                    />
                  </Grid>
                </Grid>
                {formData.isGetir &&
                  <Grid item xs={12} container alignItems="center" spacing={2}>
                    <Grid xs={12} item sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                      <Grid item xs={12}>
                        <TextField
                          disabled={editStore.getirID.length > 0}
                          fullWidth
                          label="Getir ID*"
                          type="text"
                          name="getirID"
                          value={formData.getirID}
                          onChange={handleTextFieldChange}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                }

                <Grid xs={16} container alignItems="center" spacing={2}>
                  <Grid item xs={16}>
                    <div
                      style={{
                        height: '1px',
                        backgroundColor: '#EAECF0',
                        margin: '0 15px',
                        marginTop: '32px',
                      }}
                    ></div>
                  </Grid>
                </Grid>

                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={5}
                >
                  {(editStore?.title !== formData.title ||
                    editStore?.companyLegalTitle !==
                    formData.companyLegalTitle ||
                    editStore?.companyCorporationalCode !==
                    formData.companyCorporationalCode ||
                    editStore?.taxAdministration !==
                    formData.taxAdministration ||
                    editStore?.taxNo !== formData.taxNo ||
                    editStore?.isPrimary !== formData.isPrimary ||
                    editStore?.isGetir !== formData.isGetir ||
                    editStore?.getirID !== formData.getirID
                    // || editStore?.isHandsfree !== formData.isHandsfree
                  )
                    &&
                    editStore !== null ? (
                    <Button
                      color="primary"
                      onClick={handleUpdateStore}
                      variant="contained"
                      sx={{
                        marginTop: '32px',
                        width: '241px',
                        height: '50px',
                        backgroundColor: '#1570EF',
                        fontSize: '11px'
                      }}
                      disabled={!allFieldsAreValid()}
                    >
                      KAYDET VE LOKASYON DÜZENLEMEYE İLERLE
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      onClick={nextStep}
                      variant="contained"
                      sx={{
                        marginTop: '32px',
                        width: '241px',
                        height: '50px',
                        backgroundColor: '#1570EF',
                        fontSize: '11px'
                      }}
                      disabled={!allFieldsAreValid()}
                    >
                      LOKASYON DÜZENLEMEYE İLERLE
                    </Button>
                  )}
                </Stack>
              </>
            </DialogContent>
          )}
          {activeStep === 1 && (
            <DialogContent sx={{ width: '90%' }}>
              <Grid xs={12} container alignItems="flex-end">
                <Grid item xs={4}>
                  <Typography variant="h6">Mağaza Düzenleme</Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sx={{ justifyContent: 'flex-end', display: 'flex' }}
                >
                  <Button
                    color="primary"
                    onClick={handleOpenAddUserModal}
                    variant="contained"
                    sx={{
                      width: '180px',
                      height: '44px',
                      backgroundColor: '#1570EF',
                      fontSize: 12,
                      fontWeight: 500,
                      backgroundColor: '#079455',
                      color: '#fff',
                      '&:hover': { backgroundColor: '#056235' },
                      marginRight: '8px',
                    }}
                    startIcon={<PersonAddAltOutlinedIcon />}
                  >
                    KULLANICI EKLE
                  </Button>
                  <Button
                    color="primary"
                    onClick={handleOpenAddLocationModal}
                    variant="contained"
                    sx={{
                      width: '180px',
                      height: '44px',
                      backgroundColor: '#1570EF',
                      fontSize: 12,
                      fontWeight: 500,
                      backgroundColor: '#079455',
                      color: '#fff',
                      '&:hover': { backgroundColor: '#056235' },
                    }}
                    startIcon={<AddLocationOutlinedIcon />}
                  >
                    LOKASYON EKLE
                  </Button>
                </Grid>
              </Grid>

              <div
                style={{
                  height: '1px',
                  backgroundColor: '#EAECF0',
                  marginBottom: '32px',
                  marginTop: '15px',
                  width: '100%',
                }}
              ></div>
              <>
                {formData.locations?.map((location, index) => (
                  <Accordion
                    expanded={expanded === index}
                    onChange={handleAccordionChange(index)}
                    sx={{
                      borderColor: '#ccc',
                      marginBottom: '15px',
                      borderRadius: 1,
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                      width: '100%',
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{
                        backgroundColor:
                          expanded === index ? '#F3F4F6' : 'transparent',
                        borderBottomColor: '#ccc',
                        borderBottomStyle: 'solid',
                      }}
                      style={{ height: '56px', minHeight: '56px' }}
                    >
                      <Typography>
                        <b>{selectedStoreLocations[index].title} -</b>{' '}
                        {formData.mallTitle}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        borderTopColor: '#ccc',
                        borderTopWidth: '1px',
                        borderTopStyle: 'solid',
                      }}
                    >
                      <Typography
                        sx={{
                          marginTop: '18px',
                          color: '#9CA3AF',
                          fontSize: '16px',
                        }}
                      >
                        Lokasyon Düzenle
                      </Typography>
                      <Grid xs={16} container alignItems="center" spacing={2}>
                        <Grid item xs={16}>
                          <div
                            style={{
                              height: '2px',
                              backgroundColor: '#EAECF0',
                              width: '100%',
                              marginTop: '15px',
                            }}
                          ></div>
                        </Grid>
                      </Grid>
                      <Grid xs={16} container alignItems="center" spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label="Lokasyon Adı*"
                            name="locationName"
                            value={location.title}
                            onChange={(e) =>
                              handleLocationNameChange(e.target.value, index)
                            }
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel>Lokasyon Türü</InputLabel>
                            <Select
                              sx={{ width: '100%' }}
                              label="Lokasyon Türü"
                              value={location.type}
                              onChange={(e) =>
                                handleLocationTypeChange(e.target.value, index)
                              }
                              name="locationType"
                            >
                              {typeList.map((type, index) => (
                                <MenuItem key={index} value={type.value}>
                                  {type.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <TextField
                        fullWidth
                        label="Adres Bilgisi"
                        name="locationAddress"
                        value={formData.locationAddress}
                        onChange={handleTextFieldChange}
                        margin="normal"
                      />
                      <FormControl fullWidth margin="normal">
                        {userList.length === 0 ? (
                          <InputLabel>Kullanıcılar Listeleniyor...</InputLabel>
                        ) : (
                          <InputLabel>Sorumlu Kişi ya da Kullanıcı</InputLabel>
                        )}
                        <Select
                          disabled={userList.length === 0}
                          sx={{ width: '100%' }}
                          label="Sorumlu Kişi ya da Kullanıcı"
                          value={location.userID}
                          onChange={(e) =>
                            handleLocationUserChange(e.target.value, index)
                          }
                          name="selectedUser"
                        >
                          {userList?.map((user, index) => (
                            <MenuItem
                              key={index}
                              value={user.id}
                            >{`${user.name} ${user.lastname}`}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Grid xs={16} container alignItems="center" spacing={2}>
                        <Grid item xs={16}>
                          <div
                            style={{
                              height: '1px',
                              backgroundColor: '#EAECF0',
                              margin: '0 15px',
                              marginTop: '32px',
                            }}
                          ></div>
                        </Grid>
                      </Grid>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        mb={5}
                      >
                        <Button
                          disabled={formData.locations.length === 1}
                          color="primary"
                          onClick={() => removeLocation(location)}
                          variant="outlined"
                          sx={{
                            color: '#000',
                            borderColor: '#ccc',
                            '&:hover': { borderColor: '#999' },
                            marginTop: '32px',
                            width: '241px',
                            height: '46px',
                            marginRight: '25px',
                            fontSize: 12,
                            fontWeight: 500,
                          }}
                        >
                          LOKASYONU KALDIR
                        </Button>
                        <Button
                          color="primary"
                          onClick={() => updateLocation(location)}
                          variant="contained"
                          sx={{
                            marginTop: '32px',
                            width: '241px',
                            height: '46px',
                            backgroundColor: '#1570EF',
                            fontSize: 12,
                            fontWeight: 500,
                          }}
                        >
                          DEĞİŞİKLİKLERİ KAYDET
                        </Button>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                mb={5}
              >
                <Button
                  color="primary"
                  onClick={backStep}
                  variant="outlined"
                  sx={{
                    marginTop: '32px',
                    width: '241px',
                    height: '46px',
                    marginRight: '25px',
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  ÖNCEKİ ADIM
                </Button>

                <Button
                  color="primary"
                  href="/dashboard/store-list"
                  variant="contained"
                  sx={{
                    marginTop: '32px',
                    width: '241px',
                    height: '46px',
                    backgroundColor: '#1570EF',
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  DÜZENLEMEYİ TAMAMLA
                </Button>
              </Stack>
            </DialogContent>
          )}
        </Card>
      </Stack>

      {addUserModalOpen && (
        <AddUserForStoreModal
          open={addUserModalOpen}
          onClose={handleCloseAddUserModal}
          branchID={formData.branchID}
          mallID={formData.mallID}
          refreshPage={selectNewUser}
        />
      )}
      {addLocationModalOpen && (
        <AddLocationForStoreModal
          open={addLocationModalOpen}
          onClose={handleCloseAddLocationModal}
          branchID={formData.branchID}
          mallID={mallID}
          refreshPage={getMallsAndStores}
        />
      )}

      <Dialog
        open={showResultMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Mağaza Düzenleme'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {resultMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowResultMessage(false)}>Tamam</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
