import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify/Iconify';
import moment from 'moment';
// @mui
import {
    Card,
    Stack,
    Typography,
    Grid,
    Button,
    Backdrop,
    CircularProgress,
} from '@mui/material';
// components
import '../muhaberatOutput.css';
import HorizontalDivider from 'src/components/shared/HorizontalDivider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CompleteButton from 'src/components/shared/CompleteButton';
import EditButton from 'src/components/shared/EditButton';
import Services from 'src/services/Services';
import { useLocalization } from 'src/context/LocalizationContext';

const hoverColor = '#f5f5f5';

// TableRow bileşenine hover olunca değişecek stiller
const hoverStyles = {
    '&:hover': {
        backgroundColor: hoverColor,
        cursor: 'pointer',
    },
};

// ----------------------------------------------------------------------

export default function SupportTicketsOfUser({ setSeeTicketsOfUser, setSelectedTicket, createdBy, handleCloseDetails }) {

    const { language } = useLocalization();
    const [userTickets, setUserTickets] = useState();
    const [isBackdropOpen, setIsBackdropOpen] = useState(true);
    const parsedUser = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        getTicketsByUser()
    }, []);

    function getTicketsByUser() {
        Services.GetTicketsByUser(parsedUser.token, createdBy)
            .then((response) => {
                if (response.data.status) {
                    setUserTickets(response.data.result);
                    setIsBackdropOpen(false)
                } else {
                    toast.error('Destek Talebi Bulunamadı.');
                }
            })
            .catch((error) => {
                toast.error('Kullanıcının talepleri alınırken bir hata oluştu.', error);
            });
    }

    const handleOpenDetails = (ticket) => {
        setSeeTicketsOfUser(false)
        setSelectedTicket(ticket)
    }



    const getStatusColor = (status) => {
        switch (status) {
            case '1':
                return '#079455'; // Yeşil
            case '2':
                return '#FF4C51'; // Kırmızı
            case '3':
                return '#FFB400'; // Sarı
            case '4':
                return '#9155FD'; // Mor
            case '5':
                return '#FE6B8B'; // Fuşya 
            default:
                return ''; // Varsayılan durum
        }
    };


    return (
        <>
            <Helmet>
                <title> {language.support_ticket_of.titles.site_title} </title>
            </Helmet>
            {/* right cards */}
            <Grid item xs={10} sm={6} md={7}>
                <Card sx={{ height: '1000px', borderRadius: '5px', padding: '2%', alignItems: 'center', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
                    <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', justifyContent: 'space-between', width: '95%', marginBottom: '2%', marginTop: '2%' }}>
                        <Typography sx={{ fontWeight: 500, fontSize: '18px', color: '#98A2B3', fontFamily: 'Inter', alignSelf: 'flex-start' }}>
                            {language.support_ticket_of.titles.title}
                        </Typography>
                        <Card sx={{ height: '40px', width: '40px', borderRadius: '5px', alignItems: 'center', justifyContent: 'center', display: 'flex', backgroundColor: '#172955' }}>
                            <Button onClick={() => handleCloseDetails()} sx={{ height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                <Iconify sx={{ color: '#fff', height: '16px' }} icon="material-symbols:arrow-back-ios-new" />
                            </Button>
                        </Card>
                    </div>

                    {isBackdropOpen &&

                        <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CircularProgress color="inherit" />
                        </div>
                    }
                    <TableContainer sx={{ width: '95%' }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableBody>
                                {userTickets?.map((userTicket) => (
                                    <TableRow
                                        key={userTicket.id}
                                        sx={{ ...hoverStyles, '&:last-child td, &:last-child th': { border: 0 } }}
                                        onClick={() => handleOpenDetails(userTicket)}
                                    >
                                        <TableCell sx={{ flexDirection: 'row', display: 'flex', gap: '5px', alignItems: 'center' }}>
                                            <Typography sx={{ fontWeight: 600, fontSize: '16px', fontFamily: 'Inter' }}>{userTicket.title}</Typography><Typography sx={{ fontWeight: 400, color: '#3A354161', fontSize: '14px', fontFamily: 'Inter' }}>{userTicket.email}</Typography>
                                        </TableCell>
                                        <TableCell align="right" sx={{ alignItems: 'center' }}>
                                            <Typography sx={{ fontWeight: 400, fontSize: '12px', fontFamily: 'Inter', color: '#3A354161', justifyContent: 'flex-end', display: 'flex', alignItems: 'center', gap: '20px' }}> <div style={{ width: '8px', height: '8px', borderRadius: 100, backgroundColor: getStatusColor(userTicket.status) }} />{moment.unix(userTicket.createdDateTime).format("hh:mm A")}</Typography>
                                        </TableCell>
                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>

                {/* <Card sx={{ height: '365px', borderRadius: '5px', marginTop: '4%',padding:'2%' }}>
                    <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', justifyContent: 'space-between', width: '95%', marginBottom: '2%', marginTop: '2%' }}>
                        <Typography sx={{ fontWeight: 500, fontSize: '20px', color: '#3A3541DE', fontFamily: 'Inter', alignSelf: 'flex-start' }}>Son Girilen Cihazlar</Typography>
                    </div>
                </Card> */}
            </Grid>

        </>
    );


}
