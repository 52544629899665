import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Iconify from 'src/components/iconify';
import Services from 'src/services/Services';
import FilterButton from 'src/components/shared/FilterButton';
import ClearButton from 'src/components/shared/ClearButton';
import DynamicTable from 'src/components/shared/DynamicTable';
import { toast } from 'react-toastify';
import ExportToExcelButton from 'src/components/shared/ExportToExcelButton';
import api from 'src/services/BaseService';
import CustomFilter from 'src/components/filter/CustomFilter';
import FilterViewButton from 'src/components/shared/FilterViewButton';
import DonutChart from 'src/components/chart/DonutChart';
import moment from 'moment';
import { useLocalization } from 'src/context/LocalizationContext';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';

const dummyData = [
  {
    id: 60456,
    price: 315,
    lockerNumber: '16',
    startDate: '2025-02-13T01:21:00',
    endDate: '2025-02-13T06:21:00',
    locationCode: 'AA120',
    locationTitle: 'Örnek Dolap',
  },
  {
    id: 65092,
    price: 362,
    lockerNumber: '27',
    startDate: '2025-02-13T01:27:00',
    endDate: '2025-02-13T04:27:00',
    locationCode: 'AA120',
    locationTitle: 'Örnek Dolap',
  },
  {
    id: 63245,
    price: 270,
    lockerNumber: '11',
    startDate: '2025-02-13T00:35:00',
    endDate: '2025-02-13T02:35:00',
    locationCode: 'AA120',
    locationTitle: 'Örnek Dolap',
  },
  {
    id: 67896,
    price: 279,
    lockerNumber: '33',
    startDate: '2025-02-13T00:51:00',
    endDate: '2025-02-13T02:51:00',
    locationCode: 'AA120',
    locationTitle: 'Örnek Dolap',
  },
  {
    id: 64165,
    price: 183,
    lockerNumber: '14',
    startDate: '2025-02-13T00:39:00',
    endDate: '2025-02-13T05:39:00',
    locationCode: 'AA120',
    locationTitle: 'Örnek Dolap',
  },
  {
    id: 62341,
    price: 220,
    lockerNumber: '20',
    startDate: '2025-02-13T02:00:00',
    endDate: '2025-02-13T06:00:00',
    locationCode: 'AA120',
    locationTitle: 'Örnek Dolap',
  },
  {
    id: 65984,
    price: 410,
    lockerNumber: '30',
    startDate: '2025-02-13T01:15:00',
    endDate: '2025-02-13T04:15:00',
    locationCode: 'AA120',
    locationTitle: 'Örnek Dolap',
  },
  {
    id: 67021,
    price: 145,
    lockerNumber: '7',
    startDate: '2025-02-13T03:10:00',
    endDate: '2025-02-13T06:10:00',
    locationCode: 'AA120',
    locationTitle: 'Örnek Dolap',
  },
  {
    id: 61234,
    price: 330,
    lockerNumber: '18',
    startDate: '2025-02-13T00:55:00',
    endDate: '2025-02-13T03:55:00',
    locationCode: 'AA120',
    locationTitle: 'Örnek Dolap',
  },
  {
    id: 68932,
    price: 290,
    lockerNumber: '25',
    startDate: '2025-02-13T02:30:00',
    endDate: '2025-02-13T07:30:00',
    locationCode: 'AA120',
    locationTitle: 'Örnek Dolap',
  },
];

const priceRanges = {
  '1 Saat': 2,
  '2 Saat': 1,
  '3 Saat': 2,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SafeBoxOperationsFake() {
  const StyledIcon = styled('div')(({ theme }) => ({
    display: 'flex',
    borderRadius: '5px',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
  }));

  const { language } = useLocalization();
  const parsedUser = JSON.parse(localStorage.getItem('user'));
  const token = parsedUser.token;

  const customer = useSelector((state) => state.customerReducer);

  const isMobile = useMediaQuery('(max-width:768px)');
  const [filterModal, setFilterModal] = useState(false);

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [pieData, setPieData] = useState([0, 0]);
  const [totalCount, setTotalCount] = useState(0);
  const [availableCount, setAvailableCount] = useState(0);
  const [unavailableCount, setUnavailableCount] = useState(0);
  const [percantage, setPercantage] = useState(0);
  const [loading, setLoading] = useState(false);

  const [filterLocationCode, setFilterLocationCode] = useState('');
  const [filterLockerNo, setFilterLockerNo] = useState('');
  const [filterSelectedLocation, setFilterSelectedLocation] = useState('');

  const [dailyCount, setDailyCount] = useState(0);
  const [showPopUpError, setShowPopUpError] = useState(false);

  const [dropBoxList, setDropBoxList] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [boxList, setBoxList] = useState([]);
  const [uniqueLocations, setUniqueLocations] = useState([]);

  const [canSeePage, setCanSeePage] = useState(true);

  let TABLE_HEAD = [
    { id: 'locationCode', label: language.commons.labels.location_code },
    {
      id: 'locationTitle',
      label: language.commons.labels.location,
      format: (value, row) => {
        return value;
      },
      sortFunction: (value, row) => {
        return value;
      },
    },
    {
      id: 'duration',
      label: language.commons.labels.duration,
      format: (value, row) => (
        <div>{calculateHours(row.startDate, row.endDate)} Saat</div>
      ),
      sortFunction: (row) => calculateHours(row.startDate, row.endDate),
    },
    {
      id: 'startDate',
      label: language.commons.labels.start_date,
      format: (value) => formatDate(value),
    },
    {
      id: 'endDate',
      label: language.commons.labels.end_date,
      format: (value) => formatDate(value),
    },
    {
      id: 'lockerNumber',
      label: language.commons.labels.locker_no,
      sortFunction: (row) => parseInt(row.lockerNumber, 10),
    },
    {
      id: 'price',
      label: language.commons.labels.price,
      sortFunction: (row) => parseInt(row.price, 10),
      format: (value) => <div>{value} ₺</div>,
    },
  ];

  const handleCloseModal = () => {
    setFilterModal(false);
  };

  const handleModal = () => {
    setFilterModal(true);
  };

  const dateDifference = (date1, date2) => {
    return date2.diff(date1, 'days'); // 'days' parametresi gün cinsinden farkı verir
  };

  function calculateHours(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const differenceInMs = end - start;
    const differenceInHours = differenceInMs / (1000 * 60 * 60);

    return differenceInHours.toFixed(0); // İki ondalık basamak kullanarak sonucu döndürüyoruz
  }

  function formatDate(value) {
    const time =
      value.split('T')[1].split('.')[0].split(':')[0] +
      ':' +
      value.split('T')[1].split('.')[0].split(':')[1];
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Aylar 0 indexli olduğu için +1 ekliyoruz.
    const year = date.getFullYear();

    return `${day}.${month}.${year} - ${time}`;
  }

  const clearListOptions = () => {
    // setSelectedLocation('')
    setStartDate(null);
    setEndDate(null);
    setDropBoxList([]);

    setPieData([0, 0]);
    setTotalCount(0);
    setAvailableCount(0);
    setUnavailableCount(0);
    setPercantage(0);
    setDailyCount(0);
  };

  const handleChangeFilterLocation = (event) => {
    const {
      target: { value },
    } = event;
    setFilterSelectedLocation(value);
  };

  const getDropboxListByLocations = async (inFuncBoxList) => {
    setLoading(true);
    let locationCodes = [];

    if (inFuncBoxList) {
      locationCodes = inFuncBoxList;
    } else {
      locationCodes = boxList;
    }

    try {
      const results = await Promise.all(
        locationCodes.map((code) =>
          Services.GetDropboxListByLocations(
            token,
            code,
            startDate.format('YYYY-MM-DD'),
            endDate.format('YYYY-MM-DD')
          )
        )
      );

      const combinedResult = results.reduce(
        (acc, res) => {
          if (res.data.status) {
            acc.unAvailableCount += res.data.result.unAvailableCount;
            acc.availableCount += res.data.result.availableCount;
            acc.totalCount += res.data.result.totalCount;
            acc.transactions = acc.transactions.concat(
              res.data.result.transactions
            );
          } else {
            toast.error(`Veri bulunamadı: ${res.data.locationCode}.`);
          }
          return acc;
        },
        {
          unAvailableCount: 0,
          availableCount: 0,
          totalCount: 0,
          transactions: [],
        }
      );

      const filterLocations = [
        ...new Map(
          combinedResult.transactions.map((item) => [
            item.locationCode,
            {
              locationCode: item.locationCode,
              locationTitle: item.locationTitle,
            },
          ])
        ).values(),
      ];

      setUniqueLocations(filterLocations);

      const percantageInUse =
        (combinedResult.unAvailableCount / combinedResult.totalCount) * 100;

      let dayCount = dateDifference(startDate, endDate) + 1;

      setDailyCount(combinedResult.transactions.length / dayCount);
      setPieData([
        combinedResult.unAvailableCount,
        combinedResult.availableCount,
      ]);

      console.log('data: ', combinedResult.transactions);

      setDropBoxList(combinedResult.transactions);
      setOriginalData(combinedResult.transactions);
      setTotalCount(combinedResult.totalCount);
      setAvailableCount(combinedResult.availableCount);
      setUnavailableCount(combinedResult.unAvailableCount);
      setPercantage(percantageInUse.toFixed(2));
    } catch (err) {
      console.log(err);
      toast.error('Veriler getirilirken bir hata oluştu.');
    } finally {
      setLoading(false);
    }
  };

  function exportToExcel() {
    let temp1 = dummyData.map((x, index) => ({
      id: index + 1,
      locationCode: x.locationCode,
      location: x.locationTitle,
      duration: `${calculateHours(x.startDate, x.endDate)}`,
      startDate: formatDate(x.startDate),
      endDate: formatDate(x.endDate),
      lockerNumber: x.lockerNumber,
      price: `${x.price}`,
    }));

    api
      .post('excel/export', {
        type: 'excel',
        content: temp1,
        header: {
          No: 'number',
          'Lokasyon Kodu': 'string',
          Lokasyon: 'string',
          'Kullanım Süresi': 'string',
          'Başlangıç Tarihi': 'string',
          'Bitiş Tarihi': 'string',
          'Dolap No': 'string',
          Ücret: 'string',
        },
        sheetTitle: 'Dolap İşlemleri',
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data.status) {
          try {
            const newWindow = window.open(response.data.result, '_blank');
            if (
              !newWindow ||
              newWindow.closed ||
              typeof newWindow.closed == 'undefined'
            ) {
              setShowPopUpError(true);
              throw new Error(
                'Pop-up engellendi. Lütfen pop-up engelleyicisini devre dışı bırakın.'
              );
            }
          } catch (e) {
            setShowPopUpError(true);
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
        toast.error(error.message);
      });
  }

  useEffect(() => {
    // setLoading(true);
    // Services.GetMallInformation(parsedUser.token, customer?.customer?.id)
    //   .then((res) => {
    //     if (res.data.status) {
    //       if (res.data.result.canViewBoxMenu === '1') {
    //         setBoxList(res.data.result.boxList);
    //         getDropboxListByLocations(res.data.result.boxList);
    //         setCanSeePage(true);
    //       } else {
    //         setCanSeePage(false);
    //         setLoading(false);
    //       }
    //     }
    //   })
    //   .catch((err) => console.log(err));
  }, [customer]);

  const clearFilters = () => {
    setDropBoxList(originalData);
    setFilterLocationCode('');
    setFilterLockerNo('');
    setFilterSelectedLocation('');
  };

  const getFilteredDropboxList = () => {
    let filteredDropboxList;
    if (filterLocationCode || filterLockerNo || filterSelectedLocation) {
      filteredDropboxList = originalData.filter((item) => {
        const locationCodeMatch = filterLocationCode
          ? item.locationCode
              .toLowerCase()
              .includes(filterLocationCode.toLowerCase())
          : true;
        const lockerNoMatch = filterLockerNo
          ? item.lockerNumber === filterLockerNo
          : true;
        const selectedLocationMatch = filterSelectedLocation
          ? item.locationCode
              .toLowerCase()
              .includes(filterSelectedLocation.toLowerCase())
          : true;

        return locationCodeMatch && lockerNoMatch && selectedLocationMatch;
      });
    } else {
      filteredDropboxList = originalData;
    }

    console.log(filterSelectedLocation);

    setDropBoxList(filteredDropboxList);
  };

  return (
    <>
      <Helmet>
        <title> {language.safe_box_operations.titles.site_title}</title>
      </Helmet>
      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {language.safe_box_operations.titles.escrow_operations}
          </Typography>
        </Stack>

        {loading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: 2, height: '100%' }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        {canSeePage && !loading && (
          <>
            <Stack
              direction="row"
              justifyContent={isMobile ? 'space-between' : 'end'}
              mb={5}
            >
              {isMobile ? (
                <FilterViewButton variant="contained" onClick={handleModal} />
              ) : null}

              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <ExportToExcelButton
                  onClick={exportToExcel}
                  setShowPopUpError={setShowPopUpError}
                  showPopUpError={showPopUpError}
                />
              </div>
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={3}>
                <Card sx={{ height: '250px' }}>
                  <Card
                    sx={{
                      height: '250px',
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      paddingInline: '32px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: '600',
                          color: '#1F2A37',
                          fontFamily: 'Inter',
                        }}
                      >
                        Toplam Kullanım Adedi
                      </Typography>
                      <span
                        style={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#9CA3AF',
                          fontFamily: 'Inter',
                        }}
                      >
                        {language.safe_box_operations.titles.total} :{' '}
                        <span style={{ color: '#6B7280' }}>
                          {dropBoxList.length}
                        </span>
                      </span>
                      <span
                        style={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#9CA3AF',
                          fontFamily: 'Inter',
                        }}
                      >
                        {language.safe_box_operations.titles.average} :{' '}
                        <span style={{ color: '#6B7280' }}>
                          {dailyCount.toFixed(1)}
                        </span>
                      </span>
                    </div>

                    <StyledIcon
                      sx={{
                        color: 'white',
                        backgroundImage: (theme) =>
                          `linear-gradient(135deg, #203B80 0%, #3A5EB9 100%)`,
                      }}
                    >
                      <Iconify
                        icon={'charm:chart-bar'}
                        width={24}
                        height={24}
                      />
                    </StyledIcon>
                  </Card>
                </Card>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Card sx={{ height: '250px' }}>
                  <Card
                    sx={{
                      height: '250px',
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      paddingInline: '32px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: '600',
                          color: '#1F2A37',
                          fontFamily: 'Inter',
                        }}
                      >
                        {language.safe_box_operations.titles.occupancy_rate}
                      </Typography>
                      <span
                        style={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#9CA3AF',
                          fontFamily: 'Inter',
                        }}
                      >
                        {language.safe_box_operations.titles.total}:{' '}
                        <span style={{ color: '#6B7280' }}>{totalCount}</span>
                      </span>
                      <span
                        style={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#9CA3AF',
                          fontFamily: 'Inter',
                        }}
                      >
                        {language.safe_box_operations.titles.using}:{' '}
                        <span style={{ color: '#6B7280' }}>
                          {unavailableCount}
                        </span>
                      </span>
                      <span
                        style={{
                          flexDirection: 'row',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            width: '12px',
                            height: '12px',
                            borderRadius: '50px',
                            backgroundColor: '#F7A937',
                          }}
                        ></div>
                        <span
                          style={{
                            fontSize: '12px',
                            fontWeight: '500',
                            color: '#1E1B39',
                            fontFamily: 'Inter',
                            marginLeft: '6px',
                          }}
                        >
                          {language.safe_box_operations.titles.available}
                        </span>

                        <div
                          style={{
                            width: '12px',
                            height: '12px',
                            borderRadius: '50px',
                            backgroundColor: '#3150A0',
                            marginLeft: '13px',
                          }}
                        ></div>
                        <span
                          style={{
                            fontSize: '12px',
                            fontWeight: '500',
                            color: '#1E1B39',
                            fontFamily: 'Inter',
                            marginLeft: '6px',
                          }}
                        >
                          {language.safe_box_operations.titles.using}
                        </span>
                      </span>
                    </div>

                    {dropBoxList.length > 0 && (
                      <DonutChart
                        unavailable={unavailableCount}
                        available={availableCount}
                      />
                    )}
                  </Card>
                </Card>
              </Grid>

              <Grid item xs={12} md={4} lg={3}>
                <Card sx={{ height: '250px', padding: '20px' }}>
                  <Typography variant="h6">Kullanım Süresi Dağılımı</Typography>
                  <ReactApexChart
                    options={{
                      labels: Object.keys(priceRanges),
                      chart: { type: 'pie' },
                      colors: [
                        '#3150A0',
                        '#F7A937',
                        '#9acc62',
                        '#822848',
                        '#65a6a0',
                      ],
                    }}
                    series={Object.values(priceRanges)}
                    type="pie"
                    height={200}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Card sx={{ height: '250px', padding: '20px' }}>
                  <Typography variant="h6">
                    Saatlik Kullanım Yoğunluğu
                  </Typography>
                  <ReactApexChart
                    options={{
                      colors: ['#F7A937'],
                      xaxis: {
                        categories: [
                          '10:00 - 14:00',
                          '14:00 - 18:00',
                          '18:00 - 22:00',
                        ],
                      },
                      chart: {
                        type: 'bar',
                        toolbar: {
                          show: false,
                        },
                      },
                    }}
                    series={[
                      {
                        name: 'İşlem Sayısı',
                        data: [50, 20, 15],
                      },
                    ]}
                    type="bar"
                    height={200}
                  />
                </Card>
              </Grid>
            </Grid>

            <Card sx={{ padding: '25px', marginTop: '32px' }}>
              <div
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    gap: '15px',
                    alignItems: 'center',
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4} xl={4}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          label={language.commons.labels.start_date}
                          value={moment('2025-02-11')}
                          onChange={(newValue) => {
                            setStartDate(newValue);
                            setEndDate(null);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} xl={4}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          label={language.commons.labels.end_date}
                          value={moment('2025-02-11')}
                          // minDate={startDate}
                          // disabled={!startDate}
                          onChange={(newValue) => setEndDate(newValue)}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      xl={4}
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: '10px',
                      }}
                    >
                      <Button
                        sx={{
                          height: '40px',
                          fontWeight: 400,
                          fontSize: 14,
                          fontFamily: 'Inter',
                          letterSpacing: 0.4,
                        }}
                        // disabled={!startDate || !endDate}
                        disabled
                        variant="contained"
                        onClick={() => getDropboxListByLocations()}
                      >
                        Listele
                      </Button>
                      {/* <ClearButton onClick={clearListOptions} /> */}
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Card>

            <Card sx={{ marginTop: '32px' }}>
              {loading ? (
                <Backdrop
                  sx={{ color: '#fff', zIndex: 2, height: '100%' }}
                  open={loading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              ) : (
                <DynamicTable
                  data={dummyData}
                  headers={TABLE_HEAD}
                  isOrdered={false}
                  isMobile={isMobile}
                  initialOrder={'startDate'}
                  initalOrderDirection={'desc'}
                  // handleModal={dropBoxList.length > 0 ? handleModal : null}
                  // handleCloseModal={handleCloseModal}
                />
              )}
            </Card>

            <Card>
              <CustomFilter
                modalType={'drawer'}
                filterModal={filterModal}
                handleCloseModal={handleCloseModal}
              >
                <>
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingY={2}
                    paddingX={2}
                    gap={2}
                  >
                    <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                      <InputLabel id="priority-store-label">
                        {language.commons.labels.location}
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={filterSelectedLocation}
                        onChange={handleChangeFilterLocation}
                        input={<OutlinedInput label="Lokasyon" />}
                        MenuProps={MenuProps}
                      >
                        {uniqueLocations.map((location, index) => (
                          <MenuItem
                            key={location.locationTitle}
                            value={location.locationCode}
                          >
                            {location.locationTitle}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                      <TextField
                        label={language.commons.labels.location_code}
                        variant="outlined"
                        value={filterLocationCode}
                        onChange={(e) => setFilterLocationCode(e.target.value)}
                      />
                    </FormControl>

                    <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                      <TextField
                        label={language.commons.labels.locker_no}
                        variant="outlined"
                        value={filterLockerNo}
                        onChange={(e) => setFilterLockerNo(e.target.value)}
                      />
                    </FormControl>

                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                      sx={{ width: '50%' }}
                    >
                      <ClearButton
                        onClick={clearFilters}
                        sx={{ marginRight: '8px' }}
                      />
                      <FilterButton onClick={getFilteredDropboxList} />
                    </Stack>
                  </Stack>
                </>
              </CustomFilter>
            </Card>
          </>
        )}

        {!canSeePage && !loading && (
          <>
            <div
              style={{
                marginTop: '32px',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <Typography>{language.commons.noServiceAtYourMall}</Typography>
            </div>
          </>
        )}
      </Stack>
    </>
  );
}
