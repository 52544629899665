import React from 'react';
import { useEffect, useState } from 'react';
import api from 'src/services/BaseService';
import { Helmet } from 'react-helmet-async';
import {
  Card,
  Stack,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  useMediaQuery
} from '@mui/material';
import AddEditPorterModal from 'src/components/modals/AddEditPorterModal';
import { toast } from 'react-toastify';
import { tokenService } from 'src/services';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTheme } from '@mui/material/styles';
//shared
import ClearButton from 'src/components/shared/ClearButton';
import FilterButton from 'src/components/shared/FilterButton';
import AddEntityButton from 'src/components/shared/AddEntityButton';
import EditButton from 'src/components/shared/EditButton';
import HorizontalDivider from 'src/components/shared/HorizontalDivider';
import UserStatusFilters from 'src/components/shared/UserStatusFilters';

//-----------------------------------------------
import DynamicTable from 'src/components/shared/DynamicTable';
import CustomFilter from 'src/components/filter/CustomFilter';
import FilterViewButton from 'src/components/shared/FilterViewButton';
import { useLocalization } from 'src/context/LocalizationContext';

export default function Porters() {
  const { language } = useLocalization();
  const [willShowStatus, setWillShowStatus] = useState('1');
  const [porterList, setPorterList] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [mallID, setMallID] = useState('');
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [isAuth, setIsAuth] = useState(false);
  const [mallList, setMallList] = useState([]);
  const [porterUsername, setPorterUsername] = useState('');
  const [porterName, setPorterName] = useState('');
  const [porterLastname, setPorterLastname] = useState('');
  const [porterAuths, setPorterAuths] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [porterBranchList, setPorterBranchList] = useState([]);
  const [createdStartDate, setCreatedStartDate] = useState();
  const [createdEndDate, setCreatedEndDate] = useState();
  const [statusChangeStartDate, setStatusChangeStartDate] = useState();
  const [statusChangeEndDate, setStatusChangeEndDate] = useState();
  const [tableHead, setTableHead] = useState([]);
  const isMobile = useMediaQuery('(max-width:768px)');
  const [filterModal, setFilterModal] = useState(false);

  const handleModal = () => {
    setFilterModal(true);
  };

  const handleCloseModal = () => {
    setFilterModal(false);
  };

  const theme = useTheme();

  const authList = [
    { label: 'Yönetici', id: 'manager' },
    { label: 'Operasyon', id: 'operations' },
    { label: 'İptal Yetkisi', id: 'can-cancel' },
  ];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  function arrayContainsAllElements(mainArray, subArray) {
    return subArray.every(element => mainArray.includes(element));
  }

  const getFilteredPorterList = () => {
    let filteredPorterList;
    if (
      porterUsername ||
      porterName ||
      porterLastname ||
      porterAuths.length > 0 ||
      porterBranchList.length > 0 ||
      (createdStartDate && createdEndDate) ||
      (statusChangeStartDate && statusChangeEndDate)
    ) {
      filteredPorterList = originalData.filter((item) => {
        const usernameMatch = porterUsername
          ? item.username.toLowerCase().includes(porterUsername.toLowerCase())
          : true;
        const nameMatch = porterName
          ? item.name.toLowerCase().includes(porterName.toLowerCase())
          : true;
        const lastnameMatch = porterLastname
          ? item.lastname.toLowerCase().includes(porterLastname.toLowerCase())
          : true;

        const allowedPointsIDs = item.allowedPoints.map((point) => point.id);
        const hasMatchingPoints =
          porterBranchList.length > 0
            ? porterBranchList.some((id) => allowedPointsIDs.includes(id))
            : true;

        const hasMatchingAuths =
          porterAuths.length > 0
            ? arrayContainsAllElements(item.frontAuth, porterAuths)
            : true;

        const createdDateMatch =
          createdStartDate && createdEndDate
            ? moment.unix(item.createdDateTime).format('DD-MM-YYYY') >=
            moment(createdStartDate).format('DD-MM-YYYY') &&
            moment.unix(item.createdDateTime).format('DD-MM-YYYY') <=
            moment(createdEndDate).format('DD-MM-YYYY')
            : true;
        const statusChangedDateMatch =
          statusChangeStartDate && statusChangeEndDate
            ? moment.unix(item.lastStatusUpdateDateTime).format('DD-MM-YYYY') >=
            moment(statusChangeStartDate).format('DD-MM-YYYY') &&
            moment.unix(item.lastStatusUpdateDateTime).format('DD-MM-YYYY') <=
            moment(statusChangeEndDate).format('DD-MM-YYYY')
            : true;

        return (
          usernameMatch &&
          nameMatch &&
          lastnameMatch &&
          hasMatchingPoints &&
          hasMatchingAuths &&
          createdDateMatch &&
          statusChangedDateMatch
        );
      });
    } else {
      filteredPorterList = originalData;
    }

    setPorterList(filteredPorterList);
  };

  const clearFilters = () => {
    setPorterList(originalData);
    setPorterUsername('');
    setPorterName('');
    setPorterLastname('');
    setPorterAuths([]);
    setPorterBranchList([]);
    setStatusChangeStartDate(null);
    setStatusChangeEndDate(null);
    setCreatedStartDate(null);
    setCreatedEndDate(null);
  };

  const handleChangePorterAuth = (event) => {
    const {
      target: { value },
    } = event;
    setPorterAuths(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleChangePorterBranch = (event) => {
    const {
      target: { value },
    } = event;
    setPorterBranchList(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleEdit = (rowData) => {
    setEditData(rowData);
    setOpenCreateModal(true);
    setIsModalEdit(true);
  };

  const handleOpenPorterAdd = () => {
    setOpenCreateModal(true);
    setIsModalEdit(false);
  };

  const fetchData = (status) => {
    const mallID = localStorage.getItem('mallID');
    setMallID(mallID);
    api
      .post('users/get-users', {
        type: 'porter',
        mallID: mallID,
        status: status,
      })
      .then((response) => {
        if (response.data.status) {
          setPorterList(response.data.result);
          setOriginalData(response.data.result);
        } else {
          toast.error('Kullanıcı bulunamadı');
          setPorterList([]);
          setOriginalData([]);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    let showEdit = false;
    if (
      tokenService.getUser()?.frontAuth.includes('manager') &&
      (tokenService.getUser()?.accountCategory === 'mall-logistics') === true
    ) {
      setIsAuth(true);
      showEdit = true;
    }
    let TABLE_HEAD = [];

    if (showEdit) {
      if (willShowStatus === '1') {
        TABLE_HEAD = [
          {
            id: 'username',
            label: language.commons.labels.p_username,
            alignRight: false,
          },
          { id: 'name', label: language.commons.labels.name, alignRight: false },
          { id: 'lastname', label: language.commons.labels.lastname, alignRight: false },
          {
            id: 'frontAuth',
            label: language.commons.labels.frontAuth,
            alignRight: false,
            width: 10,
            format: (value, row) => {
              const frontAuthReplacements = {
                operations: 'Operasyon',
                robot: 'Robot',
                manager: 'Yönetici',
                'can-cancel': 'İptal Yetkisi'
              };
              return Array.isArray(value)
                ? value
                  .map((val) => frontAuthReplacements[val] || val)
                  .join(', ')
                : '';
            },
          },
          {
            id: 'allowedPoints',
            label: language.commons.labels.allowedPoints,
            alignRight: false,
            format: (value, row) => {
              return (
                <>
                  {value?.map((point) => (
                    <div key={point.title}>{point.title}</div>
                  ))}
                </>
              );
            },
          },
          {
            id: 'createdDateTime',
            label: language.commons.labels.createdDateTime,
            alignRight: false,
            format: (value) => moment.unix(value).format('DD/MM/YYYY HH:mm'),
          },
          {
            id: 'lastStatusUpdateDateTime',
            label: language.commons.labels.lastStatusUpdateDateTime,
            alignRight: false,
            format: (value, row) =>
              value
                ? moment.unix(value).format('DD/MM/YYYY HH:mm')
                : moment.unix(row.createdDateTime).format('DD/MM/YYYY HH:mm'),
          },
          {
            id: 'operation',
            label: '',
            alignRight: false,
            format: (value, row) => {
              return <EditButton onClick={() => handleEdit(row)} />;
            },
            sx: { width: 100 },
          },
        ];
      } else {
        TABLE_HEAD = [
          {
            id: 'username',
            label: language.commons.labels.p_username,
            alignRight: false,
          },
          { id: 'name', label:language.commons.labels.name, alignRight: false },
          { id: 'lastname', label: language.commons.labels.lastname, alignRight: false },
          {
            id: 'frontAuth',
            label: language.commons.labels.frontAuth,
            alignRight: false,
            width: 10,
            format: (value, row) => {
              const frontAuthReplacements = {
                operations: 'Operasyon',
                robot: 'Robot',
                manager: 'Yönetici',
                'can-cancel': 'İptal Yetkisi'
              };
              return Array.isArray(value)
                ? value
                  .map((val) => frontAuthReplacements[val] || val)
                  .join(', ')
                : '';
            },
          },
          {
            id: 'allowedPoints',
            label: language.commons.labels.allowedPoints,
            alignRight: false,
            format: (value, row) => {
              return (
                <>
                  {value?.map((point) => (
                    <div key={point.title}>{point.title}</div>
                  ))}
                </>
              );
            },
          },
          {
            id: 'createdDateTime',
            label: language.commons.labels.createdDateTime,
            alignRight: false,
            format: (value) => moment.unix(value).format('DD/MM/YYYY HH:mm'),
          },
          {
            id: 'lastStatusUpdateDateTime',
            label: language.commons.labels.lastStatusUpdateDateTime,
            alignRight: false,
            format: (value, row) =>
              value
                ? moment.unix(value).format('DD/MM/YYYY HH:mm')
                : moment.unix(row.createdDateTime).format('DD/MM/YYYY HH:mm'),
          },
          {
            id: 'operation',
            label: '',
            alignRight: false,
            format: (value, row) => {
              return <EditButton onClick={() => handleEdit(row)} />;
            },
            sx: { width: 100 },
          },
        ];
      }
    } else {
      if (willShowStatus === '1') {
        TABLE_HEAD = [
          {
            id: 'username',
            label: language.commons.labels.p_username,
            alignRight: false,
          },
          { id: 'name', label: language.commons.labels.name, alignRight: false },
          { id: 'lastname', label: language.commons.labels.lastname, alignRight: false },
          {
            id: 'frontAuth',
            label: language.commons.labels.frontAuth,
            alignRight: false,
            width: 10,
            format: (value, row) => {
              const frontAuthReplacements = {
                operations: 'Operasyon',
                robot: 'Robot',
                manager: 'Yönetici',
                'can-cancel': 'İptal Yetkisi'
              };
              return Array.isArray(value)
                ? value
                  .map((val) => frontAuthReplacements[val] || val)
                  .join(', ')
                : '';
            },
          },
          {
            id: 'allowedPoints',
            label: language.commons.labels.allowedPoints,
            alignRight: false,
            format: (value, row) => {
              return (
                <>
                  {value?.map((point) => (
                    <div key={point.title}>{point.title}</div>
                  ))}
                </>
              );
            },
          },
          {
            id: 'createdDateTime',
            label: language.commons.labels.createdDateTime,
            alignRight: false,
            format: (value) => moment.unix(value).format('DD/MM/YYYY HH:mm'),
          },
          {
            id: 'lastStatusUpdateDateTime',
            label: language.commons.labels.lastStatusUpdateDateTime,
            alignRight: false,
            format: (value, row) =>
              value
                ? moment.unix(value).format('DD/MM/YYYY HH:mm')
                : moment.unix(row.createdDateTime).format('DD/MM/YYYY HH:mm'),
          },
        ];
      } else {
        TABLE_HEAD = [
          {
            id: 'username',
            label:  language.commons.labels.p_username,
            alignRight: false,
          },
          { id: 'name', label:  language.commons.labels.name, alignRight: false },
          { id: 'lastname', label:  language.commons.labels.lastname, alignRight: false },
          {
            id: 'frontAuth',
            label:  language.commons.labels.frontAuth,
            alignRight: false,
            width: 10,
            format: (value, row) => {
              const frontAuthReplacements = {
                operations: 'Operasyon',
                robot: 'Robot',
                manager: 'Yönetici',
                'can-cancel': 'İptal Yetkisi'
              };
              return Array.isArray(value)
                ? value
                  .map((val) => frontAuthReplacements[val] || val)
                  .join(', ')
                : '';
            },
          },
          {
            id: 'allowedPoints',
            label:  language.commons.labels.allowedPoints,
            alignRight: false,
            format: (value, row) => {
              return (
                <>
                  {value?.map((point) => (
                    <div key={point.title}>{point.title}</div>
                  ))}
                </>
              );
            },
          },
          {
            id: 'createdDateTime',
            label: language.commons.labels.createdDateTime,
            alignRight: false,
            format: (value) => moment.unix(value).format('DD/MM/YYYY HH:mm'),
          },
          {
            id: 'lastStatusUpdateDateTime',
            label: language.commons.labels.lastStatusUpdateDateTime,
            alignRight: false,
            format: (value, row) =>
              value
                ? moment.unix(value).format('DD/MM/YYYY HH:mm')
                : moment.unix(row.createdDateTime).format('DD/MM/YYYY HH:mm'),
          },
        ];
      }
    }
    setTableHead(TABLE_HEAD);
    api
      .post('malls/get-malls', {
        withOffices: true,
      })
      .then((response) => {
        if (response.data.result) {
          fetchData('1');
          setMallList(response.data.result);

          let currentMallBranches = response.data.result.filter(
            (item) => item.id === localStorage.getItem('mallID')
          )[0].offices;
          setBranchList(currentMallBranches);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, []);

  const handleShowPassive = () => {
    fetchData('0');
    setWillShowStatus('0');
  };

  const handleShowActive = () => {
    fetchData('1');
    setWillShowStatus('1');
  };

  return (
    <>
      <Helmet>
        <title> {language.porters.titles.site_title} </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {language.porters.titles.porter_list}
          </Typography>
          {isAuth === true && (
            <AddEntityButton
              label={language.porters.titles.add_porter}
              onClick={() => handleOpenPorterAdd(true)}
            />
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent={isMobile ? 'space-between' : 'end'}
          mb={5}
        >

          {isMobile ? <FilterViewButton variant="contained" onClick={handleModal} /> : null}



        </Stack>
        <Card>
          <CustomFilter modalType={'drawer'} filterModal={filterModal} handleCloseModal={handleCloseModal}>
            <>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                paddingY={2}
                paddingX={1}
              >
                <Stack
                  width={'100%'}
                  direction="column"
                  alignItems="center"
                  justifyContent="space-between"
                  paddingY={2}
                  spacing={2}
                >
                  <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                    <TextField
                      label={language.commons.labels.p_username}
                      variant="outlined"
                      value={porterUsername}
                      onChange={(e) => setPorterUsername(e.target.value)}

                    />

                  </FormControl>
                  <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                    <TextField
                        label={language.commons.labels.name}
                      variant="outlined"
                      value={porterName}
                      onChange={(e) => setPorterName(e.target.value)}
                    />

                  </FormControl>
                  <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                    <TextField
                        label={language.commons.labels.lastname}
                      variant="outlined"
                      value={porterLastname}
                      onChange={(e) => setPorterLastname(e.target.value)}
                    />

                  </FormControl>


                  <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                    <InputLabel id="priority-store-label">{language.commons.labels.frontAuth}</InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple
                      value={porterAuths}
                      onChange={handleChangePorterAuth}
                      input={<OutlinedInput label={language.commons.labels.frontAuth} />}
                      MenuProps={MenuProps}
                    >
                      {authList.map((auth) => (
                        <MenuItem
                          key={auth.id}
                          value={auth.id}
                          style={getStyles(auth.label, porterAuths, theme)}
                        >
                          {auth.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                    <InputLabel id="priority-store-label">
                      {language.commons.labels.responsible_unit}
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple
                      value={porterBranchList}
                      onChange={handleChangePorterBranch}
                      input={<OutlinedInput label= {language.commons.labels.responsible_unit} />}
                      MenuProps={MenuProps}
                    >
                      {branchList.map((branch) => (
                        <MenuItem key={branch.id} value={branch.id}>
                          {branch.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ marginBottom: 2 }}
                >
                  <div style={{ marginTop: '10px' }}>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 500,
                        color: '#6B7280',
                      }}
                    >
                      {language.commons.labels.createdDateTime}:{' '}
                    </Typography>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          sx={{ mt: 2, marginRight: '8px' }}
                          format="DD/MM/YYYY"
                          label={language.commons.labels.start_date}
                          value={createdStartDate}
                          onChange={(newValue) => setCreatedStartDate(newValue)}
                        />
                      </LocalizationProvider>

                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          sx={{ mt: 2, marginRight: '8px' }}
                          format="DD/MM/YYYY"
                          label={language.commons.labels.end_date}
                          value={createdEndDate}
                          onChange={(newValue) => setCreatedEndDate(newValue)}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div style={{ marginTop: '10px', marginLeft: '8px' }}>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 500,
                        color: '#6B7280',
                      }}
                    >
                    {language.commons.labels.activation_deactivation}:{' '}
                    </Typography>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          sx={{ mt: 2, marginRight: '8px' }}
                          format="DD/MM/YYYY"
                          label={language.commons.labels.start_date}
                          value={statusChangeStartDate}
                          onChange={(newValue) =>
                            setStatusChangeStartDate(newValue)
                          }
                        />
                      </LocalizationProvider>

                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          sx={{ mt: 2, marginRight: '8px' }}
                          format="DD/MM/YYYY"
                          label={language.commons.labels.end_date}
                          value={statusChangeEndDate}
                          onChange={(newValue) => setStatusChangeEndDate(newValue)}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Stack>
              </Stack>
              <HorizontalDivider />

              <Stack
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                paddingY={2}
                paddingX={2}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  paddingY={2}
                  paddingX={2}
                >
                  <UserStatusFilters
                    activeOnClick={handleShowActive}
                    passiveOnClick={handleShowPassive}
                  />
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ width: '50%' }}
                >
                  <ClearButton
                    onClick={() => clearFilters()}
                    sx={{ marginRight: '8px' }}
                  />
                  <FilterButton onClick={() => getFilteredPorterList()} />
                </Stack>
              </Stack>
            </>
          </CustomFilter>


          {tableHead.length > 0 && (
            <DynamicTable
              data={porterList}
              headers={tableHead}
              initialOrder={'createdDateTime'}
              initalOrderDirection={'asc'}
              isOrdered={true}
              isMobile={isMobile}
              handleModal={handleModal}
              handleCloseModal={handleCloseModal}
              rowOnClick={(row) => handleEdit(row)}
            />
          )}
        </Card>
      </Stack>

      {openCreateModal && (
        <AddEditPorterModal
          openCreateModal={openCreateModal}
          onCreateModal={() => setOpenCreateModal(false)}
          setIsModalEdit={() => setIsModalEdit()}
          isEdit={isModalEdit}
          editData={editData}
          pageRefresh={() => fetchData(willShowStatus)}
          malls={mallList}
        />
      )}
    </>
  );
}
