import { Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography, TextField, Grid, Box, CircularProgress, Backdrop } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import DynamicTable from 'src/components/shared/DynamicTable';
import api from 'src/services/BaseService';
import moment from 'moment';
import { toast } from 'react-toastify';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useLocalization } from 'src/context/LocalizationContext';


export default function PreHandshakeList() {
    const { language } = useLocalization();
    const mallID = localStorage.getItem('mallID');
    const [handshakeList, setHandshakeList] = useState([]);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showRejectDialog, setShowRejectDialog] = useState(false);
    const [companyRepresentativeName, setCompanyRepresentativeName] = useState('');
    const [companyRepresentativeLastname, setCompanyRepresentativeLastname] = useState('');
    const [signatureDate, setSignatureDate] = useState(moment());
    const [signatureTime, setSignatureTime] = useState('');
    const [selectedHandshakeID, setSelectedHandshakeID] = useState("");
    const [reason, setReason] = useState("");
    const [showTable, setShowTable] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedHandshake, setSelectedHandshake] = useState(null);

    const [irsaliyeNo, setIrsaliyeNo] = useState("");
    const [seriNo, setSeriNo] = useState("");

    const TABLE_HEAD = [
        {
            id: 'branchTitle',
            label: language.commons.column.store_name,
            alignRight: false,
        },
        {
            id: 'documentNo',
            label: language.commons.column.doc_no,
            alignRight: false,
            sortBy: 'confirmationValue.documentNo',
            format: (value, row) => {
                return (
                    row.confirmationValue.documentNo
                )
            }
        },
        {
            id: 'porterNameLastname',
            label: language.commons.column.transport_person,
            alignRight: false,
            sortBy: 'confirmationValue.porterNameLastname',
            format: (value, row) => {
                return (
                    row.confirmationValue.porterNameLastname
                )
            }
        },
        {
            id: 'createdDateTime',
            label: language.commons.column.created_date,
            alignRight: false,
            format: (value) => moment.unix(value).format('DD/MM/YYYY HH:mm'),
        },
        {
            id: 'totalPackageCount',
            label: language.commons.column.count,
            alignRight: false,
            sortBy: 'confirmationValue.totalPackageCount',
            format: (value, row) => {
                if (row.confirmationValue.totalPackageCount !== "0") {
                    return (
                        row.confirmationValue.totalPackageCount
                    )
                }
                else {
                    return ("-")
                }
            }
        },
        {
            id: 'details',
            label: language.commons.column.service,
            alignRight: false,
            format: (value) => {
                const toolsList = value.map(item => `${item.transportationTool}`).join(', ');
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" style={{ marginRight: 8 }}>
                            {toolsList}
                        </Typography>
                    </div>
                );
            }
        },
        {
            id: 'showDetail',
            label: language.commons.column.details,
            alignRight: false,
            format: (value, row) => {
                return (
                    <Button onClick={() => setSelectedHandshake(row)} variant='contained' sx={{ color: 'white' }}>Detayları İncele</Button>
                );
            }
        },
        {
            id: 'operations',
            label: language.commons.column.transactions,
            alignRight: false,
            format: (value, row) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', gap: '5px' }}>
                        <Button onClick={() => { handleSelectHandshakeToConfirm(row) }} variant='contained' color='success' sx={{ color: 'white' }}>Onayla</Button>
                        <Button onClick={() => { setShowRejectDialog(true); setSelectedHandshakeID(row.handshakeID) }} variant='contained' color='error'>Reddet</Button>
                    </div>
                );
            }
        },
    ];

    function convertToTimestamp(dateString, timeString) {
        const formattedDate = formatDate(dateString);
        return new Date(`${formattedDate}T${timeString}`).getTime();
    }

    function formatDate(date) {
        date = new Date(date);
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    }

    const handleCloseDetailDialog = () => {
        setSelectedHandshake(null)
    }

    const handleSelectHandshakeToConfirm = (handshake) => {
        setShowConfirmDialog(true);
        setSelectedHandshakeID(handshake.handshakeID)
        console.log(handshake);
        if (handshake.confirmationValue.storeConfirmation) {
            setCompanyRepresentativeName(handshake.confirmationValue.storeConfirmation.name)
            setCompanyRepresentativeLastname(handshake.confirmationValue.storeConfirmation.lastname)

            if (handshake.confirmationValue.storeConfirmation.irsaliyeNo) {
                setIrsaliyeNo(handshake.confirmationValue.storeConfirmation.irsaliyeNo)
                setSeriNo(handshake.confirmationValue.storeConfirmation.seriNo)
            }

            const confirmationTimestamp = handshake.confirmationValue.storeConfirmation.confirmDateTime;

            const confirmationMoment = moment.unix(confirmationTimestamp);

            setSignatureDate(confirmationMoment);
            setSignatureTime(confirmationMoment.format('HH:mm'));
        }
    }

    const handleConfirm = () => {
        confirmHandshake()
        setSelectedHandshakeID("")
        setCompanyRepresentativeName("")
        setCompanyRepresentativeLastname("")
        setSignatureDate(moment())
        setSignatureTime("")
        setIrsaliyeNo("")
        setSeriNo("")
    };

    const handleCloseConfirm = () => {
        setSelectedHandshakeID("")
        setCompanyRepresentativeName("")
        setCompanyRepresentativeLastname("")
        setSignatureDate(moment())
        setSignatureTime("")
        setShowConfirmDialog(false);
        setIrsaliyeNo("")
        setSeriNo("")
    };

    const handleReject = () => {
        rejectHandshake()
        setReason("")
        setSelectedHandshakeID("")
    }

    const handleCloseReject = () => {
        setReason("")
        setShowRejectDialog(false)
    }

    const confirmHandshake = () => {
        setIsLoading(true)
        setShowTable(false)
        setShowConfirmDialog(false);
        api
            .post('handshakes/confirm-pre', {
                handshakeID: selectedHandshakeID,
                storeConfirmation: {
                    name: companyRepresentativeName.toString(),
                    lastname: companyRepresentativeLastname.toString(),
                    confirmDateTime: convertToTimestamp(signatureDate, signatureTime)
                }
            })
            .then((response) => {
                if (response.data.status) {
                    toast.success('İşlem başarıyla tamamlandı.');
                    getHandShakes()
                }
                else {
                    toast.error('İşlem yapılırken bir hata oluştu.');
                    getHandShakes()
                }
            })
            .catch((error) => {
                toast.error('İşlem yapılırken bir hata oluştu.');
                getHandShakes()
            });
    }

    const rejectHandshake = () => {
        setIsLoading(true)
        setShowTable(false)
        setShowRejectDialog(false)
        api
            .post('handshakes/cancel-pre', {
                handshakeID: selectedHandshakeID,
                reason: reason
            })
            .then((response) => {
                if (response.data.status) {
                    toast.success('İşlem başarıyla tamamlandı.');
                    getHandShakes()
                }
                else {
                    toast.error('İşlem yapılırken bir hata oluştu.');
                    getHandShakes()
                }
            })
            .catch((error) => {
                toast.error('İşlem yapılırken bir hata oluştu.');
                getHandShakes()
            });
    }

    const getHandShakes = () => {
        api
            .post('handshakes/get', {
                mallID: mallID,
                type: 'pre',
                status: "3"
            })
            .then((response) => {
                console.log(response.data);
                setShowConfirmDialog(false);
                setShowRejectDialog(false)
                if (response.data.status) {
                    setHandshakeList(response.data.result)
                    setIsLoading(false)
                }
                else {
                    setHandshakeList([])
                    setShowTable(true)
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                // console.log(error);
            });
    };

    useEffect(() => {
        getHandShakes()
    }, []);

    useEffect(() => {
        setShowTable(true)
    }, [handshakeList]);

    return (
        <>
            <Helmet>
                <title> {language.pre_handshake_list.titles.site_title} </title>
            </Helmet>

            <Stack style={{ paddingInline: '25px' }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                >
                    <Typography variant="h4" gutterBottom>
                        {language.pre_handshake_list.titles.title}
                    </Typography>
                </Stack>

                {showTable && !isLoading && <>
                    {
                        handshakeList.length > 0 ?
                            <Card>
                                <DynamicTable
                                    data={handshakeList}
                                    headers={TABLE_HEAD}
                                    initialOrder={'createdDateTime'}
                                    initalOrderDirection={'desc'}
                                    isOrdered={true}
                                />
                            </Card> :
                            <Card style={{ height: '100px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                <Typography>{language.pre_handshake_list.titles.warning}</Typography>
                            </Card>

                    }
                </>
                }


            </Stack>

            <Dialog
                open={showConfirmDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"> {language.pre_handshake_list.titles.approve}</DialogTitle>
                <DialogContent sx={{ width: '550px' }}                >
                    <DialogContentText id="alert-dialog-description">
                        {language.pre_handshake_list.titles.approve_message}
                    </DialogContentText>
                    <Box mt={2}>
                        <Typography variant="h6">{language.pre_handshake_list.titles.company_manager}</Typography>
                        <Grid container xs={12} spacing={1} mt={2}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label={language.commons.labels.name}
                                    variant="outlined"
                                    value={companyRepresentativeName}
                                    onChange={(e) => setCompanyRepresentativeName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label={language.commons.labels.lastname}
                                    variant="outlined"
                                    value={companyRepresentativeLastname}
                                    onChange={(e) => setCompanyRepresentativeLastname(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={1} mt={2}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label={language.commons.column.waybill_no}
                                    variant="outlined"
                                    value={irsaliyeNo}
                                    onChange={(e) => setIrsaliyeNo(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label={language.commons.column.cob_no}
                                    variant="outlined"
                                    value={seriNo}
                                    onChange={(e) => setSeriNo(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={1} mt={2}>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        format="DD/MM/YYYY"
                                        label={language.commons.column.signature_date}
                                        value={signatureDate}
                                        onChange={(newValue) => setSignatureDate(newValue)}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label={language.commons.column.signature_time}
                                    type="time"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    value={signatureTime}
                                    onChange={(e) => setSignatureTime(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm}>{language.commons.no}</Button>
                    <Button disabled={!signatureDate || !signatureTime} onClick={handleConfirm} color="primary">{language.commons.yes}</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showRejectDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{language.commons.decline}</DialogTitle>
                <DialogContent sx={{ width: '550px' }}                >
                    <DialogContentText id="alert-dialog-description">
                        {language.pre_handshake_list.titles.handshake_decline}
                    </DialogContentText>
                    <Box mt={2}>
                        <Typography variant="h6">{language.pre_handshake_list.titles.reason}</Typography>
                        <TextField
                            sx={{ marginTop: '15px' }}
                            fullWidth
                            label={language.commons.labels.reason}
                            variant="outlined"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseReject}>{language.commons.no}</Button>
                    <Button disabled={!reason} onClick={handleReject} color="primary">{language.commons.yes}</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={selectedHandshake}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{language.commons.detail}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {language.pre_handshake_list.titles.tool_info}
                    </DialogContentText>
                    <Box mt={2}>
                        <Grid container>
                            {selectedHandshake?.details?.map(handshake => (
                                <React.Fragment key={handshake.id}>
                                    <Grid item xs={9}>
                                        <b>{handshake.transportationTool}</b>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {handshake.transportationToolCount} {language.commons.quantity}
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </Grid>

                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDetailDialog}>{language.commons.close}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
