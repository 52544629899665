import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Iconify from 'src/components/iconify';
import Services from 'src/services/Services';
import FilterButton from 'src/components/shared/FilterButton';
import ClearButton from 'src/components/shared/ClearButton';
import DynamicTable from 'src/components/shared/DynamicTable';
import { toast } from 'react-toastify';
import ExportToExcelButton from 'src/components/shared/ExportToExcelButton';
import api from 'src/services/BaseService';
import CustomFilter from 'src/components/filter/CustomFilter';
import FilterViewButton from 'src/components/shared/FilterViewButton';
import moment from 'moment';
import { useLocalization } from 'src/context/LocalizationContext';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';

const dummyData = [
  {
    locationCode: 'AA120',
    checkRoomMemberId: 1386,
    checkRoomPriceId: 23,
    quantity: 5,
    price: 200,
    status: 'Teslim Edildi',
    notes: 'VERILDI',
    startDate: '2025-02-11T13:50:21.603',
    endDate: '2025-02-11T15:50:21.603',
    finishDate: '2025-02-11T15:07:25.383',
    verificationCode: '311823',
    checkRoomNumber: '1',
    extendPrice: null,
    id: 1330,
    insertDate: '2025-02-11T14:46:41.433',
    updateDate: '2025-02-11T15:07:25.58',
    locationTitle: 'Örnek AVM',
  },
  {
    locationCode: 'AA120',
    checkRoomMemberId: 1386,
    checkRoomPriceId: 23,
    quantity: 5,
    price: 150,
    status: 'Teslim Edildi',
    notes: 'VERILDI',
    startDate: '2025-02-11T15:22:00',
    endDate: '2025-02-11T16:22:00',
    finishDate: '2025-02-11T15:39:00',
    verificationCode: '311823',
    checkRoomNumber: '2',
    extendPrice: null,
    id: 1340,
    insertDate: '2025-02-11T15:03:00',
    updateDate: '2025-02-11T15:39:10',
    locationTitle: 'Örnek AVM',
  },
  {
    locationCode: 'AA120',
    checkRoomMemberId: 1386,
    checkRoomPriceId: 23,
    quantity: 5,
    price: 150,
    status: 'Teslim Edildi',
    notes: 'VERILDI',
    startDate: '2025-02-11T15:58:00',
    endDate: '2025-02-11T18:58:00',
    finishDate: '2025-02-11T16:11:00',
    verificationCode: '311823',
    checkRoomNumber: '3',
    extendPrice: null,
    id: 1359,
    insertDate: '2025-02-11T15:42:00',
    updateDate: '2025-02-11T16:11:01',
    locationTitle: 'Örnek AVM',
  },
  {
    locationCode: 'AA120',
    checkRoomMemberId: 1386,
    checkRoomPriceId: 23,
    quantity: 5,
    price: 500,
    status: 'Teslim Edildi',
    notes: 'VERILDI',
    startDate: '2025-02-11T12:37:00',
    endDate: '2025-02-11T15:37:00',
    finishDate: '2025-02-11T14:54:00',
    verificationCode: '311823',
    checkRoomNumber: '4',
    extendPriceId: null,
    extendPrice: null,
    id: 1491,
    insertDate: '2025-02-11T14:18:00',
    updateDate: '2025-02-11T14:54:51',
    locationTitle: 'Örnek AVM',
  },
  {
    locationCode: 'AA120',
    checkRoomMemberId: 1386,
    checkRoomPriceId: 23,
    quantity: 5,
    price: 300,
    status: 'Teslim Edildi',
    notes: 'VERILDI',
    startDate: '2025-02-11T15:13:00',
    endDate: '2025-02-11T16:13:00',
    finishDate: '2025-02-11T15:32:00',
    verificationCode: '311823',
    checkRoomNumber: '5',
    extendPriceId: null,
    extendPrice: null,
    id: 1463,
    insertDate: '2025-02-11T14:44:00',
    updateDate: '2025-02-11T15:32:41',
    locationTitle: 'Örnek AVM',
  },
];

const priceRanges = {
  '1 Saat': 2,
  '2 Saat': 1,
  '3 Saat': 2,
};

const usageByVestiyer = {
  1: 2,
  2: 3,
  3: 2,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CheckroomFake() {
  const StyledIcon = styled('div')(({ theme }) => ({
    display: 'flex',
    borderRadius: '5px',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
  }));

  const { language } = useLocalization();
  const parsedUser = JSON.parse(localStorage.getItem('user'));
  const token = parsedUser.token;

  const customer = useSelector((state) => state.customerReducer);
  const isMobile = useMediaQuery('(max-width:768px)');
  const [filterModal, setFilterModal] = useState(false);

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [loading, setLoading] = useState(false);

  const [filterLocationCode, setFilterLocationCode] = useState('');
  const [filterLockerNo, setFilterLockerNo] = useState('');
  const [filterSelectedLocation, setFilterSelectedLocation] = useState('');

  const [dailyCount, setDailyCount] = useState(0);
  const [showPopUpError, setShowPopUpError] = useState(false);

  const [dropBoxList, setDropBoxList] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [boxList, setBoxList] = useState([]);
  const [uniqueLocations, setUniqueLocations] = useState([]);

  const [canSeePage, setCanSeePage] = useState(false);

  let TABLE_HEAD = [
    { id: 'locationCode', label: language.commons.labels.location_code },
    {
      id: 'locationTitle',
      label: language.commons.labels.location,
      format: (value, row) => {
        return value;
      },
      sortFunction: (value, row) => {
        return value;
      },
    },
    {
      id: 'duration',
      label: language.commons.labels.duration,
      format: (value, row) => (
        <div>{calculateHours(row.startDate, row.endDate)} Saat</div>
      ),
      sortFunction: (row) => calculateHours(row.startDate, row.endDate),
    },
    {
      id: 'startDate',
      label: language.commons.labels.start_date,
      format: (value) => formatDate(value),
    },
    {
      id: 'endDate',
      label: language.commons.labels.end_date,
      format: (value) => formatDate(value),
    },
    {
      id: 'checkRoomNumber',
      label: language.commons.labels.checkroom_no,
      sortFunction: (row) => parseInt(row.checkRoomNumber, 10),
    },
    {
      id: 'campaign',
      label: language.commons.labels.campaign,
      format: (value, row) => {
        return '-';
      },
    },
    {
      id: 'price',
      label: language.commons.labels.price,
      sortFunction: (row) => parseInt(row.price, 10),
      format: (value, row) => {
        if (!row.extendPrice) {
          return <div>{value} ₺</div>;
        } else {
          return <div>{value + row.extendPrice} ₺</div>;
        }
      },
    },
  ];

  const handleCloseModal = () => {
    setFilterModal(false);
  };

  const handleModal = () => {
    setFilterModal(true);
  };

  const dateDifference = (date1, date2) => {
    return date2.diff(date1, 'days'); // 'days' parametresi gün cinsinden farkı verir
  };

  function calculateHours(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const differenceInMs = end - start;
    const differenceInHours = differenceInMs / (1000 * 60 * 60);

    return differenceInHours.toFixed(0); // İki ondalık basamak kullanarak sonucu döndürüyoruz
  }

  function formatDate(value) {
    if (!value || typeof value !== 'string' || !value.includes('T')) {
      console.error('Invalid date format:', value); // Format hatası durumunda uyarı
      return 'Tarih Hatalı'; // Hata durumunda null dönebiliriz ya da farklı bir değer
    }

    const time =
      value.split('T')[1].split('.')[0].split(':')[0] +
      ':' +
      value.split('T')[1].split('.')[0].split(':')[1];
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Aylar 0 indexli olduğu için +1 ekliyoruz.
    const year = date.getFullYear();

    return `${day}.${month}.${year} - ${time}`;
  }

  const clearListOptions = () => {
    // setSelectedLocation('')
    setStartDate(null);
    setEndDate(null);
    setDropBoxList([]);
    setDailyCount(0);
  };

  const handleChangeFilterLocation = (event) => {
    const {
      target: { value },
    } = event;
    setFilterSelectedLocation(value);
  };

  const getCheckRoomTransactions = async (inFuncBoxList) => {
    setLoading(true);
    let locationCodes = [];

    console.log('inFuncBoxList: ', inFuncBoxList);

    if (inFuncBoxList) {
      locationCodes = inFuncBoxList;
    } else {
      locationCodes = boxList;
    }

    try {
      const results = await Promise.all(
        locationCodes.map((code) =>
          Services.GetCheckRoomTransactions(
            token,
            code,
            startDate.format('YYYY-MM-DD'),
            endDate.format('YYYY-MM-DD')
          )
        )
      );

      const combinedResult = results.reduce((acc, res) => {
        if (res.data.status) {
          acc.transactions = acc.transactions.concat(res.data.result);
        }
        return acc;
      });

      let dayCount = dateDifference(startDate, endDate) + 1;

      if (combinedResult.data.result) {
        const filterLocations = [
          ...new Map(
            combinedResult.data.result
              .filter((x) => x.status === 'Teslim Edildi')
              .map((item) => [
                item.locationCode,
                {
                  locationCode: item.locationCode,
                  locationTitle: item.locationTitle,
                },
              ])
          ).values(),
        ];
        setUniqueLocations(filterLocations);
        console.log('filterLocations: ', filterLocations);

        setDailyCount(
          combinedResult.data.result.filter((x) => x.status === 'Teslim Edildi')
            .length / dayCount
        );
        setDropBoxList(
          combinedResult.data.result.filter((x) => x.status === 'Teslim Edildi')
        );

        console.log(
          'OriginalData: ',
          combinedResult.data.result.filter((x) => x.status === 'Teslim Edildi')
        );
        setOriginalData(
          combinedResult.data.result.filter((x) => x.status === 'Teslim Edildi')
        );
      } else {
        setDailyCount(0);
        setDropBoxList([]);
        setOriginalData([]);
      }
    } catch (err) {
      console.log(err);
      toast.error('Veriler getirilirken bir hata oluştu.');
    } finally {
      setLoading(false);
    }
  };

  function exportToExcel() {
    let temp1 = dummyData.map((x, index) => ({
      id: index + 1,
      locationCode: x.locationCode,
      location: x.locationTitle,
      duration: `${calculateHours(x.startDate, x.endDate)}`,
      startDate: formatDate(x.startDate),
      endDate: formatDate(x.endDate),
      lockerNumber: x.checkRoomNumber,
      campaign: '-',
      price: `${x.price}`,
    }));

    api
      .post('excel/export', {
        type: 'excel',
        content: temp1,
        header: {
          No: 'number',
          'Lokasyon Kodu': 'string',
          Lokasyon: 'string',
          'Kullanım Süresi': 'string',
          'Başlangıç Tarihi': 'string',
          'Bitiş Tarihi': 'string',
          'Vestiyer No': 'string',
          Kampanya: 'string',
          Ücret: 'string',
        },
        sheetTitle: 'Vestiyer İşlemleri',
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data.status) {
          try {
            const newWindow = window.open(response.data.result, '_blank');
            if (
              !newWindow ||
              newWindow.closed ||
              typeof newWindow.closed == 'undefined'
            ) {
              setShowPopUpError(true);
              throw new Error(
                'Pop-up engellendi. Lütfen pop-up engelleyicisini devre dışı bırakın.'
              );
            }
          } catch (e) {
            setShowPopUpError(true);
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
        toast.error(error.message);
      });
  }

  const clearFilters = () => {
    setDropBoxList(originalData);
    setFilterLocationCode('');
    setFilterLockerNo('');
    setFilterSelectedLocation('');
  };

  const getFilteredDropboxList = () => {
    let filteredDropboxList;
    if (filterLocationCode || filterLockerNo || filterSelectedLocation) {
      filteredDropboxList = originalData.filter((item) => {
        const locationCodeMatch = filterLocationCode
          ? item.locationCode
              .toLowerCase()
              .includes(filterLocationCode.toLowerCase())
          : true;
        const lockerNoMatch = filterLockerNo
          ? item.checkRoomNumber === filterLockerNo
          : true;
        const selectedLocationMatch = filterSelectedLocation
          ? item.locationCode
              .toLowerCase()
              .includes(filterSelectedLocation.toLowerCase())
          : true;

        return locationCodeMatch && lockerNoMatch && selectedLocationMatch;
      });
    } else {
      filteredDropboxList = originalData;
    }

    setDropBoxList(filteredDropboxList);
  };

  useEffect(() => {
    setDropBoxList(dummyData);

    let totalUsage = dummyData.length;

    setDailyCount(totalUsage / 3);
    setCanSeePage(true);

    // setLoading(true);

    // Services.GetMallInformation(parsedUser.token, customer?.customer?.id)
    //   .then((res) => {
    //     if (res.data.status) {
    //       if (res.data.result.canViewCheckroomMenu === '1') {
    //         setBoxList(res.data.result.checkroomList);
    //         getCheckRoomTransactions(res.data.result.checkroomList);
    //         setCanSeePage(true);
    //       } else {

    //         setCanSeePage(false);
    //         setLoading(false);
    //       }
    //     }
    //   })
    //   .catch((err) => console.log(err));
  }, [customer]);

  return (
    <>
      <Helmet>
        <title> {language.checkroom.titles.site_title}</title>
      </Helmet>
      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {language.checkroom.titles.escrow_operations}
          </Typography>
        </Stack>

        {loading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: 2, height: '100%' }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        {canSeePage && !loading && (
          <>
            <Stack
              direction="row"
              justifyContent={isMobile ? 'space-between' : 'end'}
              mb={5}
            >
              {/* {isMobile ? (
                <FilterViewButton variant="contained" onClick={handleModal} />
              ) : null} */}

              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <ExportToExcelButton
                  onClick={exportToExcel}
                  setShowPopUpError={setShowPopUpError}
                  showPopUpError={showPopUpError}
                />
              </div>
            </Stack>

            <Grid container spacing={2}>
              {/* Toplam Kullanım */}
              <Grid item xs={12} md={4}>
                <Card sx={{ height: '250px' }}>
                  <Card
                    sx={{
                      height: '250px',
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      paddingInline: '32px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: '600',
                          color: '#1F2A37',
                          fontFamily: 'Inter',
                        }}
                      >
                        Toplam Kullanım Adedi
                      </Typography>
                      <span
                        style={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#9CA3AF',
                          fontFamily: 'Inter',
                        }}
                      >
                        {language.checkroom.titles.total} :{' '}
                        <span style={{ color: '#6B7280' }}>
                          {dropBoxList.length}
                        </span>
                      </span>
                      <span
                        style={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#9CA3AF',
                          fontFamily: 'Inter',
                        }}
                      >
                        {language.checkroom.titles.average} :{' '}
                        <span style={{ color: '#6B7280' }}>
                          {dailyCount.toFixed(1)}
                        </span>
                      </span>
                    </div>

                    <StyledIcon
                      sx={{
                        color: 'white',
                        backgroundImage: (theme) =>
                          `linear-gradient(135deg, #203B80 0%, #3A5EB9 100%)`,
                      }}
                    >
                      <Iconify
                        icon={'charm:chart-bar'}
                        width={24}
                        height={24}
                      />
                    </StyledIcon>
                  </Card>
                </Card>
              </Grid>

              {/* Vestiyer Kullanım Dağılımı - Pie Chart */}
              <Grid item xs={12} md={4}>
                <Card sx={{ height: '250px', padding: '20px' }}>
                  <Typography variant="h6">Kullanım Süresi Dağılımı</Typography>
                  <ReactApexChart
                    options={{
                      labels: Object.keys(priceRanges),
                      chart: { type: 'pie' },
                      colors: [
                        '#3150A0',
                        '#F7A937',
                        '#9acc62',
                        '#822848',
                        '#65a6a0',
                      ],
                    }}
                    series={Object.values(priceRanges)}
                    type="pie"
                    height={200}
                  />
                </Card>
              </Grid>

              {/* Günlük Kullanım Trendi - Bar Chart */}
              <Grid item xs={12} md={4}>
                <Card sx={{ height: '250px', padding: '20px' }}>
                  <Typography variant="h6">
                    Saatlik Kullanım Yoğunluğu
                  </Typography>
                  <ReactApexChart
                    options={{
                      colors: ['#F7A937'],
                      xaxis: {
                        categories: [
                          '10:00 - 14:00',
                          '14:00 - 18:00',
                          '18:00 - 22:00',
                        ],
                      },
                      chart: {
                        type: 'bar',
                        toolbar: {
                          show: false,
                        },
                      },
                    }}
                    series={[
                      {
                        name: 'İşlem Sayısı',
                        data: [50, 20, 15],
                      },
                    ]}
                    type="bar"
                    height={200}
                  />
                </Card>
              </Grid>
            </Grid>

            <Card sx={{ padding: '25px', marginTop: '32px' }}>
              <div
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    gap: '15px',
                    alignItems: 'center',
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4} xl={4}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          label={language.commons.labels.start_date}
                          value={moment('2025-02-11')}
                          onChange={(newValue) => {
                            setStartDate(newValue);
                            setEndDate(null);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} xl={4}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          label={language.commons.labels.end_date}
                          value={moment('2025-02-11')}
                          // minDate={startDate}
                          // disabled={!startDate}
                          onChange={(newValue) => setEndDate(newValue)}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      xl={4}
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: '10px',
                      }}
                    >
                      <Button
                        sx={{
                          height: '40px',
                          fontWeight: 400,
                          fontSize: 14,
                          fontFamily: 'Inter',
                          letterSpacing: 0.4,
                        }}
                        // disabled={!startDate || !endDate}
                        disabled
                        variant="contained"
                        onClick={() => getCheckRoomTransactions()}
                      >
                        Listele
                      </Button>
                      {/* <ClearButton disabled onClick={clearListOptions} /> */}
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Card>

            <Card sx={{ marginTop: '32px' }}>
              {loading ? (
                <Backdrop
                  sx={{ color: '#fff', zIndex: 2, height: '100%' }}
                  open={loading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              ) : (
                <DynamicTable
                  data={dropBoxList}
                  headers={TABLE_HEAD}
                  isOrdered={false}
                  isMobile={isMobile}
                  initialOrder={'startDate'}
                  initalOrderDirection={'desc'}
                  // handleModal={dropBoxList.length > 0 ? handleModal : null}
                  // handleCloseModal={handleCloseModal}
                />
              )}
            </Card>

            {/* <Card>
              <CustomFilter
                modalType={'drawer'}
                filterModal={filterModal}
                handleCloseModal={handleCloseModal}
              >
                <>
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingY={2}
                    paddingX={2}
                    gap={2}
                  >
                    <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                      <InputLabel id="priority-store-label">
                        {language.commons.labels.location}
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={filterSelectedLocation}
                        onChange={handleChangeFilterLocation}
                        input={<OutlinedInput label="Lokasyon" />}
                        MenuProps={MenuProps}
                      >
                        {uniqueLocations.map((location, index) => (
                          <MenuItem
                            key={location.locationTitle}
                            value={location.locationCode}
                          >
                            {location.locationTitle}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                      <TextField
                        label={language.commons.labels.location_code}
                        variant="outlined"
                        value={filterLocationCode}
                        onChange={(e) => setFilterLocationCode(e.target.value)}
                      />
                    </FormControl>

                    <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                      <TextField
                        label={language.commons.labels.checkroom_no}
                        variant="outlined"
                        value={filterLockerNo}
                        onChange={(e) => setFilterLockerNo(e.target.value)}
                      />
                    </FormControl>

                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                      sx={{ width: '50%' }}
                    >
                      <ClearButton
                        onClick={clearFilters}
                        sx={{ marginRight: '8px' }}
                      />
                      <FilterButton onClick={getFilteredDropboxList} />
                    </Stack>
                  </Stack>
                </>
              </CustomFilter>
            </Card> */}
          </>
        )}

        {!canSeePage && !loading && (
          <>
            <div
              style={{
                marginTop: '32px',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <Typography>{language.commons.noServiceAtYourMall}</Typography>
            </div>
          </>
        )}
      </Stack>
    </>
  );
}
