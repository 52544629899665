import React, { useEffect, useState } from 'react';
import {
    Modal,
    Box,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import CompleteButton from '../shared/CompleteButton';
import CancelButton from '../shared/CancelButton';
import towers from '../../../src/towers.json';
import api from 'src/services/BaseService';
import Services from 'src/services/Services';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px'
};

export default function EditMuhaberatOutputPost({ post, onClose }) {
    const [barcode, setBarcode] = useState(post.barcode || '');
    const [customerName, setCustomerName] = useState(post.dataJSON.customerName || '');
    const [branch, setBranch] = useState('');
    const [tower, setTower] = useState(post.dataJSON.tower || '');
    const [office, setOffice] = useState(post.dataJSON.office || '');
    const [packageCount, setPackageCount] = useState(post.orderInventory || '');
    const [shipmentFirm, setShipmentFirm] = useState(post.dataJSON.shipmentFirm || '');
    const [branchOptions, setBranchOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [muhaberatID, setMuhaberatID] = useState('');
    const [shipmentFirmOptions, setShipmentFirmOptions] = useState([]);

    // const shipmentFirmOptions = [
    //     'Aras Kargo',
    //     'MNG Kargo',
    //     'PTT Kargo',
    //     'Sürat Kargo',
    //     'UPS',
    //     'Yurtiçi Kargo',
    //     'Trendyol Express',
    //     'Hepsi Jet',
    //     'Kolaygelsin',
    //     'Kargoist',
    //     'Sendeo',
    //     'DHL',
    //     'Fedex',
    //     'Birgünde Kargo',
    //     'Beymen',
    //     'Kargom Sende',
    //     'Scotty',
    //     'AGT',
    //     'Bovo',
    //     'Bolt',
    //     'Diğer',
    // ];

    const towerOptions = {
        ...towers.kule,
        ...towers.teras,
        ...towers.ofis
    };

    const officeOptions = tower && towerOptions[tower] ? towerOptions[tower] : [];

    useEffect(() => {
        const parsedUser = JSON.parse(localStorage.getItem('user'));
        let cargoFirms = []
        Services.GetCargoFirms(parsedUser.token)
          .then((res) => {
            if (res.data.status) {
              res.data.result.map(cargoFirm => cargoFirms.push(cargoFirm.title))
              const sortedCargoFirms = [
                ...cargoFirms.filter(cargoFirm => cargoFirm !== "Diğer"),
                cargoFirms.find(cargoFirm => cargoFirm === "Diğer")
              ];
    
              setShipmentFirmOptions(sortedCargoFirms);
            }
          })
          .catch((err) => console.log(err));
    
      }, [])

    const handleSubmit = () => {
        setLoading(true)

        let to = ""
        let from = ""

        if (post.direction === 'CustomerToMallLogistics') {
            from = branch.locationID
            to = muhaberatID
        }
        else {
            from = muhaberatID
            to = branch.locationID
        }

        const parsedUser = JSON.parse(localStorage.getItem('user'));

        Services.UpdateMallLogisticsPost(parsedUser.token, post.id, barcode, shipmentFirm, parseInt(packageCount, 10), tower, office, customerName.toLocaleUpperCase('tr-TR'), branch.id, branch.value, from, to)
            .then((res) => {

                if (res.data.status) {
                    toast.success("İşleminiz başarıyla tamamlandı.")
                    onClose()
                    setLoading(false)

                } else {
                    if (res.data.message === "Barcode/Delivery Code must be unique") {
                        toast.error("Girilen barkod numarası zaten mevcut.")
                    }
                    else {
                        toast.error("İşleminiz gerçekleştirilirken bir hata ile karşılaşıldı.")
                    }
                    setLoading(false)
                }
            })
            .catch((err) => {
                toast.error("İşleminiz gerçekleştirilirken bir hata ile karşılaşıldı.")
                setLoading(false)

            });
    };

    const handleBranchChange = (branch) => {
        setBranch(branch)
        setTower('')
        setOffice('')
    }

    useEffect(() => {
        const mall = localStorage.getItem('mallID');
        api
            .post('flow/get-malls-branches', {
                mallID: mall,
            })
            .then((response) => {
                if (response.data.status) {
                    const result = response.data.result;
                    const processedDataList = result?.map((item) => ({
                        value: item.title,
                        id: item.branchID,
                        corporationID: item.corporationID ? item.corporationID : '-',
                        locationID: item.locations[0].locationID,
                    }));
                    setBranchOptions(processedDataList);

                    const defaultFirm = processedDataList.filter(branch => branch.id === post.customer?.branchID)[0];
                    setBranch(defaultFirm)
                } else {
                    toast.error(
                        'Müşteri listesi çekilirken hata oluştu, bağlantınızı kontrol ediniz.'
                    );
                    setBranchOptions([]);
                }
                setLoading(false)
            })
            .catch(() => {
                toast.error(
                    'Müşteri listesi çekilirken hata oluştu, bağlantınızı kontrol ediniz.'
                );
                setLoading(false)
                setBranchOptions([]);
            });

        api
            .post('/malls/get-malls', {
                withOffices: true,
            })
            .then((res) => {
                // console.log(res.data);
                if (res.data.status) {
                    const foundMall = res.data.result.find((item) => item.id === mall);

                    if (foundMall) {
                        // console.log('foundMall: ', foundMall);
                        const foundOffice = foundMall.offices.find(
                            (item) => item.isMuhaberat === '1'
                        );
                        setMuhaberatID(foundOffice?.id);
                    }
                } else {
                    toast.error(
                        "AVM'nizin bilgileri çekilirken hata oluştu, bağlantınızı kontrol ediniz."
                    );
                    setMuhaberatID('');
                }
            })
            .catch((err) => {
                toast.error(
                    "AVM'nizin bilgileri çekilirken hata oluştu, bağlantınızı kontrol ediniz."
                );
                setMuhaberatID('');
            });
    }, []);

    return (
        <Modal
            open={true}
            onClose={onClose}
        >
            <>
                <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box sx={style}>
                    <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>{post.barcode} Barkodlu Gönderiyi Düzenle</Typography>
                    <TextField
                        label="Barkod"
                        value={barcode}
                        onChange={(e) => setBarcode(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Kişi"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="branch-title-label">Firma</InputLabel>
                        <Select
                            disabled={loading}
                            labelId="branch-title-label"
                            label="Firma"
                            value={branch}
                            onChange={(e) => handleBranchChange(e.target.value)}
                        >
                            {branchOptions.map((option) => (
                                <MenuItem key={option.id} value={option}>{option.value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {branch?.value?.toLowerCase()?.includes('residance') &&
                        <>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="tower-label">Kule/Teras/Ofis</InputLabel>
                                <Select
                                    labelId="tower-label"
                                    label="Kule/Teras/Ofis"
                                    value={tower}
                                    onChange={(e) => setTower(e.target.value)}
                                >
                                    {Object.keys(towerOptions).map((option) => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="office-label">Daire</InputLabel>
                                <Select
                                    disabled={!tower}
                                    label="Daire"
                                    labelId="office-label"
                                    value={office}
                                    onChange={(e) => setOffice(e.target.value)}
                                >
                                    {officeOptions.map((option) => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </>
                    }
                    <TextField
                        label="Paket Sayısı"
                        type="number"
                        value={packageCount}
                        onChange={(e) => setPackageCount(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="shipment-firm-label">Kargo Firması</InputLabel>
                        <Select
                            labelId="shipment-firm-label"
                            label="Kargo Firması"
                            value={shipmentFirm}
                            onChange={(e) => setShipmentFirm(e.target.value)}
                        >
                            {shipmentFirmOptions.map((option) => (
                                <MenuItem key={option} value={option}>{option}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <div style={{ width: '100%', justifyContent: 'flex-end', display: 'flex', marginTop: '15px', gap: '10px' }}>
                        <CancelButton label={'Vazgeç'} onClick={onClose} />
                        <CompleteButton label={"Kaydet"} onClick={handleSubmit} btnType={'save'} disabled={!barcode || !shipmentFirm || !packageCount || !branch || !customerName} />
                    </div>
                </Box>
            </>
        </Modal>
    );
}
