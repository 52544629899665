import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, FormControl, Stack, FormGroup, TextField, Typography, Divider, Card, Select, MenuItem, OutlinedInput, InputLabel, FormControlLabel, Switch, RadioGroup, Radio, Checkbox, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import DraggableItem from '../../components/color-utils/DraggAbleItem';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { EditorState, convertToRaw, convertFromRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../components/modals/TextEditor/TextEditor.css'
import CloseIcon from '@mui/icons-material/Close';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import './FormsStep.css';
import resim from '../../assets/images/logo-form.png';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import api from 'src/services/BaseService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

const FormsStep2 = ({ handleOpenCloseConfirmation, formStep1, getSurveys, isFormEdit, editData }) => {
  
    const formRef = useRef();
    const toolbarOptions = {
        options: ['inline', 'link', 'fontSize'],
        inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough']
        },
        fontSize: {
            options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 28, 32, 36, 48, 60, 72, 96],
        },
        link: { defaultTargetOption: "_blank" }
    };
    const [formQuestions, setFormQuestions] = useState({
        question_type: '',
        editorContent: '',
        question_options: [],
        multi_choice: false,
    });
    const [isRow, setIsRow] = useState(false);
    const [loading, setLoading] = useState(false); // Yükleme durumunu kontrol eden state
    const [newOption, setNewOption] = useState('');
    const [formContent, setFormContent] = useState([]);
    const [questionShow, setQuestionShow] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editingIndex, setEditingIndex] = useState(null);
    const [checkedChoice, setCheckedChoice] = useState(false);
    const [minValue, setMinValue] = useState('');
    const [maxValue, setMaxValue] = useState('');
    const [minContent, setMinContent] = useState('');
    const [maxContent, setMaxContent] = useState('');
    const [previewMode, setPreviewMode] = useState(false);
    const [editingQuestionIndex, setEditingQuestionIndex] = useState(null);
    const [newRating, setNewRating] = useState({});
    const handleRatingClick = (questionIndex, rating) => {
        setNewRating((prevRatings) => ({
            ...prevRatings,
            [questionIndex]: rating,
        }));
    };
    const handleChange = (event) => {
        setCheckedChoice(event.target.checked);
        if (event.target.checked) {
            setFormQuestions({
                ...formQuestions,
                multi_choice: true,
            });
        }

    };
    //console.log("formContent", formContent);

    const addFieldOption = () => {
        if (newOption.trim() === '') return;
        const options = formQuestions.question_options;
        options.push(newOption);
        setFormQuestions({
            ...formQuestions,
            question_options: options,
        });
        setNewOption('');
    };
    const handleEditOption = (index) => {
        setEditingIndex(index);
        setNewOption(formQuestions.question_options[index]);

    };

    const handleRemoveOption = (index) => {
        const updatedOptions = [...formQuestions.question_options];
        updatedOptions.splice(index, 1);
        setFormQuestions({
            ...formQuestions,
            question_options: updatedOptions,
        });
    };

    const handleUpdateOption = () => {
        if (newOption) {
            const updatedOptions = [...formQuestions.question_options];
            updatedOptions[editingIndex] = newOption;
            setFormQuestions((prev) => ({
                ...prev,
                question_options: updatedOptions,
            }));
            setEditingIndex(null);
            setNewOption('');
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormQuestions((prevQuestions) => {
            let updatedQuestions = {
                ...prevQuestions,
                [name]: value,
            };

            // Eğer question_type değiştiriliyorsa, ilgili `question_options`'u kontrol et
            if (name === 'question_type') {
                // Eğer yeni soru tipi 'multi_choice' ise, `question_options`'u basit string formatına çevir
                if (value === 'multi_choice') {
                    updatedQuestions.question_options = prevQuestions.question_options.map(option => {
                        return typeof option === 'object' ? option.minContent || 'Seçenek' : option;
                    });
                }

                // Eğer yeni soru tipi 'rating' ise, `question_options`'u nesne formatına çevir
                if (value === 'rating') {
                    updatedQuestions.question_options = prevQuestions.question_options.map(option => {
                        return typeof option === 'string'
                            ? { minValue: 1, maxValue: 5, minContent: option, maxContent: 'İçerik' }
                            : option;
                    });
                }
            }

            return updatedQuestions;
        });
    };


    const handleNewQuestion = () => {
        setQuestionShow(true);
        setEditorState('');
        setNewOption('');
        setEditingQuestionIndex(null);
        setFormQuestions({
            ...formQuestions,
            question_options: [],
        });
    }
    const handleEditorChange = (state) => {

        setEditorState(state);
        const rawContentState = convertToRaw(state.getCurrentContent());
        const htmlContent = draftToHtml(rawContentState);
        setFormQuestions({
            ...formQuestions,
            editorContent: htmlContent,
        });
    };
    const addQuestion = () => {
        const field = {
            name: `question_${formContent?.length}`,
            label: formQuestions.editorContent || "Untitled Question",
            question_type: formQuestions.question_type || "short_answer",
            question_options: formQuestions.question_options.length ? formQuestions.question_options : [],
            multi_choice: formQuestions.multi_choice || false,
        }
        setFormContent([...formContent, field]);
        setFormQuestions({ question: '', question_type: '', question_options: [], editorContent: '' });
        setNewOption('');
        setEditorState(EditorState.createEmpty());

    }
    const removeQuestion = (index) => {
        const updatedContent = formContent.filter((_, i) => i !== index);
        setFormContent(updatedContent);
    };

    const [draggedIndex, setDraggedIndex] = useState(null);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const onDragStart = (index) => {
        setDraggedIndex(index);
    };
    const onDragOver = (index) => {
        setHoveredIndex(index);
    };

    const onDrop = () => {
        const newItems = [...formContent];
        const draggedItem = newItems.splice(draggedIndex, 1)[0];
        newItems.splice(hoveredIndex, 0, draggedItem);
        setFormContent(newItems);
        setDraggedIndex(null);
        setHoveredIndex(null);
    };

    const isEditorContentValid = (editorContent) => {
        const strippedContent = editorContent?.replace(/<[^>]*>/g, '').trim();
        return strippedContent.length > 0;
    };
    //console.log("formQuestions", formQuestions.editorContent);
    const disabledSaveButton = () => {
        if (formQuestions.question_type === 'short_answer') {

            if (formQuestions.question_type && isEditorContentValid(formQuestions.editorContent)) {
                return false
            } else {
                return true
            }
        } else if (formQuestions.question_type === 'multi_choice') {
            if (formQuestions.question_type && formQuestions.editorContent && formQuestions.question_options.length > 0) {
                return false
            } else {
                return true
            }

        } else if (formQuestions.question_type === 'rating') {
            if (formQuestions.question_type && minValue && maxValue && maxContent && minContent && formQuestions.editorContent) {
                return false;

            } else {
                return true;
            }
        }
        else if (formQuestions.question_type === '') return true

    }

    // const [newAnswer, setNewAnswer] = useState('');
    // const [newRating, setNewRating] = useState(1);

    // const handleAddAnswer = () => {
    //     if (newAnswer.trim() === '') return; // Boş cevap eklemeyi engelle

    //     const anor = { content: newAnswer, rating: newRating };
    //     const options = formQuestions.question_options;
    //     options.push(anor);
    //     setFormQuestions({
    //         ...formQuestions,
    //         question_options: options,
    //     });
    //     setNewAnswer('');
    //     setNewRating(1);
    // };


    const handleAddAnswer = () => {
        // if (minValue.trim() === '' || maxValue.trim() === '') return; // Boş cevap eklemeyi engelle

        const anor = { minValue: minValue, maxValue: maxValue, maxContent: maxContent, minContent: minContent };
        const options = formQuestions.question_options;
        options.push(anor);
        setFormQuestions({
            ...formQuestions,
            question_options: options,
        });
        setMinValue('');
        setMaxValue('');
        setMaxContent('');
        setMinContent('');


    };


    const handleMaxMinChange = (event) => {
        const { name, value } = event.target;
        if (name === 'max_value') {
            setMaxValue(value);
        } else if (name === 'min_value') {
            setMinValue(value);
        }
    }
    const handleContentChange = (event) => {
        const { name, value } = event.target;
        if (name === 'min_content') {
            setMinContent(value);
        } else if (name === 'max_content') {
            setMaxContent(value);
        }
    };
    const handleSwitchChange = () => {
        setPreviewMode(!previewMode);
    }
    const stripHtmlTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    const handleEditeQuestion = (index) => {
        setEditingQuestionIndex(index);

        //editor edit
        const contentBlock = htmlToDraft(formContent[index].label);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);


        //question options rating  edit
        const ratingOptions = formContent[index].question_options;

        if (ratingOptions.length > 0) {
            setMinContent(ratingOptions[0].minContent);
            setMaxContent(ratingOptions[0].maxContent);
            setMinValue(ratingOptions[0].minValue);
            setMaxValue(ratingOptions[0].maxValue);
        }


        //question options multi choice and question tpye edit
        setFormQuestions({
            ...formQuestions,
            question_type: formContent[index].question_type,
            question_options: formContent[index].question_options,
            multi_choice: formContent[index].multi_choice
        });

        setQuestionShow(true);
        //console.log("formContent[index]", formContent[index]);
    };

    const handleUpdateQuestion = () => {
        const updatedContent = editorState.getCurrentContent();
        const updatedHTML = draftToHtml(convertToRaw(updatedContent));

        let updatedQuestion = {
            ...formContent[editingQuestionIndex],
            label: updatedHTML,
            question_type: formQuestions.question_type,
            question_options: formQuestions.question_type === 'multi_choice'
                ? formQuestions.question_options
                : formQuestions.question_type === 'rating'
                    ? [{ minValue: minValue, maxValue: maxValue, maxContent: maxContent, minContent: minContent }]
                    : []
        };

        const newFormContent = [...formContent];
        newFormContent[editingQuestionIndex] = updatedQuestion;

        console.log("newFormContent", newFormContent);
        console.log("newFormContent[editingQuestionIndex]", newFormContent[editingQuestionIndex]);

        setFormContent(newFormContent);
    };


    const handleDownloadPDF = () => {
        html2canvas(formRef.current, { scale: 2 }).then((canvas) => {
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgData = canvas.toDataURL('image/png');

            // Görüntü boyutlarını ayarlama
            const imgWidth = 210; // A4 genişliği mm cinsinden
            const pageHeight = pdf.internal.pageSize.height;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;

            let position = 0;

            // PDF'ye sayfa ekleme
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('form.pdf');
        });
    };

    const handlePrint = () => {
        const printContent = document.getElementById('printable-area');
        let title = printContent.querySelector('.form_title').innerHTML;
        const newWindow = window.open('', '_blank');

        const styles = Array.from(document.querySelectorAll('link[rel="stylesheet"], style'))
            .map(link => link.outerHTML)
            .join('');

        newWindow.document.write(`
            <html>
                <head>
                    <title>${title}</title>
                    ${styles} 
                    <style>
                        @media print {
                            body, .css-dp5fpk-MuiTypography-root {
                                font-size: 8pt;
                            }
                            #short_answer{
                                padding:3px;
                            }
                            #short_answer + fieldset{
                                width:240px;
                            }
                            #short_answer-label{
                                display:none;
                            }
                            .MuiButton-outlined{
                               min-width:25px;
                               min-height:25px;
                               width:25px;
                               height:25px;
                            }
                        
                        }
                    </style>
                </head>
                <body>
                    ${printContent.innerHTML}
                </body>
            </html>
        `);
        newWindow.document.close();
        newWindow.print();
    };

    const handleToggleLayout = () => {
        setIsRow((prev) => !prev);
    }

    function createSurvey(status = 1) {
        setLoading(true);
        api
            .post('flow/survey-create', {
                title: formStep1.surveyTitle,
                category: formStep1.surveyCategories,
                description: formStep1.surveyDesc,
                status: status,
                questions: formContent.map((item) => {
                    // Her bir formContent öğesi için uygun yapıyı oluşturuyoruz
                    const question = {
                        question: item.name,
                        label: item.label,
                        type: item.question_type,
                        canSelectOneMore: item.multi_choice,
                        options: item.question_options
                    };

                    return question;

                }),
            })
            .then((response) => {
                setLoading(false);
                console.log("response",response);
                if (response.data.status) {
                    toast.success("Anket başarıyla oluşturuldu!");
                    getSurveys([1, 2, 3]); //eklenen verinin render edilip güncellenmesi için
                } else {
                    toast.error("Anket oluşturulamadı. Lütfen tekrar deneyin.");
                }
            })
            .catch((error) => console.log(error.message));
    }
    function updateSurvey() {
        setLoading(true);
        api
            .post('flow/survey-update', {
                surveyID: editData.surveyID,
                title: formStep1.surveyTitle,
                category: formStep1.surveyCategories,
                description: formStep1.surveyDesc,
                status: 1,
                questions: formContent.map((item) => {
                    // Her bir formContent öğesi için uygun yapıyı oluşturuyorum
                    const question = {
                        question: item.name,
                        label: item.label,
                        type: item.question_type,
                        canSelectOneMore: item.multi_choice,
                        options: item.question_options
                    };

                    return question;
                }),
            })
            .then((response) => {
                setLoading(false);
                console.log("responseStep2", response);
                if (response.data.status) {
                    toast.success("Anket başarıyla Güncellendi!");
                    getSurveys(); //eklenen verinin render edilip güncellenmesi için
                } else {
                    toast.error("Anket Güncellenmedi. Lütfen tekrar deneyin.");
                }
            })
            .catch((error) => console.log(error.message));
    }
    useEffect(() => {
        if (isFormEdit) {
            setFormContent(editData.questions.map(item => ({
                name: item.question,
                label: item.label,
                question_options: item.options,
                multi_choice: item.canSelectOneMore,
                question_type: item.type
            })));
        } else {
            setFormContent([]);
        }

    }, []);
    const HandleFormPublish = () => {
        createSurvey();
    }
    const HandleFormDraft = (status) => {
        createSurvey(status);
    }
    const HandleFormUpdate = () => {
        updateSurvey();
    }
    const downloadSurveyAsJSON = () => {
        const surveyData = {
            title: formStep1.surveyTitle,
            category: formStep1.surveyCategories,
            description: formStep1.surveyDesc,
            status: 1, // Varsayılan statü
            questions: formContent.map((item) => ({
                question: item.name,
                label: item.label,
                type: item.question_type,
                canSelectOneMore: item.multi_choice,
                options: item.question_options
            })),
        };

        const dataStr = JSON.stringify(surveyData, null, 2);
        const blob = new Blob([dataStr], { type: 'application/json' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = `${formStep1.surveyTitle || 'survey'}.json`; // Başlık yoksa 'survey' kullan
        link.click();

        URL.revokeObjectURL(url); // Belleği temizleme
    };


    console.log("formCont", formContent)

    return (
        <Box>
            {/* Material UI Backdrop ve CircularProgress */}
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.modal + 1,
                    backgroundColor: 'rgba(255, 255, 255, 0.7)'
                }}
                open={loading}
            >
                <CircularProgress color="inherit" sx={{ color: 'black' }} />
            </Backdrop>
            {previewMode === false &&
                <Stack sx={{ gap: 3 }}>


                    <Stack flexDirection="row" justifyContent="end">
                        <Button
                            onClick={handleOpenCloseConfirmation}
                            sx={{ color: 'black', fontSize: '18px', position: 'absolute', top: '5px', right: 0 }}
                        ><CloseIcon />
                        </Button>
                    </Stack>
                    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Stack>
                            <Typography variant="h3" sx={{ color: 'text.primary' }}>
                                Anket Ayarları {`(${formStep1.surveyTitle})`}
                            </Typography>
                        </Stack>

                        <Stack flexDirection="row" sx={{ gap: 3 }} >
                            <Stack flexDirection="row" alignItems="center">
                                <Typography>Önizleme Modu:</Typography>
                                <Switch
                                    checked={previewMode}
                                    onChange={handleSwitchChange}
                                    name="previewMode"
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </Stack>
                            <Button onClick={() => downloadSurveyAsJSON()} variant="outlined" color="primary" sx={{ height: '58px' }}>
                                Export
                            </Button>
                            <Button onClick={() => HandleFormDraft(3)} variant="outlined" color="primary" sx={{ height: '58px' }}>
                                Taslak Olarak Kaydet
                            </Button>
                            {isFormEdit ? <Button variant="contained" color="primary" sx={{ height: '58px' }} onClick={HandleFormUpdate}>
                                Güncelle
                            </Button>
                                :
                                <Button variant="contained" color="primary" sx={{ height: '58px' }} onClick={HandleFormPublish}>
                                    Yayınla
                                </Button>
                            }
                        </Stack>
                    </Stack>
                    <Stack flexDirection="row" justifyContent="space-between">
                        <Stack flexDirection="column" flex={1} sx={{ gap: '25px' }}>
                            <Button
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    height: '56px',
                                    color: 'white',
                                    boxShadow: 'none',
                                    bgcolor: '#2065d1b5',
                                    '&:hover': {
                                        bgcolor: '#2065D1',
                                        color: 'white'
                                    }
                                }}
                            >
                                Soru Sıralaması
                            </Button>
                            <Stack sx={{ flex: 1 }}>
                                <Box sx={{ borderRadius: '4px' }}>
                                    {formContent.map((item, index) => (
                                        <DraggableItem
                                            handleEditeQuestion={handleEditeQuestion}

                                            removeQuestion={removeQuestion}
                                            key={index}
                                            item={item}
                                            index={index}
                                            onDragStart={onDragStart}
                                            onDragOver={() => onDragOver(index)}
                                            onDrop={onDrop}
                                            isDragging={draggedIndex === index}
                                            isOver={hoveredIndex === index}
                                        />
                                    ))}
                                </Box>
                            </Stack>
                        </Stack>

                        <Stack sx={{ width: '70px', justifyContent: 'center', alignItems: 'center' }}>
                            <Divider orientation="vertical" sx={{ height: '100%', borderColor: '#EFEEFC' }} />
                        </Stack>
                        <Stack flexDirection="column" flex={1} sx={{ gap: '25px' }}>
                            <Button
                                onClick={() => handleNewQuestion()}
                                startIcon={<AddIcon />}
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    height: '56px',
                                    boxShadow: 'none',
                                    color: 'white',
                                    bgcolor: '#2065d1b5',
                                    '&:hover': {
                                        bgcolor: '#2065D1',
                                        color: 'white'
                                    }
                                }}
                            >
                                Yeni Soru Ekle
                            </Button>
                            {questionShow &&
                                <Stack sx={{ flex: 1 }} >
                                    <Box>
                                        <Stack sx={{ gap: '25px' }}>
                                            <Typography variant="h6" sx={{ color: 'text.primary' }}>
                                                Soru Metni

                                            </Typography>
                                            <FormControl sx={{ flex: 1 }}>
                                                <div style={{ border: '1px solid rgba(145, 158, 171, 0.24)', padding: '15px', borderRadius: '8px' }} className="text-editor-container-form">
                                                    <Editor
                                                        editorState={editorState}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName=""
                                                        onEditorStateChange={handleEditorChange}
                                                        toolbar={toolbarOptions}

                                                    />
                                                </div >
                                            </FormControl>
                                            <Typography variant="h6" sx={{ color: 'text.primary', display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                Soru Tipi
                                                <InfoOutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                                            </Typography>
                                            <FormControl sx={{ flex: 1 }}>
                                                <InputLabel id="priority-store-label">
                                                    Soru Tipleri
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-multiple-name-label"
                                                    id="demo-multiple-name"
                                                    input={<OutlinedInput label="Talep Durumu" />}
                                                    onChange={handleInputChange}
                                                    name="question_type"
                                                    value={formQuestions.question_type}
                                                >
                                                    <MenuItem value='short_answer'>Açık Uçlu Soru</MenuItem>
                                                    <MenuItem value='rating'>Derecelendirme</MenuItem>
                                                    <MenuItem value='multi_choice'>Çoktan Seçmeli</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <Divider></Divider>
                                            {formQuestions.question_type === 'short_answer' &&
                                                <Stack sx={{ gap: '15px' }}>

                                                </Stack>
                                            }

                                            {formQuestions.question_type === 'multi_choice' &&
                                                <Stack sx={{ gap: 1 }}>
                                                    <Typography variant="h6" gutterBottom>
                                                        Çoklu Cevaplı Sorular
                                                    </Typography>
                                                    <Stack
                                                        flexDirection="row"
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                        sx={{ padding: '12px', border: '1px solid #e0e0e0', borderRadius: '8px', backgroundColor: '#f9f9f9' }}
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={checkedChoice}
                                                                    onChange={handleChange}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={checkedChoice ? 'Evet' : 'Hayır'}
                                                            sx={{ marginRight: '16px' }}
                                                        />
                                                        <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
                                                            Birden fazla seçenek seçebilir mi
                                                            <InfoOutlinedIcon sx={{ fontSize: '20px', color: 'gray', marginLeft: '8px' }} />
                                                        </Typography>
                                                    </Stack>

                                                    <Button onClick={() => addFieldOption()}>Seçenek Ekle  <AddIcon sx={{ fontSize: '20px', color: '#da3831', textAlign: 'left' }} /></Button>

                                                    <FormControl fullWidth>
                                                        <TextField
                                                            onChange={(e) => setNewOption(e.target.value)}
                                                            id="add_option"
                                                            name="add_option"
                                                            type="text"
                                                            label={`${formQuestions.question_options.length + 1}. Seçenek Ekle`}
                                                            fullWidth
                                                            value={newOption}
                                                        />

                                                    </FormControl>
                                                    {formQuestions.question_options.map((option, index) => (
                                                        <Stack key={index} direction="row" alignItems="center" spacing={1}>
                                                            {editingIndex === index ? (
                                                                <>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        value={newOption}
                                                                        onChange={(e) => setNewOption(e.target.value)}
                                                                    />
                                                                    <Button onClick={handleUpdateOption}>Save</Button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Typography variant="body1">{option}</Typography>
                                                                    <Button onClick={() => handleEditOption(index)}>Edit</Button>
                                                                    <Button onClick={() => handleRemoveOption(index)}>Remove</Button>
                                                                </>
                                                            )}
                                                        </Stack>
                                                    ))}
                                                </Stack>
                                            }
                                            {formQuestions.question_type === 'rating' && (
                                                <Stack sx={{ gap: 2 }}>
                                                    <Typography variant="h6" gutterBottom>
                                                        Derecelendirme Soruları
                                                    </Typography>
                                                    {/* <Stack direction="row" spacing={1}>
                                                    {[1, 2, 3, 4, 5].map((rating) => (
                                                        <Button
                                                            key={rating}
                                                            variant="outlined"
                                                            onClick={() => setNewRating(rating)}
                                                            sx={{
                                                                bgcolor: newRating === rating ? '#da3831' : 'transparent',
                                                                color: newRating === rating ? 'white' : '#000',
                                                            }}
                                                        >
                                                            {rating}
                                                        </Button>
                                                    ))}
                                                </Stack> 

                                                <TextField
                                                    label="Cevap İçeriği"
                                                    value={newAnswer}
                                                    onChange={(e) => setNewAnswer(e.target.value)}
                                                />
                                                  <Typography variant="h6" gutterBottom>
                                                    Eklenen Cevaplar
                                                </Typography>
                                                {formQuestions.question_options.length > 0 ? (
                                                    formQuestions.question_options.map((answer, index) => (
                                                        <Stack key={index} direction="row" alignItems="center" spacing={1}>
                                                            {editingIndex === index ? (
                                                                <Stack key={index} spacing={1}>
                                                                    <Typography variant="body1">
                                                                        {`Cevap: ${answer.content}, Puan: ${answer.rating}`}
                                                                    </Typography>
                                                                </Stack>
                                                            ) :
                                                                <>
                                                                    <Typography variant="body1">
                                                                        {`Cevap: ${answer.content}, Puan: ${answer.rating}`}
                                                                    </Typography>
                                                                    <Button onClick={() => handleRemoveOption(index)}>Remove</Button>
                                                                </>

                                                            }



                                                        </Stack>

                                                    ))
                                                ) : (
                                                    <Typography variant="body1" color="text.secondary">
                                                        Henüz cevap eklenmedi.
                                                    </Typography>
                                                )}
                                                       <Button variant="contained" onClick={handleAddAnswer}>
                                                    Cevap Ekle
                                                </Button>

                                                */}
                                                    <Stack flexDirection="row" sx={{ gap: '15px' }}>
                                                        <FormControl sx={{ flex: 1 }}>
                                                            <InputLabel id="min-select-label">Minimum Değer</InputLabel>
                                                            <Select
                                                                labelId="min-value-select"
                                                                id="min-value-select"
                                                                label="Select a Number"
                                                                name="min_value"
                                                                onChange={handleMaxMinChange}
                                                                value={minValue}
                                                            >
                                                                {[...Array(11).keys()].map((num) => (
                                                                    <MenuItem key={num} value={num}>
                                                                        {num}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>

                                                        <FormControl sx={{ flex: 1 }}>
                                                            <InputLabel id="max-select-label">Maximum Değer</InputLabel>
                                                            <Select
                                                                labelId="max-value-select"
                                                                id="max-value-select"
                                                                label="Select a Number"
                                                                name="max_value"
                                                                onChange={handleMaxMinChange}
                                                                value={maxValue}
                                                            >
                                                                {[...Array(11).keys()].map((num) => (
                                                                    <MenuItem key={num} value={num}>
                                                                        {num}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Stack>

                                                    <Stack flexDirection="column" sx={{ gap: '15px', marginTop: '15px' }}>
                                                        <FormControl sx={{ flex: 1 }}>
                                                            <TextField
                                                                variant="outlined"
                                                                placeholder="Minumum Değer"
                                                                name="min_content"
                                                                value={minContent}
                                                                onChange={handleContentChange}
                                                            />
                                                        </FormControl>

                                                        <FormControl sx={{ flex: 1 }}>
                                                            <TextField
                                                                variant="outlined"
                                                                placeholder="Maximum Değer"
                                                                name="max_content"
                                                                value={maxContent}
                                                                onChange={handleContentChange}
                                                            />
                                                        </FormControl>

                                                    </Stack>

                                                </Stack>
                                            )}
                                            {editingQuestionIndex !== null ?

                                                <Button
                                                    disabled={disabledSaveButton()}
                                                    onClick={() => { handleUpdateQuestion(); setQuestionShow(false); }}
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ height: '58px', width: '250px' }}
                                                >
                                                    Düzenle
                                                </Button>
                                                :
                                                (maxContent ?
                                                    <Button
                                                        className='maxcon'
                                                        disabled={disabledSaveButton()}
                                                        onClick={() => { handleAddAnswer(); addQuestion(); setQuestionShow(false); }}
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{ height: '58px', width: '250px' }}
                                                    >
                                                        Düzenle / Kaydet
                                                    </Button>
                                                    :
                                                    <Button
                                                        className='no-maxcon'
                                                        disabled={disabledSaveButton()}
                                                        onClick={() => { addQuestion(); setQuestionShow(false); }}
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{ height: '58px', width: '250px' }}
                                                    >
                                                        Düzenle / Kaydet
                                                    </Button>
                                                )
                                            }


                                        </Stack>
                                    </Box>
                                </Stack>}

                        </Stack>

                    </Stack>

                </Stack >
            }

            {previewMode === true &&
                <Stack>
                    <Stack alignItems='center' flexDirection="row" justifyContent="end">

                        <IconButton onClick={handleToggleLayout}>
                            {isRow ? <ViewWeekIcon /> : <ViewStreamIcon />}

                        </IconButton>

                        <IconButton onClick={handlePrint}>
                            <LocalPrintshopOutlinedIcon />
                        </IconButton>

                        {/* <IconButton onClick={handleDownloadPDF}>
                            <FileDownloadOutlinedIcon />
                        </IconButton> */}

                        <Typography>Önizleme Modu:</Typography>
                        <Switch
                            checked={previewMode}
                            onChange={handleSwitchChange}
                            name="previewMode"
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />

                    </Stack>
                    <Box ref={formRef} id="printable-area">
                        <Stack alignItems="center" justifyContent="space-between" flexDirection="row" sx={{ boxShadow: '2px 1px 4px 0px rgba(0, 0, 0, 0.1)', borderTop: '7px solid #2065D1', borderRadius: '8px', background: 'white', padding: '15px', gap: '10px' }} >
                            <Box>
                                <Typography class="form_title" variant="h4">{formStep1.surveyTitle}</Typography>
                                <Typography sx={{
                                    wordBreak: 'break-word',
                                    overflowWrap: 'break-word',
                                    whiteSpace: 'normal',
                                    fontWeight: '400'
                                }} variant="h6">{formStep1.surveyDesc}</Typography>
                            </Box>
                            <Box>
                                <img height="100%" width="120"  src={resim} alt="Mall Logistics Logo" />

                            </Box>
                        </Stack>
                        <Card className='printable-card' sx={{ gap: '10px', flexDirection: isRow ? 'row' : 'column', flexWrap: isRow ? 'wrap' : 'nowrap', backgroundColor: 'transparent', display: 'flex', paddingTop: '20px' }} >
                            {
                                formContent?.map((field, index) => {
                                    const minValue = field.question_options[0]?.minValue;
                                    const maxValue = field.question_options[0]?.maxValue;
                                    const ratings = Array.from({ length: maxValue - minValue + 1 }, (_, index) => minValue + index);
                                    return (
                                        <Stack className='printable-row' sx={{ width: isRow ? '48%' : '100%', }} paddingX={2} key={index}>
                                            <Stack marginY={1} sx={{ gap: '15px' }}>
                                                <Box display="flex" alignItems="center">
                                                    <Typography variant="p" color="primary" >
                                                        {index + 1}.
                                                    </Typography>
                                                    <Typography variant="p" color="primary" >
                                                        Soru:
                                                    </Typography>
                                                    <Typography variant="p" sx={{ marginLeft: 1 }}>
                                                        {stripHtmlTags(field.label)}
                                                    </Typography>
                                                </Box>
                                                {
                                                    field.question_type === 'short_answer' &&
                                                    <Stack>

                                                        <TextField
                                                            id="short_answer"
                                                            name="short_answer"
                                                            type="text"
                                                            label={stripHtmlTags(field.label)}
                                                            fullWidth
                                                        // value={barcode}
                                                        />
                                                    </Stack>

                                                }
                                                {
                                                    field.question_type === "multi_choice" && field.multi_choice && (
                                                        // Çoklu Seçim (Checkbox) Durumu
                                                        <Stack spacing={2} >


                                                            <FormGroup sx={{ flexDirection: 'row' }}>
                                                                {field.question_options.map((option, optionIndex) => (
                                                                    <FormControlLabel
                                                                        key={optionIndex}
                                                                        control={<Checkbox color="primary" />}
                                                                        label={
                                                                            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                                                                {option}
                                                                            </Typography>
                                                                        }
                                                                        value={option}
                                                                        name={`question_${index}_${optionIndex}`} // Benzersiz isimlendirme
                                                                    />
                                                                ))}
                                                            </FormGroup>
                                                        </Stack>
                                                    )
                                                }
                                                {
                                                    field.question_type === "multi_choice" && !field.multi_choice &&

                                                    // Tekli Seçim (Radio) Durumu
                                                    <Stack spacing={1} >
                                                        <RadioGroup name={`question_${index}`} sx={{ flexDirection: 'row' }}>
                                                            {field.question_options.map((option, optionIndex) => (
                                                                <FormControlLabel
                                                                    key={optionIndex}
                                                                    value={option}
                                                                    control={<Radio sx={{ padding: '5px' }} color="primary" />}
                                                                    label={
                                                                        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                                                            {option}
                                                                        </Typography>
                                                                    }
                                                                />
                                                            ))}
                                                        </RadioGroup>
                                                    </Stack>

                                                }
                                                {
                                                    field.question_type === 'rating' &&
                                                    <Stack spacing={2}>
                                                        <Stack direction="row" spacing={2} flexWrap="wrap">
                                                            {ratings.map((rating, ratingIndex) => (
                                                                <Stack key={ratingIndex} alignItems="center" >
                                                                    <Button
                                                                        variant="outlined"
                                                                        onClick={() => handleRatingClick(index, rating)}
                                                                        sx={{
                                                                            minWidth: '30px',
                                                                            minHeight: '30px',
                                                                            bgcolor: newRating[index] === rating ? '#da3831' : 'transparent',
                                                                            color: newRating[index] === rating ? 'white' : '#000',

                                                                            padding: '2px 2px',
                                                                            transition: 'background-color 0.3s, transform 0.2s',
                                                                            '&:hover': {
                                                                                bgcolor: '#da3831',
                                                                                transform: 'scale(1.05)', // Buton büyütme efekti
                                                                            },
                                                                        }}
                                                                    >
                                                                        {rating}
                                                                    </Button>
                                                                    <Typography variant="p" sx={{ mt: 1 }}>
                                                                        {ratingIndex === 0 && field.question_options[0].minContent}
                                                                        {ratingIndex === ratings.length - 1 && field.question_options[0].maxContent}
                                                                    </Typography>
                                                                </Stack>
                                                            ))}
                                                        </Stack>
                                                    </Stack>

                                                }
                                            </Stack>
                                        </Stack>

                                    )
                                }
                                )
                            }
                        </Card>
                    </Box>
                </Stack>
            }
        </Box >
    )
}

export default FormsStep2
